import { React, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Outlet, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setGlobal, getGlobal } from '../../src/globals';
import { getUserRole} from '../modules/Utilities';
import { _CONFIGS } from '../../src/Config/index';

const isDevMode = _CONFIGS.isDevMode;

const Login = () => {
    const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
   
    useEffect(()=> {
        /*
        *  getActivePatient will search through the patient list that we pulled from the SE Sandbox "EMR" in App.js to match their 
        *  email with the email used to log in so that we can set them as the active patient. This will allow us to know all the 
        *  patient details we may need downstream
        */
        function getActivePatient() {
            const patientList = getGlobal("gPatlist");
            var activePatient = null;

            if(patientList && user) {
                patientList.data.forEach(element => {
                    if(activePatient)
                        return;

                    if(isDevMode)
                        console.log("login.getActivePatient: Checking user: " + element.given_name + " " + element.family_name);

                    if(element.email === user.email){
                        setGlobal({gActiveUser: activePatient = element});
                        setGlobal({gPatientMRN: element.mrn});
                        if(isDevMode)
                            console.log("login.getActivePatient: Active User is: " + element.given_name + " " + element.family_name);
                    }
                });
                //!!!!TO DO: If we got here then we didn't find the user. This means that we now need to add the user to the SE Sandbox "EMR"!!!!
                if(!activePatient) {
                    if(isDevMode)
                        console.log("login.getActivePatient: Didn't find a patient, so we need to do something");
                }
                else {
                    if(isDevMode)
                        console.log("login.getActivePatient: Found the patient");
                }
            }
        }
        if(user.email_verified) {
            //Determine which user is logged in and then pull their details from the patient list in the SE Sandbox "EMR"
            getActivePatient();
        }
    }, [user]);

    if (isAuthenticated) {
        if(!user.email_verified) {
            return ( 
                <>
                    <div>
                        <h3>Please verify your account before continuing</h3>
                    </div>
                </>
            ) 
        }
        else {
            return (
                <>
                    <div>
                        <br/>
                        <h6>Please choose the user experience you'd like to have</h6>
                        <button className='button' onClick={handleLaunchPatient} type="submit" value="" id="LaunchPatient" >Patient</button>
                        <button className='button' onClick={handleLaunchProvider} type="submit" value="" id="LaunchProvider" >Provider</button>
                    </div>
                    <br/>
                    <div>
                        <Outlet />
                    </div>
                </>
            ) 
        }
    }
    else {       
        return (
            <>
                <br/>
                <h5>You're not currently Logged In.</h5>
                <h4>Please click the link above to log in.</h4>
                <br/>
            </>
        )     
    }

    async function handleLaunchPatient() {
        navigate("/login/PatientExperience");
        /*
        const strUserRole = await getUserRole(getAccessTokenSilently);
        
        if (isDevMode)
            console.log("User Role Returned: " + strUserRole);

        //Clicking the button can return error 429, "Too many Requests". Just return on error instead of parsing through various reasons. We can just review the console for errors if we need to. 
        if (strUserRole.toUpperCase() === "ERROR")
            return;

        if (strUserRole.toUpperCase() === "PATIENT"){
            navigate("/login/PatientExperience");
        }
        else {
            navigate("/IncorrectUserRole");
        }
        */
    }

    async function handleLaunchProvider() {
        navigate("/login/ProviderExperience");
        /*
        const strUserRole = await getUserRole(getAccessTokenSilently);
        
        if (isDevMode)
            console.log("User Role Returned: " + strUserRole);

        //Clicking the button can return error 429, "Too many Requests". Just return on error instead of parsing through various reasons. We can just review the console for errors if we need to. 
        if (strUserRole.toUpperCase() === "ERROR")
            return;

        if (strUserRole.toUpperCase() === "PROVIDER" || strUserRole.toUpperCase() === "PRACTITIONER"){
            navigate("/login/ProviderExperience");
        }
        else {
            navigate("/IncorrectUserRole");
        }
        */
    }
}

export default Login