/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { _CONFIGS } from '../../Config/index';
import { useNavigate } from 'react-router-dom';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const usePatientPortalECheckInStep4 = () => {
  const data = {};
  const navigate = useNavigate();
  
  const handleFinishLater = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep4: handleOnFinishLater Called"); 
    }
    navigate('/PatientPortalECheckIn');

  };

  const handleOnNext = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep4: handleOnNext Called"); 
    }
    navigate('/PatientPortalECheckInStep5');
  
  };
  
  const handleLogOut = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep4: onLogOut Called"); 
    }
    navigate('/LogIn/PatientExperience');
  
  };

  const handleAddMeds = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep4: handleAddMeds Called"); 
    }
  
  };

  const fns = { handleFinishLater, handleOnNext, handleLogOut, handleAddMeds };
  return { data, fns };
};

export default usePatientPortalECheckInStep4;
