/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useState, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import axios from "axios";
import { styled } from '@mui/material/styles';
import { _URLS, _CONFIGS } from '../../Config/index';
import {setGlobal, getGlobal } from '../../globals';
import useProviderEmrMessageVideoVisit from './useProviderEmrMessageVideoVisit';
import { useNavigate } from 'react-router-dom';

//Visuals to use when selecting and unselecting "Video Visits"
import VectorImage from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector.png';
import Vector1Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_1.png';
import Vector2Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_2.png';
import Vector3Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_3.png';
import Vector4Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_4.png';
import Vector5Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_5.png';
import ShapeImage from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape.png';
import Vector6Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_6.png';
import Shape1Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_1.png';
import Vector7Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_7.png';
import Vector8Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_8.png';
import Vector9Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_9.png';
import Vector10Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_10.png';
import Vector11Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_11.png';
import Vector12Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_12.png';
import Vector13Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_13.png';
import Vector14Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_14.png';
import Vector15Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_15.png';
import Vector16Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_16.png';
import Vector17Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_17.png';
import Vector18Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_18.png';
import Vector19Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_19.png';
import Vector20Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_20.png';
import Vector21Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_21.png';
import Vector22Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_22.png';
import Vector23Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_23.png';
import Vector24Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_24.png';
import Vector25Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_25.png';
import Vector26Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_26.png';
import Vector27Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_27.png';
import Ellipse1Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Ellipse_1.png';
import VectorImage1 from 'src/assets/images/PatientPortaleCheckInStep1_Vector.svg';
import Vector35Image from 'src/assets/images/videocam_ready_outline.png';

var PnpWidgetRoot = null;

function ProviderEmrNextPatient(props) {
  const { fns } = useProviderEmrMessageVideoVisit();
  const [ strFullName, setFullName ] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    getNextPatient();
  }, [])

  async function getNextPatient() {
    var arrPatientList = getGlobal("gPatlist");
    const strEmrId = arrPatientList.data[Math.floor(Math.random() * (arrPatientList.data.length-1))].mrn;//getGlobal("gEhrId");
    

    if(!arrPatientList){
      console.log("PNP: Don't have a patient list, so let's get one");
      arrPatientList = await axios.get(_URLS.patientListUrl)
      .catch((e) => {
        console.log("App.getPatientList: Unable to get patient list from " + _URLS.patientListUrl);
        console.log(e);
        return;
      });
      
      setGlobal({gPatlist: arrPatientList});
    }
    
    const patientDetails = await axios.get(_URLS.patientSearchUrl + strEmrId)
      .catch((e) => {
        console.log("ProviderEmrMessageVideoVisit.getNextPatient: Unable to get patient list from " + _URLS.patientSearchUrl + strEmrId);
        console.log(e);
        return;
      });

      if(patientDetails) {
        setGlobal({gActiveUser: patientDetails});
        setGlobal({gPatlist: patientDetails});
        setGlobal({gPatientMRN: patientDetails.data[0].mrn});
        setFullName(patientDetails.data[0].given_name + " " + patientDetails.data[0].family_name);
      }
      else {
        setFullName("No Patients Available");
      }
  }

  function PnpGetNextPatient() {
    var numberWating = Math.floor(Math.random() * (15-2 + 1) + 2);
    
    return (
      <PnpWidget1 id="PnpWidget" className={props.className}>
        <WidgetTitleFrame>
          <MainWidgetTitle>
              <Header>{`Next Patient`}</Header>
          </MainWidgetTitle>
          <ContentPart>
              <ContentTextBlock> 
                <Description>
                  {`We’ll automatically match you with the next patient in the queue.`}
                </Description>
              </ContentTextBlock>
          </ContentPart>
        </WidgetTitleFrame>
        <ActionSummary>
            <ActionDetailOutline>
              <TxtPatientName>{`Patients in Queue`}</TxtPatientName>
              <TxtNumberWaiting>{numberWating}</TxtNumberWaiting>
            </ActionDetailOutline>
            <ActionTaken>
              <ActionTakenOutline>
                <TxtActionTaken>{`AVERAGE WAIT TIME: 5 MINS`}</TxtActionTaken>
              </ActionTakenOutline>
            </ActionTaken>
          </ActionSummary>
        <MediumPrimaryButton>
          <ActionM onClick={onNextPatient}>{`Next Patient`}</ActionM>
        </MediumPrimaryButton>
      </PnpWidget1>
    )
  }

  function PnpPatientScheduledWidget() {
    return (
      <PnpWidget1 className={props.className}>
          <WidgetTitleFrame>
            <MainWidgetTitle>
                <Header>{`Appointment Scheduled`}</Header>
            </MainWidgetTitle>
            <ContentPart>
                <ContentTextBlock>
                  <Description>
                    {"Go to your schedule and join the visit with " + strFullName + " and complete the visit as usual.\n\n" + 
                    "If you cannot find the patient on your schedule, never connected with them OR you already completed the documentation/visit on the above named visit and need to clear your queue to take the next patient, select End the Visit."}
                  </Description>
                </ContentTextBlock>
            </ContentPart>
          </WidgetTitleFrame>
          <ActionSummary>
              <ActionDetailOutline>
                <TxtPatientName>{strFullName}</TxtPatientName>
                <TxtCareType>{`Routine Care`}</TxtCareType>
              </ActionDetailOutline>
              <ActionTaken>
                <ActionTakenOutline>
                  <TxtActionTaken>{`Patient Assigned`}</TxtActionTaken>
                </ActionTakenOutline>
              </ActionTaken>
            </ActionSummary>
          <MediumPrimaryButton>
            <ActionM onClick={onOpenChart}>{`Open Patient Chart`}</ActionM>
          </MediumPrimaryButton>
          <EndVisit1>
            <BtnEndVisit onClick={onEndVisit}>
              <TxtEndVisit>{`End the Visit`}</TxtEndVisit>
            </BtnEndVisit>
          </EndVisit1>
      </PnpWidget1>
    )
  }

  function onNextPatient() {
    setGlobal({gShowAllPatients: false});

    PnpWidgetRoot = createRoot(document.getElementById("PnpWidget"));
    
    const newWidgetRoot = (
      <PnpPatientScheduledWidget/>
    );

    PnpWidgetRoot.render(newWidgetRoot);
  }

  function onEndVisit() {
    setGlobal({gShowAllPatients: false});

    PnpWidgetRoot = createRoot(document.getElementById("PnpWidget"));
    
    const newWidgetRoot = (
      <PnpGetNextPatient/>
    );

    PnpWidgetRoot.render(newWidgetRoot);
  }

  function onGoHome() {
    setGlobal({gShowAllPatients: _CONFIGS.bShowAllPatients});
    
    navigate('/ProviderEmrMessageVideoVisit');
  }

  function onOpenChart() {
    setGlobal({gShowAllPatients: false});
    
    navigate('/ProviderEmrMessageVideoVisit');
  }

  return (
    <ProviderEmrMessageVi className={props.className}>
      <SubNav>
        <Rectangle4></Rectangle4>
        <Group16>
          <Home onClick={onGoHome}>{`Home`}</Home>
          <Home1>
            <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
          </Home1>
        </Group16>
        <Group17>
          <Refresh>{`Refresh`}</Refresh>
          <Refresh1>
            <Vector1 src={Vector1Image} loading="lazy" alt={'Vector'} />
          </Refresh1>
        </Group17>
        <Group20>
          <Search>{`Search`}</Search>
          <Search1>
            <Vector2 src={Vector2Image} loading="lazy" alt={'Vector'} />
          </Search1>
        </Group20>
        <Group21>
          <Attach>{`Attach`}</Attach>
          <Group>
            <Vector3 src={Vector3Image} loading="lazy" alt={'Vector'} />
          </Group>
        </Group21>
        <Group22>
          <OutOfContact>{`Out of Contact`}</OutOfContact>
          <IconsEmptyCalendar>
            <Vector4 src={Vector4Image} loading="lazy" alt={'Vector'} />
          </IconsEmptyCalendar>
        </Group22>
        <Group23>
          <More>{`More`}</More>
          <MoreHoriz>
            <Vector5 src={Vector5Image} loading="lazy" alt={'Vector'} />
          </MoreHoriz>
        </Group23>
        <GroupOD>
          <More>{`OnDemand`}</More>
          <MoreHoriz>
            <Vector35 src={Vector35Image} loading="lazy" alt={'Vector'} />
          </MoreHoriz>
        </GroupOD>
        <Group18>
          <NewMessage>{`New Message`}</NewMessage>
          <Shape src={ShapeImage} loading="lazy" alt={'Shape'} />
          <CreateNewFolder>
            <Vector6 src={Vector6Image} loading="lazy" alt={'Vector'} />
          </CreateNewFolder>
        </Group18>
        <Group19>
          <ManagePools>{`Manage Pools`}</ManagePools>
          <Shape1 src={Shape1Image} loading="lazy" alt={'Shape'} />
          <GroupWork>
            <Vector7 src={Vector7Image} loading="lazy" alt={'Vector'} />
          </GroupWork>
        </Group19>
      </SubNav>
      <Navigation>
        <Rectangle1></Rectangle1>
        <Menu>
          <Vector8 src={Vector8Image} loading="lazy" alt={'Vector'} />
        </Menu>
        <GenericEmr>
          <GenericEmrSpan1>{`Generic`}</GenericEmrSpan1>
          <GenericEmrSpan2>{`EMR`}</GenericEmrSpan2>
        </GenericEmr>
        <LogosAmwellLogoCompa>
          <Frame>
            <Group1>
              <Vector9 src={Vector9Image} loading="lazy" alt={'Vector'} />
              <Vector10 src={Vector10Image} loading="lazy" alt={'Vector'} />
              <Vector11 src={Vector11Image} loading="lazy" alt={'Vector'} />
              <Vector12 src={Vector12Image} loading="lazy" alt={'Vector'} />
              <Vector13 src={Vector13Image} loading="lazy" alt={'Vector'} />
              <Vector14 src={Vector14Image} loading="lazy" alt={'Vector'} />
              <Vector15 src={Vector15Image} loading="lazy" alt={'Vector'} />
              <Vector16 src={Vector16Image} loading="lazy" alt={'Vector'} />
              <Vector17 src={Vector17Image} loading="lazy" alt={'Vector'} />
              <Vector18 src={Vector18Image} loading="lazy" alt={'Vector'} />
            </Group1>
            <Vector19 src={Vector19Image} loading="lazy" alt={'Vector'} />
            <Vector20 src={Vector20Image} loading="lazy" alt={'Vector'} />
            <Vector21 src={Vector21Image} loading="lazy" alt={'Vector'} />
            <Vector22 src={Vector22Image} loading="lazy" alt={'Vector'} />
            <Vector23 src={Vector23Image} loading="lazy" alt={'Vector'} />
            <Vector24 src={Vector24Image} loading="lazy" alt={'Vector'} />
            <Vector25 src={Vector25Image} loading="lazy" alt={'Vector'} />
            <Vector26 src={Vector26Image} loading="lazy" alt={'Vector'} />
            <Vector27 src={Vector27Image} loading="lazy" alt={'Vector'} />
          </Frame>
        </LogosAmwellLogoCompa>
        <Ellipse1 src={Ellipse1Image} loading="lazy" alt={'Ellipse 1'} />
      </Navigation>
      <Group4 onClick={fns.onLogOut}>
        <Logout>{`Logout`}</Logout>
        <Vector src={VectorImage1} loading="lazy" alt={'Vector'} />
      </Group4>
      <PnpGetNextPatient />
    </ProviderEmrMessageVi>
  );
}

export default ProviderEmrNextPatient;

const PnpWidget1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `12px`,
  display: `flex`,
  position: `fixed`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  boxSizing: `border-box`,
  height: 'auto',
  top: '225px',
  left: '450px',
}));

const WidgetTitleFrame = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `0px`,
  left: '65px',
});

const MainWidgetTitle = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `552px`,
  margin: `0px`,
});

const Header = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
  flex: `1`,
  margin: `0px`,
}));

const ContentPart = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `552px`,
  margin: `16px 0px 0px 0px`,
  overflow: `hidden`,
});

const ContentTextBlock = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  flex: `1`,
  margin: `0px`,
});

const Description = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  flex: `1`,
  margin: `0px`,
}));

/*
const MediumPrimaryButton = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(23, 116, 204, 1)`,
  borderRadius: `25px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 16px`,
  boxSizing: `border-box`,
  height: `36px`,
}));
*/

const MediumPrimaryButton = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(23, 116, 204, 1)`,
  borderRadius: `25px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 16px`,
  boxSizing: `border-box`,
  left:  '70px',
  height: `36px`,
}));

const ActionM = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
  cursor: `pointer`,
}));



const ProviderEmrMessageVi = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `912px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const SubNav = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1439px`,
  height: `62px`,
  left: `1px`,
  top: `70px`,
});

const Rectangle4 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(240, 241, 242, 1)`,
  boxShadow: `0px 8px 36px rgba(0, 0, 0, 0.16)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1439px`,
  height: `62px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group16 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `75px`,
  height: `24px`,
  left: `27px`,
  top: `19px`,
});

const Home = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
  cursor: 'pointer',
}));

const Home1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector = styled('img')({
  height: `14.17px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `3px`,
});

const Group17 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `88px`,
  height: `24px`,
  left: `134px`,
  top: `19px`,
});

const Refresh = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Refresh1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Group20 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `81px`,
  height: `24px`,
  left: `624px`,
  top: `19px`,
});

const Search = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Search1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector2 = styled('img')({
  height: `14.58px`,
  width: `14.58px`,
  position: `absolute`,
  left: `3px`,
  top: `3px`,
});

const Group21 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `79px`,
  height: `24px`,
  left: `737px`,
  top: `19px`,
});

const Attach = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Group = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector3 = styled('img')({
  height: `11.67px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `4px`,
});

const Group22 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `141px`,
  height: `24px`,
  left: `848px`,
  top: `19px`,
});

const OutOfContact = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `15px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const IconsEmptyCalendar = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector4 = styled('img')({
  height: `16.67px`,
  width: `15px`,
  position: `absolute`,
  left: `3px`,
  top: `1px`,
});

const Group23 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `69px`,
  height: `24px`,
  left: `1021px`,
  top: `19px`,
});

const GroupOD = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `69px`,
  height: `24px`,
  left: `1121px`,
  top: `19px`,
});

const More = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const MoreHoriz = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector5 = styled('img')({
  height: `3.33px`,
  width: `13.33px`,
  position: `absolute`,
  left: `3px`,
  top: `8px`,
});

const Group18 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `151.99px`,
  height: `24px`,
  left: `254px`,
  top: `19px`,
});

const NewMessage = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Shape = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `142px`,
  top: `9px`,
});

const CreateNewFolder = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector6 = styled('img')({
  height: `13.33px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `3px`,
});

const Group19 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `153.99px`,
  height: `24px`,
  left: `438px`,
  top: `19px`,
});

const ManagePools = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Shape1 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `144px`,
  top: `9px`,
});

const GroupWork = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector7 = styled('img')({
  height: `16.67px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Menu = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `28px`,
  top: `23px`,
  overflow: `hidden`,
});

const Vector8 = styled('img')({
  height: `12px`,
  width: `18px`,
  position: `absolute`,
  left: `3px`,
  top: `6px`,
});

const GenericEmrSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `300`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
});

const GenericEmrSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `800`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
});

const GenericEmr = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
  position: `absolute`,
  left: `258px`,
  top: `17px`,
});

const LogosAmwellLogoCompa = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 0)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `160px`,
  height: `32px`,
  left: `76px`,
  top: `19px`,
  overflow: `hidden`,
});

const Frame = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `159.32px`,
  height: `26.5px`,
  left: `0px`,
  top: `3px`,
  overflow: `hidden`,
});

const Group1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `126.96px`,
  height: `16.39px`,
  left: `32px`,
  top: `5px`,
});

const Vector9 = styled('img')({
  height: `15.38px`,
  width: `13.33px`,
  position: `absolute`,
  left: `0px`,
  top: `1px`,
});

const Vector10 = styled('img')({
  height: `12.58px`,
  width: `12.27px`,
  position: `absolute`,
  left: `15px`,
  top: `4px`,
});

const Vector11 = styled('img')({
  height: `12.58px`,
  width: `12.91px`,
  position: `absolute`,
  left: `29px`,
  top: `4px`,
});

const Vector12 = styled('img')({
  height: `16.21px`,
  width: `3.75px`,
  position: `absolute`,
  left: `44px`,
  top: `0px`,
});

const Vector13 = styled('img')({
  height: `15.2px`,
  width: `7.72px`,
  position: `absolute`,
  left: `50px`,
  top: `1px`,
});

const Vector14 = styled('img')({
  height: `16.21px`,
  width: `12.17px`,
  position: `absolute`,
  left: `60px`,
  top: `0px`,
});

const Vector15 = styled('img')({
  height: `15.65px`,
  width: `15.04px`,
  position: `absolute`,
  left: `74px`,
  top: `1px`,
});

const Vector16 = styled('img')({
  height: `12.58px`,
  width: `12.91px`,
  position: `absolute`,
  left: `90px`,
  top: `4px`,
});

const Vector17 = styled('img')({
  height: `12.34px`,
  width: `7.58px`,
  position: `absolute`,
  left: `106px`,
  top: `4px`,
});

const Vector18 = styled('img')({
  height: `12.58px`,
  width: `12.27px`,
  position: `absolute`,
  left: `115px`,
  top: `4px`,
});

const Vector19 = styled('img')({
  height: `11.24px`,
  width: `15.57px`,
  position: `absolute`,
  left: `11px`,
  top: `8px`,
});

const Vector20 = styled('img')({
  height: `11.24px`,
  width: `15.56px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const Vector21 = styled('img')({
  height: `15.11px`,
  width: `11.24px`,
  position: `absolute`,
  left: `8px`,
  top: `11px`,
});

const Vector22 = styled('img')({
  height: `1.72px`,
  width: `2.85px`,
  position: `absolute`,
  left: `16px`,
  top: `11px`,
});

const Vector23 = styled('img')({
  height: `16.01px`,
  width: `11.24px`,
  position: `absolute`,
  left: `8px`,
  top: `0px`,
});

const Vector24 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `16px`,
  top: `6px`,
});

const Vector25 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `22px`,
  top: `19px`,
});

const Vector26 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `8px`,
  top: `14px`,
});

const Vector27 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `8px`,
  top: `20px`,
});

const Logout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `25px`,
  top: `0px`,
  cursor: `pointer`,
}));

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `1369px`,
  top: `12px`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1275px`,
  top: `23px`,
  cursor: `pointer`,
});

const Vector1 = styled('img')({
  height: `13.33px`,
  width: `13.32px`,
  position: `absolute`,
  left: `3px`,
  top: `3px`,
});

const Vector35 = styled('img')({
  height: `15.95px`,
  width: `15.95px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const ActionSummary = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.04), 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 6px 20px rgba(0, 0, 0, 0.12), 0px 8px 36px rgba(0, 0, 0, 0.16)`,
  borderRadius: `16px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `24px 0px 0px 0px`,
  boxSizing: `border-box`,
  left:  '70px',
  height: `204px`,
  margin: '20px',
}));

/*
const ActionSummary = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.04), 0px 2px 5px rgba(0, 0, 0, 0.06), 0px 4px 10px rgba(0, 0, 0, 0.08), 0px 6px 20px rgba(0, 0, 0, 0.12), 0px 8px 36px rgba(0, 0, 0, 0.16)`,
  borderRadius: `16px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `24px 0px 0px 0px`,
  boxSizing: `border-box`,
  height: `204px`,
  margin: '20px',
}));
*/

const ActionDetailOutline = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `10px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  flex: `1`,
  margin: `0px`,
});

const TxtPatientName = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `28px`,
  textTransform: `none`,
  margin: `0px`,
}));

const TxtNumberWaiting = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `50px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `136px`,
  margin: `20px 0px 0px 0px`,
}));

const TxtCareType = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `136px`,
  margin: `20px 0px 0px 0px`,
}));

const ActionTaken = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `48px`,
  width: `308px`,
  margin: `5px 0px 0px 0px`,
});

const ActionTakenOutline = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(20, 20, 20, 0.03)`,
  borderRadius: `0px 0px 16px 16px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `10px 0px`,
  boxSizing: `border-box`,
  width: `308px`,
  left: `0px`,
  top: `0px`,
}));

const TxtActionTaken = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `28px`,
  textTransform: `uppercase`,
  margin: `0px`,
}));

const EndVisit1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `5px`,
});

const BtnEndVisit = styled('div')({
  borderRadius: `25px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `7px 16px`,
  boxSizing: `border-box`,
  left:  '70px',
  height: `36px`,
  margin: `0px`,
  cursor: `pointer`,
});

const TxtEndVisit = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(217, 59, 61, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}));