/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { styled } from '@mui/material/styles';
import { _CONFIGS } from '../../Config/index';
import { useNavigate } from 'react-router-dom';

//Visuals to use when selecting and unselecting "Video Visits"
import SelectedVector44Image from 'src/assets/images/Selected_videocam_outline.png';
import UnselectedVector44Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_44.png';
import UnselectedVector45Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_45.png';
import SelectedVector45Image from 'src/assets/images/SelectedNotifyPatientIcon.png';
import { getGlobal } from '../../globals';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const useProviderEmrMessageVideoVisit = () => {
  const data = {};
  const navigate = useNavigate();
  // const [ bPatientSelected, setMyPatientSelected ] = useState(false);

  //There variables help ensure that we don't try to get the root element more than once
  var group32Root = null;
  var group33Root = null;
  var isVVIconSelected = false;

  const handleVVMouseOver = () => {
    if(getGlobal("gActivateVVBtn")) {
      //Make sure the element we're looking for exists so we don't crash
      if(document.getElementById("Group32")){      
        if(!group32Root)
          group32Root = createRoot(document.getElementById("Group32"));
        
        const HoverOnGroup = (
          <SelectedGroup32>
            <VirtualVisit3>{`Virtual Visit`}</VirtualVisit3>
            <VideocamOutline className="VideoVisitImage">
              <Vector44 src={SelectedVector44Image} loading="lazy" alt={'Vector'} />
            </VideocamOutline>
          </SelectedGroup32>
        );
      
        group32Root.render(HoverOnGroup); 
      }
    }
  };

  const handleVVMouseLeave = () => {
    if(getGlobal("gActivateVVBtn")) {
      //Make sure the element we're looking for exists so we don't crash
      if(document.getElementById("Group32") && !isVVIconSelected){

        if(!group32Root)
          group32Root = createRoot(document.getElementById("Group32"));
        
        const NewGroup32 = (
          <UnselectedGroup32>
            <UnSelectedVirtualVisit3>{`Virtual Visit`}</UnSelectedVirtualVisit3>
            <VideocamOutline className="VideoVisitImage">
              <Vector44 src={UnselectedVector44Image} loading="lazy" alt={'Vector'} />
            </VideocamOutline>
          </UnselectedGroup32>
        );
      
        group32Root.render(NewGroup32); 
      }
    }
  };

  const handleNPMouseOver = () => {
    if(getGlobal("gActivateVVBtn")) {
      //Make sure the element we're looking for exists so we don't crash
      if(document.getElementById("Group33")){
        
        if(!group33Root)
          group33Root = createRoot(document.getElementById("Group33"));
        
        const HoverOnGroup = (
          <SelectedGroup33>
            <NotifyPatient>{`Notify Patient`}</NotifyPatient>
            <NotificationsActive>
              <Vector45 src={SelectedVector45Image} loading="lazy" alt={'Vector'} />
            </NotificationsActive>
          </SelectedGroup33>
        );
      
        group33Root.render(HoverOnGroup); 
      }
    }
  };

  const handleNPMouseLeave = () => {
    if(getGlobal("gActivateVVBtn")) {
      //Make sure the element we're looking for exists so we don't crash
      if(document.getElementById("Group33")){

        if(!group33Root)
          group33Root = createRoot(document.getElementById("Group33"));
        
        const NewGroup33 = (
          <UnselectedGroup33>
            <UnSelectedNotifyPatient>{`Notify Patient`}</UnSelectedNotifyPatient>
            <NotificationsActive>
              <Vector45 src={UnselectedVector45Image} loading="lazy" alt={'Vector'} />
            </NotificationsActive>
          </UnselectedGroup33>
        );
      
        group33Root.render(NewGroup33); 
      }
    }
  };

  const handleVideoVisit = () => {
    if(getGlobal("gActivateVVBtn")) {
      if(isDevMode){
        console.log("useProviderEmrMessageVideoVisit: handleVideoVisit Called"); 
      }

      navigate('/ProviderEmrDocumentingTakeNote');
    }
  };

  const goNextPatient = () => {
    if(isDevMode){
      console.log("useProviderEmrMessageVideoVisit: goNextPatient Called"); 
    }

    navigate('/ProviderEmrNextPatient');
  };

  const onLogOut = () => {
    if(isDevMode){
      console.log("useProviderEmrMessageVideoVisit: onLogOut Called"); 
    }

    navigate('/LogIn/ProviderExperience');

  };

  const fns = { handleVVMouseOver, handleVideoVisit, handleVVMouseLeave, handleNPMouseOver, handleNPMouseLeave, onLogOut, goNextPatient };
  return { data, fns };
};

export default useProviderEmrMessageVideoVisit;

//Video Visit Icon styles to use when selecting and unselecting "Video Visits"
const SelectedGroup32 = styled('div')({
  background: 'rgba(0, 137, 6, 1)',
  width: `67px`,
  height: `60px`,
  cursor: `pointer`,
});

const UnselectedGroup32 = styled('div')({
  background: 'rgba(237, 237, 237, 1)',
  width: `67px`,
  height: `60px`,
});

const UnSelectedVirtualVisit3 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const VirtualVisit3 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const VideocamOutline = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `11px`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `40px`,
  height: `56px`,
  left: `16px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector44 = styled('img')({
  height: `18px`,
  width: `27px`,
  position: `absolute`,
  left: `5px`,
  top: `9px`,
});

const SelectedGroup33 = styled('div')({
  background: 'rgba(0, 137, 6, 1)',
  width: `78px`,
  height: `60px`,
  cursor: `pointer`,
});

const UnselectedGroup33 = styled('div')({
  background: 'rgba(237, 237, 237, 1)',
  width: `67px`,
  height: `60px`,
});

const NotifyPatient = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const UnSelectedNotifyPatient = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));


const NotificationsActive = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `23px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector45 = styled('img')({
  height: `29.25px`,
  width: `29.91px`,
  position: `absolute`,
  left: `3px`,
  top: `4px`,
});

