/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from 'react-helmet';
import LogosAmwellLogoCompaImage from 'src/assets/images/PatientPortaleCheckIn_Logos_Amwell___Logo_company.svg';
import VectorImage from 'src/assets/images/PatientPortaleCheckIn_Vector.svg';
import Vector1Image from 'src/assets/images/PatientPortaleCheckIn_Vector.png';
import Vector2Image from 'src/assets/images/PatientPortaleCheckIn_Vector_1.png';
import Vector3Image from 'src/assets/images/PatientPortaleCheckIn_Vector_2.png';
import Layer13Image from 'src/assets/images/PatientPortaleCheckIn_Layer_1.svg';
import PanelChemistryImage from 'src/assets/images/PatientPortaleCheckIn_Panel_Chemistry.png';
import LargePrimaryButtonImage from 'src/assets/images/PatientPortaleCheckIn_Large_Primary_Button.png';
import MediumSecondaryButtoImage from 'src/assets/images/PatientPortaleCheckIn_Medium_Secondary_Button.png';
import MediumSecondaryButto1Image from 'src/assets/images/PatientPortaleCheckIn_Medium_Secondary_Button_1.png';
import Ellipse3Image from 'src/assets/images/PatientPortaleCheckIn_Ellipse_3.png';
import Ellipse4Image from 'src/assets/images/PatientPortaleCheckIn_Ellipse_4.png';
import Ellipse41Image from 'src/assets/images/PatientPortaleCheckIn_Ellipse_4_1.png';
import LargePrimaryButton1Image from 'src/assets/images/PatientPortaleCheckIn_Large_Primary_Button_1.png';
import MediumSecondaryButto2Image from 'src/assets/images/PatientPortaleCheckIn_Medium_Secondary_Button_2.png';
import Vector4Image from 'src/assets/images/PatientPortaleCheckIn_Vector_1.svg';
import UnionImage from 'src/assets/images/PatientPortaleCheckIn_Union.png';
import Ellipse2Image from 'src/assets/images/PatientPortaleCheckIn_Ellipse_2.png';
import LargePrimaryButton2Image from 'src/assets/images/PatientPortaleCheckIn_Large_Primary_Button_2.png';
import MediumSecondaryButto3Image from 'src/assets/images/PatientPortaleCheckIn_Medium_Secondary_Button_3.png';
import Vector5Image from 'src/assets/images/PatientPortaleCheckIn_Vector_2.svg';
import MediumSecondaryButto4Image from 'src/assets/images/PatientPortaleCheckIn_Medium_Secondary_Button_4.png';
import Ellipse31Image from 'src/assets/images/PatientPortaleCheckIn_Ellipse_3_1.png';
import Ellipse42Image from 'src/assets/images/PatientPortaleCheckIn_Ellipse_4_2.png';
import Ellipse43Image from 'src/assets/images/PatientPortaleCheckIn_Ellipse_4_3.png';
import LargePrimaryButton3Image from 'src/assets/images/PatientPortaleCheckIn_Large_Primary_Button_3.png';
import GenericPortalImage from 'src/assets/images/PatientPortaleCheckIn_GenericPortal.svg';
import { styled } from '@mui/material/styles';
import Menu from 'src/components/Menu/Menu';
import usePatientPortalECheckIn from './usePatientPortalECheckIn';
import { getGlobal } from '../../globals';
import { getPatientList } from '../../modules/Utilities';

function PatientPortalECheckIn(props) {
  const { fns } = usePatientPortalECheckIn();
  const { isAuthenticated, user } = useAuth0();
  const [ strFirstName, setPatientName ] = useState("");

  useEffect(() => {
    const patientList = getGlobal("gActiveUser");
    
    if(!patientList) {
      getPatientList();
      return;
    }
    else {
      //We have a patient list, so go ahead and set the first patient name in the list
      setPatientName("Welcome, " + patientList.given_name + "!");
    }
  }, [])

  function Welcome() {
    if(strFirstName === "") {
      getPatientList();
      return null;
    }

    const patientList = getGlobal("gActiveUser");

    const imgPatient = "data:image/png;base64," + patientList.photo;

    if(patientList.given_name === "" || patientList.photo === "") {
      return null;
    }

    return (
      <>
        <WelcomeTiana id='WelcomeLabel'>{strFirstName}</WelcomeTiana>
        <Ellipse1 id='WelcomeImage' src={imgPatient} loading="lazy" alt={'Ellipse 1'} />
      </>
    );
  }

  if (isAuthenticated) {
    if(!user.email_verified) {
      return ( 
        <>
          <div>
            <h3>Please verify your account before continuing</h3>
          </div>
        </>
      ) 
    }
    else {
      return (
        <PatientPortalECheckI className={props.className}>
          <Helmet>
            <title>Patient Portal</title>
          </Helmet>
          <Rectangle14></Rectangle14>
          <SubNav>
            <Rectangle4></Rectangle4>
            <Group6>
              <Rectangle12></Rectangle12>
              <Dashboard>{`Dashboard`}</Dashboard>
            </Group6>
            <Visits onClick={fns.handleODVisit}>{`Visits`}</Visits>
            <MessageCenter>{`Message Center`}</MessageCenter>
            <TestResults>{`Test Results`}</TestResults>
            <Medications>{`Medications`}</Medications>
          </SubNav>
          <Navigation>
            <Rectangle1></Rectangle1>
            <LogosAmwellLogoCompa
              src={LogosAmwellLogoCompaImage}
              loading="lazy"
              alt={'Logos/Amwell - Logo company'}
            />
            <Menu1 />
            <Group5>
              <Welcome />
            </Group5>
          </Navigation>
          <Group4 onClick={fns.onLogOut}>
            <Logout>{`Logout`}</Logout>
            <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
          </Group4>
          <Group15>
            <UpcomingVisit>
              <Rectangle6></Rectangle6>
              <Group7>
                <StartsAt1230PmEstVer>
                  {`Starts at 12:30 PM EST\nVerona Cardiology Clinic\nwith Dominic Bradley, MD`}
                </StartsAt1230PmEstVer>
                <Layer1>
                  <Vector1 src={Vector1Image} loading="lazy" alt={'Vector'} />
                </Layer1>
                <Layer11>
                  <Vector2 src={Vector2Image} loading="lazy" alt={'Vector'} />
                </Layer11>
                <Layer12>
                  <Vector3 src={Vector3Image} loading="lazy" alt={'Vector'} />
                </Layer12>
              </Group7>
              <UpcomingVisit1>{`Upcoming Visit`}</UpcomingVisit1>
              <Layer13 src={Layer13Image} loading="lazy" alt={'Layer_1'} />
              <Line3></Line3>
              <PanelChemistry
                src={PanelChemistryImage}
                loading="lazy"
                alt={'Panel Chemistry'}
              />
              <Button1>
                <LargePrimaryButton
                  onClick={fns.handleECheckIn}
                  src={LargePrimaryButtonImage}
                  loading="lazy"
                  alt={'Large Primary Button'}
                />
                <MediumSecondaryButto
                  src={MediumSecondaryButtoImage}
                  loading="lazy"
                  alt={'Medium Secondary Button'}
                />
              </Button1>
            </UpcomingVisit>
            <LeftCol>
              <Rectangle7></Rectangle7>
              <Group16>
                <MediumSecondaryButto1
                  src={MediumSecondaryButto1Image}
                  loading="lazy"
                  alt={'Medium Secondary Button'}
                />
                <RecentProvider>
                  <Rectangle8></Rectangle8>
                  <RecentProviders>{`Recent Providers`}</RecentProviders>
                  <Line4></Line4>
                  <Group11>
                    <DrDavidBowerMdPrimar>
                      <DrDavidBowerMdPrimarSpan1>{`Dr. David Bower, MD\n`}</DrDavidBowerMdPrimarSpan1>
                      <DrDavidBowerMdPrimarSpan2>{`Primary Care Provider - Family Practice\n`}</DrDavidBowerMdPrimarSpan2>
                      <DrDavidBowerMdPrimarSpan3>{`Schedule Visit`}</DrDavidBowerMdPrimarSpan3>
                      <DrDavidBowerMdPrimarSpan4>{`  |  `}</DrDavidBowerMdPrimarSpan4>
                      <DrDavidBowerMdPrimarSpan5>{`Write a Message`}</DrDavidBowerMdPrimarSpan5>
                    </DrDavidBowerMdPrimar>
                    <Ellipse3
                      src={Ellipse3Image}
                      loading="lazy"
                      alt={'Ellipse 3'}
                    />
                  </Group11>
                  <Group10>
                    <DrJacksonWilliamPtPh>
                      <DrJacksonWilliamPtPhSpan1>{`Dr. Jackson William, PT\n`}</DrJacksonWilliamPtPhSpan1>
                      <DrJacksonWilliamPtPhSpan2>{`Physical Therapist - Sport Medicine\n`}</DrJacksonWilliamPtPhSpan2>
                      <DrJacksonWilliamPtPhSpan3>{`Schedule Visit`}</DrJacksonWilliamPtPhSpan3>
                      <DrJacksonWilliamPtPhSpan4>{`  |  `}</DrJacksonWilliamPtPhSpan4>
                      <DrJacksonWilliamPtPhSpan5>{`Write a Message`}</DrJacksonWilliamPtPhSpan5>
                    </DrJacksonWilliamPtPh>
                    <Ellipse4
                      src={Ellipse4Image}
                      loading="lazy"
                      alt={'Ellipse 4'}
                    />
                  </Group10>
                  <Group12>
                    <DrJenniferMayorMdCar>
                      <DrJenniferMayorMdCarSpan1>{`Dr. Jennifer Mayor, MD\n`}</DrJenniferMayorMdCarSpan1>
                      <DrJenniferMayorMdCarSpan2>{`Cardiologist - Preventive Cardiology\n`}</DrJenniferMayorMdCarSpan2>
                      <DrJenniferMayorMdCarSpan3>{`Schedule Visit`}</DrJenniferMayorMdCarSpan3>
                      <DrJenniferMayorMdCarSpan4>{`  |  `}</DrJenniferMayorMdCarSpan4>
                      <DrJenniferMayorMdCarSpan5>{`Write a Message`}</DrJenniferMayorMdCarSpan5>
                    </DrJenniferMayorMdCar>
                    <Ellipse41
                      src={Ellipse41Image}
                      loading="lazy"
                      alt={'Ellipse 4'}
                    />
                  </Group12>
                </RecentProvider>
              </Group16>
              <VirtualCare>
                <Rectangle81></Rectangle81>
                <LargePrimaryButton1
                  onClick={fns.handleODVisit}
                  src={LargePrimaryButton1Image}
                  loading="lazy"
                  alt={'Large Primary Button'}
                />
                <VirtualUrgentCareOnD>
                  <VirtualUrgentCareOnDSpan1>{`Virtual Care is a service allows you to set up an online virtual visit with a `}</VirtualUrgentCareOnDSpan1>
                  <VirtualUrgentCareOnDSpan2>{`HealthCare`}</VirtualUrgentCareOnDSpan2>
                  <VirtualUrgentCareOnDSpan3>{` physician assistant, nurse practitioner, physician, Psychologist, or Behaviourial Health specialist.`}</VirtualUrgentCareOnDSpan3>
                </VirtualUrgentCareOnD>
                <VirtualUrgentCareOnD1>{`Virtual Care Options`}</VirtualUrgentCareOnD1>
                <Line41></Line41>
              </VirtualCare>
            </LeftCol>
            <Group14>
              <UnreadMessage>
                <Rectangle61></Rectangle61>
                <MediumSecondaryButto2
                  src={MediumSecondaryButto2Image}
                  loading="lazy"
                  alt={'Medium Secondary Button'}
                />
                <ReplyToDrDavidBowerM>{`Reply to  Dr. David Bower, MD`}</ReplyToDrDavidBowerM>
                <UnreadMessage1>{`Unread Message`}</UnreadMessage1>
                <Line31></Line31>
                <Vector4 src={Vector4Image} loading="lazy" alt={'Vector'} />
                <Chat>
                  <Group9>
                    <Union src={UnionImage} loading="lazy" alt={'Union'} />
                    <PleaseMakeSureYouCom>{`Please make sure you complete the Covid check before arrival`}</PleaseMakeSureYouCom>
                  </Group9>
                  <Ellipse2 src={Ellipse2Image} loading="lazy" alt={'Ellipse 2'} />
                </Chat>
              </UnreadMessage>
              <Balance>
                <Rectangle62></Rectangle62>
                <Group8>
                  <LargePrimaryButton2
                    src={LargePrimaryButton2Image}
                    loading="lazy"
                    alt={'Large Primary Button'}
                  />
                  <MediumSecondaryButto3
                    src={MediumSecondaryButto3Image}
                    loading="lazy"
                    alt={'Medium Secondary Button'}
                  />
                </Group8>
                <Group71>
                  <AbdomenUltrasoundDue>
                    {`Abdomen Ultrasound\nDue Nov 10, 2023`}
                  </AbdomenUltrasoundDue>
                </Group71>
                <OutstandingBalance>{`Outstanding Balance`}</OutstandingBalance>
                <Vector5 src={Vector5Image} loading="lazy" alt={'Vector'} />
                <Line32></Line32>
                <PanelChemistry1>
                  <Rectangle82></Rectangle82>
                  {false && <Rectangle9></Rectangle9>}
                  <Q234>{`$234`}</Q234>
                  <YouOwe>{`You Owe`}</YouOwe>
                  <Layer14></Layer14>
                </PanelChemistry1>
              </Balance>
              <Rectangle71></Rectangle71>
              <MediumSecondaryButto4
                src={MediumSecondaryButto4Image}
                loading="lazy"
                alt={'Medium Secondary Button'}
              />
              <PanelHematology>
                <Rectangle83></Rectangle83>
                <LinkedAccoutnts>{`Linked Accounts`}</LinkedAccoutnts>
                <Line42></Line42>
                <Group111>
                  <DrDavidBowerMdPrimar1>
                    <DrDavidBowerMdPrimar1Span1>{`Dr. David Bower, MD\n`}</DrDavidBowerMdPrimar1Span1>
                    <DrDavidBowerMdPrimar1Span2>{`Primary Care Provider - Family Practice\n`}</DrDavidBowerMdPrimar1Span2>
                    <DrDavidBowerMdPrimar1Span3>{`Schedule Visit`}</DrDavidBowerMdPrimar1Span3>
                    <DrDavidBowerMdPrimar1Span4>{`  |  `}</DrDavidBowerMdPrimar1Span4>
                    <DrDavidBowerMdPrimar1Span5>{`Write a Message`}</DrDavidBowerMdPrimar1Span5>
                  </DrDavidBowerMdPrimar1>
                  <Ellipse31
                    src={Ellipse31Image}
                    loading="lazy"
                    alt={'Ellipse 3'}
                  />
                </Group111>
                <Group101>
                  <DrJacksonWilliamPtPh1>
                    <DrJacksonWilliamPtPh1Span1>{`Dr. Jackson William, PT\n`}</DrJacksonWilliamPtPh1Span1>
                    <DrJacksonWilliamPtPh1Span2>{`Physical Therapist - Sport Medicine\n`}</DrJacksonWilliamPtPh1Span2>
                    <DrJacksonWilliamPtPh1Span3>{`Schedule Visit`}</DrJacksonWilliamPtPh1Span3>
                    <DrJacksonWilliamPtPh1Span4>{`  |  `}</DrJacksonWilliamPtPh1Span4>
                    <DrJacksonWilliamPtPh1Span5>{`Write a Message`}</DrJacksonWilliamPtPh1Span5>
                  </DrJacksonWilliamPtPh1>
                  <Ellipse42
                    src={Ellipse42Image}
                    loading="lazy"
                    alt={'Ellipse 4'}
                  />
                </Group101>
                <Group121>
                  <DrJenniferMayaMdCard>
                    <DrJenniferMayaMdCardSpan1>{`Dr. Jennifer Maya, MD\n`}</DrJenniferMayaMdCardSpan1>
                    <DrJenniferMayaMdCardSpan2>{`Cardiologist - Preventive Cardiology\n`}</DrJenniferMayaMdCardSpan2>
                    <DrJenniferMayaMdCardSpan3>{`Schedule Visit`}</DrJenniferMayaMdCardSpan3>
                    <DrJenniferMayaMdCardSpan4>{`  |  `}</DrJenniferMayaMdCardSpan4>
                    <DrJenniferMayaMdCardSpan5>{`Write a Message`}</DrJenniferMayaMdCardSpan5>
                  </DrJenniferMayaMdCard>
                  <Ellipse43
                    src={Ellipse43Image}
                    loading="lazy"
                    alt={'Ellipse 4'}
                  />
                </Group121>
              </PanelHematology>
              <PanelHematology1>
                <Rectangle84></Rectangle84>
                <LargePrimaryButton3
                  src={LargePrimaryButton3Image}
                  loading="lazy"
                  alt={'Large Primary Button'}
                />
                <VirtualUrgentCareOnD2>
                  {`Virtual Urgent Care On-Demand is a service allows you to set up a virtual visit with a HealthCare physician, assistant, nurse practitioner, or physician.`}
                </VirtualUrgentCareOnD2>
                <VirtualUrgentCareOnD3>
                  {`Virtual Urgent Care On-Demand`}
                </VirtualUrgentCareOnD3>
                <Line43></Line43>
              </PanelHematology1>
            </Group14>
          </Group15>
          <GenericPortal
            src={GenericPortalImage}
            loading="lazy"
            alt={'GenericPortal'}
          />
        </PatientPortalECheckI>
      );
    }
  }
  else {       
    return (
        <>
          <br/>
          <h5>You're not currently Logged In.</h5>
          <h4>Please click the link above to log in.</h4>
          <br/>
        </>
    )     
  }
}

export default PatientPortalECheckIn;

const PatientPortalECheckI = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1024px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Rectangle14 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  width: `1440px`,
  height: `1058px`,
  position: `absolute`,
  left: `0px`,
  top: `70px`,
}));

const SubNav = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `63px`,
  left: `0px`,
  top: `70px`,
});

const Rectangle4 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 8px 36px rgba(0, 0, 0, 0.16)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `62px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group6 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `103px`,
  height: `43px`,
  left: `90px`,
  top: `20px`,
});

const Rectangle12 = styled('div')({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  width: `103px`,
  height: `6px`,
  position: `absolute`,
  left: `0px`,
  top: `37px`,
});

const Dashboard = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Visits = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `241px`,
  top: `20px`,
  cursor: `pointer`,
}));

const MessageCenter = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `339px`,
  top: `20px`,
}));

const TestResults = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `536px`,
  top: `20px`,
}));

const Medications = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `693px`,
  top: `20px`,
}));

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LogosAmwellLogoCompa = styled('img')({
  height: `auto`,
  width: `160px`,
  position: `absolute`,
  left: `76px`,
  top: `19px`,
});

const Menu1 = styled(Menu)(({ theme }) => ({
  width: `24px`,
  height: `24px`,
  position: `absolute`,
  left: `28px`,
  top: `23px`,
}));

const Group5 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `210px`,
  height: `46px`,
  left: `1056px`,
  top: `12px`,
});

const WelcomeTiana = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  width: '455px',
  left: `55px`,
  top: `11px`,
}));

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1316px`,
  top: `23px`,
});

const Logout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `39px`,
  top: `0px`,
  cursor: `pointer`,
}));

const Vector = styled('img')({
  height: `auto`,
  width: `24px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group15 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1263px`,
  height: `1550px`,
  left: `87px`,
  top: `170px`,
});

const UpcomingVisit = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `710px`,
  height: `248px`,
  left: `3px`,
  top: `0px`,
});

const Rectangle6 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `710px`,
  height: `248px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group7 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `235px`,
  height: `108px`,
  left: `184px`,
  top: `108px`,
});

const StartsAt1230PmEstVer = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
  position: `absolute`,
  width: `350px`,
  left: `34px`,
  top: `0px`,
}));

const Layer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `19.79px`,
  left: `0px`,
  top: `79px`,
  overflow: `hidden`,
});

const Vector1 = styled('img')({
  height: `19.79px`,
  width: `19.99px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Layer11 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `18.02px`,
  left: `2px`,
  top: `45px`,
  overflow: `hidden`,
});

const Vector2 = styled('img')({
  height: `18.03px`,
  width: `20px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Layer12 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `2px`,
  top: `9px`,
  overflow: `hidden`,
});

const Vector3 = styled('img')({
  height: `20px`,
  width: `20px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const UpcomingVisit1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `71px`,
  top: `30px`,
}));

const Layer13 = styled('img')({
  height: `auto`,
  width: `24.83px`,
  position: `absolute`,
  left: `30px`,
  top: `30px`,
});

const Line3 = styled('div')({
  border: `1px solid rgba(190, 195, 204, 1)`,
  width: `710px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `84px`,
});

const PanelChemistry = styled('img')({
  height: `106px`,
  width: `620px`,
  position: `absolute`,
  left: `30px`,
  top: `109px`,
});

const Button1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `131px`,
  height: `88px`,
  left: `537px`,
  top: `108px`,
});

const LargePrimaryButton = styled('img')({
  height: `36px`,
  width: `131px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  cursor: `pointer`,
});

const MediumSecondaryButto = styled('img')({
  height: `38px`,
  width: `131px`,
  position: `absolute`,
  left: `0px`,
  top: `50px`,
});

const LeftCol = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `520px`,
  height: `763px`,
  left: `743px`,
  top: `0px`,
});

const Rectangle7 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `520px`,
  height: `763px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group16 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `428px`,
  left: `20px`,
  top: `300px`,
});

const MediumSecondaryButto1 = styled('img')({
  height: `38px`,
  width: `95px`,
  position: `absolute`,
  left: `378px`,
  top: `390px`,
});

const RecentProvider = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `371px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle8 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `371px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const RecentProviders = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `24px`,
  top: `14px`,
}));

const Line4 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const Group11 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `404px`,
  height: `72px`,
  left: `24px`,
  top: `71px`,
});

const DrDavidBowerMdPrimarSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimarSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimarSpan3 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimarSpan4 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimarSpan5 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimar = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `0px`,
});

const Ellipse3 = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group10 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `371px`,
  height: `72px`,
  left: `24px`,
  top: `167px`,
});

const DrJacksonWilliamPtPhSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPhSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPhSpan3 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPhSpan4 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPhSpan5 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPh = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `0px`,
});

const Ellipse4 = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group12 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `380px`,
  height: `72px`,
  left: `24px`,
  top: `263px`,
});

const DrJenniferMayorMdCarSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayorMdCarSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  width: `550px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayorMdCarSpan3 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayorMdCarSpan4 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayorMdCarSpan5 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayorMdCar = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `0px`,
});

const Ellipse41 = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const VirtualCare = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `246px`,
  left: `20px`,
  top: `30px`,
});

const Rectangle81 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `246px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LargePrimaryButton1 = styled('img')({
  height: `48px`,
  width: `194px`,
  position: `absolute`,
  left: `143px`,
  top: `166px`,
  cursor: `pointer`,
});

const VirtualUrgentCareOnDSpan1 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
}));

const VirtualUrgentCareOnDSpan2 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
}));

const VirtualUrgentCareOnDSpan3 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
}));

const VirtualUrgentCareOnD = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `432px`,
  position: `absolute`,
  left: `24px`,
  top: `60px`,
}));

const VirtualUrgentCareOnD1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `24px`,
  top: `14px`,
}));

const Line41 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const Group14 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1263px`,
  height: `1285px`,
  left: `0px`,
  top: `265px`,
});

const UnreadMessage = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `710px`,
  height: `287px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle61 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `710px`,
  height: `287px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const MediumSecondaryButto2 = styled('img')({
  height: `38px`,
  width: `130px`,
  position: `absolute`,
  left: `541px`,
  top: `223px`,
});

const ReplyToDrDavidBowerM = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
  position: `absolute`,
  left: `433px`,
  top: `173px`,
});

const UnreadMessage1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `68px`,
  top: `37px`,
}));

const Line31 = styled('div')({
  border: `1px solid rgba(190, 195, 204, 1)`,
  width: `710px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `91px`,
});

const Vector4 = styled('img')({
  height: `auto`,
  width: `24px`,
  position: `absolute`,
  left: `33px`,
  top: `40px`,
});

const Chat = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `616px`,
  height: `64px`,
  left: `30px`,
  top: `113px`,
});

const Group9 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `553px`,
  height: `47px`,
  left: `63px`,
  top: `8px`,
});

const Union = styled('img')({
  height: `47px`,
  width: `553px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const PleaseMakeSureYouCom = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
  position: `absolute`,
  left: `30px`,
  top: `6px`,
}));

const Ellipse2 = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Balance = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `710px`,
  height: `248px`,
  left: `3px`,
  top: `311px`,
});

const Rectangle62 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `710px`,
  height: `248px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group8 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `131px`,
  height: `88px`,
  left: `537px`,
  top: `108px`,
});

const LargePrimaryButton2 = styled('img')({
  height: `36px`,
  width: `131px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const MediumSecondaryButto3 = styled('img')({
  height: `38px`,
  width: `131px`,
  position: `absolute`,
  left: `0px`,
  top: `50px`,
});

const Group71 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `171px`,
  height: `48px`,
  left: `185px`,
  top: `135px`,
});

const AbdomenUltrasoundDue = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const OutstandingBalance = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `70px`,
  top: `30px`,
}));

const Vector5 = styled('img')({
  height: `auto`,
  width: `24px`,
  position: `absolute`,
  left: `30px`,
  top: `34px`,
});

const Line32 = styled('div')({
  border: `1px solid rgba(190, 195, 204, 1)`,
  width: `710px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `84px`,
});

const PanelChemistry1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `620px`,
  height: `106px`,
  left: `30px`,
  top: `109px`,
});

const Rectangle82 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `128px`,
  height: `106px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Rectangle9 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(232, 234, 237, 1)`,
  borderRadius: `8px 8px 0px 0px`,
  width: `128px`,
  height: `26px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Q234 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `32px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `24px`,
  top: `51px`,
}));

const YouOwe = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `37px`,
  top: `15px`,
}));

const Layer14 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  width: `15px`,
  height: `8px`,
  left: `605px`,
  top: `21px`,
  overflow: `hidden`,
});

const Rectangle71 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `520px`,
  height: `763px`,
  position: `absolute`,
  left: `743px`,
  top: `522px`,
}));

const MediumSecondaryButto4 = styled('img')({
  height: `38px`,
  width: `95px`,
  position: `absolute`,
  left: `1141px`,
  top: `942px`,
});

const PanelHematology = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `371px`,
  left: `763px`,
  top: `552px`,
});

const Rectangle83 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `371px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LinkedAccoutnts = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `24px`,
  top: `14px`,
}));

const Line42 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const Group111 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `404px`,
  height: `72px`,
  left: `24px`,
  top: `71px`,
});

const DrDavidBowerMdPrimar1Span1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimar1Span2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimar1Span3 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(175, 33, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimar1Span4 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimar1Span5 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(175, 33, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrDavidBowerMdPrimar1 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `0px`,
});

const Ellipse31 = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group101 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `371px`,
  height: `72px`,
  left: `24px`,
  top: `167px`,
});

const DrJacksonWilliamPtPh1Span1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPh1Span2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPh1Span3 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(175, 33, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPh1Span4 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPh1Span5 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(175, 33, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  width: `300px`,
  textTransform: `none`,
});

const DrJacksonWilliamPtPh1 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `0px`,
});

const Ellipse42 = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group121 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `380px`,
  height: `72px`,
  left: `24px`,
  top: `263px`,
});

const DrJenniferMayaMdCardSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayaMdCardSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayaMdCardSpan3 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(175, 33, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayaMdCardSpan4 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayaMdCardSpan5 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(175, 33, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const DrJenniferMayaMdCard = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `0px`,
});

const Ellipse43 = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const PanelHematology1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `246px`,
  left: `763px`,
  top: `1010px`,
});

const Rectangle84 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `246px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LargePrimaryButton3 = styled('img')({
  height: `48px`,
  width: `194px`,
  position: `absolute`,
  left: `143px`,
  top: `166px`,
});

const VirtualUrgentCareOnD2 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `432px`,
  position: `absolute`,
  left: `24px`,
  top: `72px`,
}));

const VirtualUrgentCareOnD3 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `24px`,
  top: `14px`,
}));

const Line43 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const GenericPortal = styled('img')({
  height: `auto`,
  width: `155px`,
  position: `absolute`,
  left: `256px`,
  top: `23px`,
});

 