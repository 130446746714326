/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { styled } from '@mui/material/styles';
import LandscapeImage from 'src/assets/images/PatientPortaleCheckIn_Landscape.png';
import LogosAmwellLogoCompaImage from 'src/assets/images/PatientPortaleCheckIn_Logos_Amwell___Logo_company.svg';
import VectorImage from 'src/assets/images/PatientPortaleCheckIn_Vector.svg';
import GenericPortalImage from 'src/assets/images/PatientPortaleCheckIn_GenericPortal.svg';
import Menu from 'src/components/Menu/Menu';
import { getGlobal } from '../../globals';
import { getPatientList } from '../../modules/Utilities';

function PatientEmbeddedConverge(props) {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth0();
  const [ strFirstName, setPatientName ] = useState("");
  const { convergeUrl } = getGlobal('config') || {};

  useEffect(() => {
    const arrPatientList = getGlobal("gActiveUser");
    
    if(!arrPatientList) {
      getPatientList();
      return;
    }
    else {
      //We have a patient list, so go ahead and set the first patient name in the list
      setPatientName("Welcome, " + arrPatientList.given_name + "!");
    }
  }, [])

  function handleOnDashboard(){
    navigate('/PatientPortalECheckIn');
  }

  function handleLogout(){
    navigate('/LogIn');
  }

  function Welcome() {
    if(strFirstName === "") {
      getPatientList();
      return null;
    }

    const patientList = getGlobal("gActiveUser");

    const imgPatient = "data:image/png;base64," + patientList.photo;

    if(patientList.given_name === "" || patientList.photo === "") {
      return null;
    }

    return (
      <>
        <WelcomeTiana id='WelcomeLabel'>{strFirstName}</WelcomeTiana>
        <Ellipse1 id='WelcomeImage' src={imgPatient} loading="lazy" alt={'Ellipse 1'} />
      </>
    );
  }

  if (isAuthenticated) {
    if(!user.email_verified) {
      return ( 
        <>
          <div>
            <h3>Please verify your account before continuing</h3>
          </div>
        </>
      ) 
    }
    else {
      return (
        <PatientPortalEmbedded className={props.className}>
          <Helmet>
            <title>Patient Portal</title>
          </Helmet>
          <Landscape src={LandscapeImage} loading="lazy" alt={'Landscape'} />
          <Rectangle14>
            <iframe className="PortalExp-UCVisitFrame" id="Converge"
                title="EmbeddedConvergeVisit"
                allowFullScreen
                src={convergeUrl}>
            </iframe>
          </Rectangle14>
          <SubNav>
          <DashboardMenuGroup onClick={handleOnDashboard}></DashboardMenuGroup>
            <Rectangle4></Rectangle4>
            <Group6>
              <Dashboard onClick={handleOnDashboard}>{`Dashboard`}</Dashboard>
            </Group6>
            <VisitsGroup>
              <Rectangle12></Rectangle12>
              <Visits>{`Visits`}</Visits>
            </VisitsGroup>
            <MessageCenter>{`Message Center`}</MessageCenter>
            <TestResults>{`Test Results`}</TestResults>
            <Medications>{`Medications`}</Medications>
          </SubNav>
          <Navigation>
            <Rectangle1></Rectangle1>
            <LogosAmwellLogoCompa
              src={LogosAmwellLogoCompaImage}
              loading="lazy"
              alt={'Logos/Amwell - Logo company'}
            />
            <Menu1 />
            <Group5>
              <Welcome />
            </Group5>
          </Navigation>
          <Group4 onClick={handleLogout}>
            <Logout>{`Logout`}</Logout>
            <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
          </Group4>
          <GenericPortal
            src={GenericPortalImage}
            loading="lazy"
            alt={'GenericPortal'}
          />
        </PatientPortalEmbedded>
      );
    }
  }
  else {       
    return (
        <>
          <br/>
          <h5>You're not currently Logged In.</h5>
          <h4>Please click the link above to log in.</h4>
          <br/>
        </>
    )     
  }
}

export default PatientEmbeddedConverge;

const PatientPortalEmbedded = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1024px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Landscape = styled('img')({
  height: `1058px`,
  width: `1348px`,
  position: `absolute`,
  left: `91px`,
  top: `0px`,
});

const Rectangle14 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  width: `1440px`,
  height: `890px`,
  position: `absolute`,
  left: `0px`,
  top: `133px`,
}));

const SubNav = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `63px`,
  left: `0px`,
  top: `70px`,
});

const Rectangle4 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 8px 36px rgba(0, 0, 0, 0.16)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `62px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group6 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `103px`,
  height: `43px`,
  left: `90px`,
  top: `20px`,
});

const DashboardMenuGroup = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `103px`,
  height: `43px`,
  left: `90px`,
  top: `20px`,
});


const Dashboard = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
  cursor: `pointer`,
});

const VisitsGroup = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `103px`,
  height: `43px`,
  left: `240px`,
  top: `0px`,
});

const Rectangle12 = styled('div')({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  width: `50px`,
  height: `6px`,
  position: `absolute`,
  left: `0px`,
  top: `56px`,
});

const Visits = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `20px`,
}));

const MessageCenter = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `339px`,
  top: `20px`,
}));

const TestResults = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `536px`,
  top: `20px`,
}));

const Medications = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `693px`,
  top: `20px`,
}));

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LogosAmwellLogoCompa = styled('img')({
  height: `auto`,
  width: `160px`,
  position: `absolute`,
  left: `76px`,
  top: `19px`,
});

const Menu1 = styled(Menu)(({ theme }) => ({
  width: `24px`,
  height: `24px`,
  position: `absolute`,
  left: `28px`,
  top: `23px`,
}));

const Group5 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `210px`,
  height: `46px`,
  left: `1056px`,
  top: `12px`,
});

const WelcomeTiana = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  width: '455px',
  left: `55px`,
  top: `11px`,
}));

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1316px`,
  top: `23px`,
});

const Logout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `39px`,
  top: `0px`,
  cursor: `pointer`,
}));

const Vector = styled('img')({
  height: `auto`,
  width: `24px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GenericPortal = styled('img')({
  height: `auto`,
  width: `155px`,
  position: `absolute`,
  left: `256px`,
  top: `23px`,
});

