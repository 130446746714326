import { Outlet } from 'react-router-dom';
import MainHeader from '../components/MainHeader';
import Footer from '../components/footer';
import "./SharedLayout.css"

const Home = () => {
  
  return (
    <>
      <MainHeader />
      {/*<span className='TenantSpan'>
        <input type="text" id="TenantID" placeholder={strTenantID.current} onChange={handleTenantIdChange}/>
        <input type="text" id="EhrID" placeholder={strEhrID.current} onChange={handleEhrIdChange}/>
  </span>*/}
      <h1 className='homeTitle' textcolor='red' >Solutions Design Demo Portal</h1>
      <Outlet />
      <Footer />
    </>
  );
};
export default Home;
