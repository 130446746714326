/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import LogosAmwellLogoCompaImage from 'src/assets/images/PatientPortaleCheckInStep5_Logos_Amwell___Logo_company.svg';
import VectorImage from 'src/assets/images/PatientPortaleCheckInStep5_Vector.png';
import Vector1Image from 'src/assets/images/PatientPortaleCheckInStep5_Vector_1.png';
import SecondaryButtonImage from 'src/assets/images/PatientPortaleCheckInStep5_Secondary_Button.png';
import Ellipse652Image from 'src/assets/images/PatientPortaleCheckInStep5_Ellipse_652.png';
import MaskGroupImage from 'src/assets/images/PatientPortaleCheckInStep5_Mask_Group.png';
import Rectangle5617Image from 'src/assets/images/PatientPortaleCheckInStep5_Rectangle_5617.png';
import Rectangle5620Image from 'src/assets/images/PatientPortaleCheckInStep5_Rectangle_5620.png';
import Vector2Image from 'src/assets/images/PatientPortaleCheckInStep5_Vector_2.png';
import Vector3Image from 'src/assets/images/PatientPortaleCheckInStep5_Vector_3.png';
import Rectangle5619Image from 'src/assets/images/PatientPortaleCheckInStep5_Rectangle_5619.png';
import Ellipse654Image from 'src/assets/images/PatientPortaleCheckInStep5_Ellipse_654.png';
import Ellipse655Image from 'src/assets/images/PatientPortaleCheckInStep5_Ellipse_655.png';
import Ellipse656Image from 'src/assets/images/PatientPortaleCheckInStep5_Ellipse_656.png';
import Ellipse658Image from 'src/assets/images/PatientPortaleCheckInStep5_Ellipse_658.png';
import Path25916Image from 'src/assets/images/PatientPortaleCheckInStep5_Path_25916.png';
import Vector4Image from 'src/assets/images/PatientPortaleCheckInStep5_Vector_4.png';
import Vector5Image from 'src/assets/images/PatientPortaleCheckInStep5_Vector_5.png';
import SecondaryButton1Image from 'src/assets/images/PatientPortaleCheckInStep5_Secondary_Button_1.png';
import StepImage from 'src/assets/images/PatientPortaleCheckInStep5_Step.png';
import QImage from 'src/assets/images/PatientPortaleCheckInStep5___.png';
import Q1Image from 'src/assets/images/PatientPortaleCheckInStep5____1.png';
import Q2Image from 'src/assets/images/PatientPortaleCheckInStep5____2.png';
import { styled } from '@mui/material/styles';
import usePatientPortalECheckInStep5 from './usePatientPortalECheckInStep5';
import { getGlobal } from '../../globals';
import { getPatientList } from '../../modules/Utilities';

function PatientPortalECheckInStep5(props) {
  const { fns } = usePatientPortalECheckInStep5();
  const { isAuthenticated, user } = useAuth0();
  const [ strFirstName, setPatientName ] = useState("");

  useEffect(() => {
    const arrPatientList = getGlobal("gActiveUser");
    
    if(!arrPatientList) {
      getPatientList();
      return;
    }
    else {
      //We have a patient list, so go ahead and set the first patient name in the list
      setPatientName("Welcome, " + arrPatientList.given_name + "!");
    }
  }, [])

  function Welcome() {
    if(strFirstName === "") {
      getPatientList();
      return null;
    }

    const patientList = getGlobal("gActiveUser");

    const imgPatient = "data:image/png;base64," + patientList.photo;

    if(patientList.given_name === "" || patientList.photo === "") {
      return null;
    }

    return (
      <>
        <WelcomeTiana id='WelcomeLabel'>{strFirstName}</WelcomeTiana>
        <Ellipse1 id='WelcomeImage' src={imgPatient} loading="lazy" alt={'Ellipse 1'} />
      </>
    );
  }

  if (isAuthenticated) {
    if(!user.email_verified) {
      return ( 
        <>
          <div>
            <h3>Please verify your account before continuing</h3>
          </div>
        </>
      ) 
    }
    else {
      return (
        <PatientPortalECheckI className={props.className}>
          <PatientPortalECheckI1>
            <Rectangle14></Rectangle14>
            <ECheckIn>{`eCheck-in`}</ECheckIn>
            <SubNav>
              <Rectangle4></Rectangle4>
              <Group6>
                <Rectangle12></Rectangle12>
                <Dashboard>{`Dashboard`}</Dashboard>
              </Group6>
              <Visits>{`Visits`}</Visits>
              <MessageCenter>{`Message Center`}</MessageCenter>
              <TestResults>{`Test Results`}</TestResults>
              <Medications>{`Medications`}</Medications>
            </SubNav>
            <Navigation>
              <Rectangle1></Rectangle1>
              <LogosAmwellLogoCompa
                src={LogosAmwellLogoCompaImage}
                loading="lazy"
                alt={'Logos/Amwell - Logo company'}
              />
              <Menu>
                <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
              </Menu>
              <Group5>
                <Welcome />
              </Group5>
            </Navigation>
            <Group4 onClick={fns.handleLogOut}>
              <Logout>{`Logout`}</Logout>
              <Vector1 src={Vector1Image} loading="lazy" alt={'Vector'} />
            </Group4>
            <GenericPortal>
              <GenericPortalSpan1>{`Generic`}</GenericPortalSpan1>
              <GenericPortalSpan2>{`Portal`}</GenericPortalSpan2>
            </GenericPortal>
            <Group26788>
              <Rectangle7></Rectangle7>
              <Group26784>
                <SecondaryButton
                  onClick={fns.handleAddAllergies}
                  src={SecondaryButtonImage}
                  loading="lazy"
                  alt={'Secondary Button'}
                />
                <YouHaveNoAllergiesOn>
                  {`You have no Allergies on file.`}
                </YouHaveNoAllergiesOn>
                <MaskGroup2>
                  <Ellipse652
                    src={Ellipse652Image}
                    loading="lazy"
                    alt={'Ellipse 652'}
                  />
                  <Group>
                    <MaskGroup
                      src={MaskGroupImage}
                      loading="lazy"
                      alt={'Mask Group'}
                    />
                  </Group>
                  <Rectangle5617
                    src={Rectangle5617Image}
                    loading="lazy"
                    alt={'Rectangle 5617'}
                  />
                  <Rectangle5620
                    src={Rectangle5620Image}
                    loading="lazy"
                    alt={'Rectangle 5620'}
                  />
                  <Rectangle5618>
                    <Vector2 src={Vector2Image} loading="lazy" alt={'Vector'} />
                    <Vector3 src={Vector3Image} loading="lazy" alt={'Vector'} />
                  </Rectangle5618>
                  <Rectangle5619
                    src={Rectangle5619Image}
                    loading="lazy"
                    alt={'Rectangle 5619'}
                  />
                  <Group26781>
                    <Ellipse654
                      src={Ellipse654Image}
                      loading="lazy"
                      alt={'Ellipse 654'}
                    />
                    <Ellipse655
                      src={Ellipse655Image}
                      loading="lazy"
                      alt={'Ellipse 655'}
                    />
                    <Ellipse656
                      src={Ellipse656Image}
                      loading="lazy"
                      alt={'Ellipse 656'}
                    />
                  </Group26781>
                  <Group26782>
                    <Ellipse658
                      src={Ellipse658Image}
                      loading="lazy"
                      alt={'Ellipse 658'}
                    />
                    <Path25916
                      src={Path25916Image}
                      loading="lazy"
                      alt={'Path 25916'}
                    />
                  </Group26782>
                </MaskGroup2>
              </Group26784>
              <Group26783>
                <Rectangle8></Rectangle8>
                <PrimaryButton onClick={fns.handleOnNext}>
                  {false && <DangerBg></DangerBg>}
                  {false && (
                    <ChevronLeft>
                      <Vector4 src={Vector4Image} loading="lazy" alt={'Vector'} />
                    </ChevronLeft>
                  )}
                  <ActionL>{`Next`}</ActionL>
                  {false && (
                    <ChevronRight>
                      <Vector5 src={Vector5Image} loading="lazy" alt={'Vector'} />
                    </ChevronRight>
                  )}
                </PrimaryButton>
                <SecondaryButton1
                  onClick={fns.handleFinishLater}
                  src={SecondaryButton1Image}
                  loading="lazy"
                  alt={'Secondary Button'}
                />
                <PleaseReviewYourInsu>
                  <PleaseReviewYourInsuSpan1>{`Please review your allergies and verify the list is up-to-date. To edit an existing allergy, please delete the allergy then add it back with the necessary changes. `}</PleaseReviewYourInsuSpan1>
                  <PleaseReviewYourInsuSpan2>{`Please call 911 if you have an emergency.`}</PleaseReviewYourInsuSpan2>
                </PleaseReviewYourInsu>
              </Group26783>
              <Frame12>
                <Step src={StepImage} loading="lazy" alt={'Step'} />
                <Step1>
                  <Frame4>
                    <Rectangle2></Rectangle2>
                    <Frame3>
                      <Frame15>
                        <Q src={QImage} loading="lazy" alt={''} />
                      </Frame15>
                    </Frame3>
                    <Rectangle11></Rectangle11>
                  </Frame4>
                  <StepText>{`Insurance`}</StepText>
                </Step1>
                <Step2>
                  <Frame41>
                    <Rectangle21></Rectangle21>
                    <Frame31>
                      <Frame151>
                        <Q1 src={Q1Image} loading="lazy" alt={''} />
                      </Frame151>
                    </Frame31>
                    <Rectangle13></Rectangle13>
                  </Frame41>
                  <StepText1>{`Sign`}</StepText1>
                </Step2>
                <Step3>
                  <Frame42>
                    <Rectangle22></Rectangle22>
                    <Frame32>
                      <Frame152>
                        <Q2 src={Q2Image} loading="lazy" alt={''} />
                      </Frame152>
                    </Frame32>
                    <Rectangle15></Rectangle15>
                  </Frame42>
                  <StepText2>{`Medications`}</StepText2>
                </Step3>
                <Step4>
                  <Frame43>
                    <Rectangle23></Rectangle23>
                    <Frame7>
                      <Frame33>
                        <Q11>{`5`}</Q11>
                      </Frame33>
                    </Frame7>
                    <Rectangle16></Rectangle16>
                  </Frame43>
                  <StepText3>{`Allergies`}</StepText3>
                </Step4>
                <Step5>
                  <Frame44>
                    <Rectangle24></Rectangle24>
                    <Frame34>
                      <Q12>{`6`}</Q12>
                    </Frame34>
                    <Rectangle17></Rectangle17>
                  </Frame44>
                  <StepText4>{`Health Issues`}</StepText4>
                </Step5>
              </Frame12>
            </Group26788>
          </PatientPortalECheckI1>
        </PatientPortalECheckI>
      );
    }
  }
  else {       
    return (
        <>
          <br/>
          <h5>You're not currently Logged In.</h5>
          <h4>Please click the link above to log in.</h4>
          <br/>
        </>
    )     
  }
}

export default PatientPortalECheckInStep5;

const PatientPortalECheckI = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1024px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
});

const PatientPortalECheckI1 = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `1024px`,
  left: `0px`,
  top: `0px`,
  overflow: `hidden`,
});

const Rectangle14 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  width: `1440px`,
  height: `1058px`,
  position: `absolute`,
  left: `0px`,
  top: `70px`,
}));

const ECheckIn = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `32px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `194px`,
}));

const SubNav = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `62px`,
  left: `0px`,
  top: `70px`,
});

const Rectangle4 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 8px 36px rgba(0, 0, 0, 0.16)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `62px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group6 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `200px`,
  height: `42px`,
  left: `90px`,
  top: `20px`,
});

const Rectangle12 = styled('div')({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  width: `49px`,
  height: `6px`,
  position: `absolute`,
  left: `151px`,
  top: `36px`,
});

const Dashboard = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Visits = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `241px`,
  top: `20px`,
});

const MessageCenter = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `339px`,
  top: `20px`,
}));

const TestResults = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `536px`,
  top: `20px`,
}));

const Medications = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `693px`,
  top: `20px`,
}));

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LogosAmwellLogoCompa = styled('img')({
  height: `auto`,
  width: `160px`,
  position: `absolute`,
  left: `76px`,
  top: `19px`,
});

const Menu = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `28px`,
  top: `23px`,
  overflow: `hidden`,
});

const Vector = styled('img')({
  height: `12px`,
  width: `18px`,
  position: `absolute`,
  left: `3px`,
  top: `6px`,
});

const Group5 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `210px`,
  height: `46px`,
  left: `1056px`,
  top: `12px`,
});

const WelcomeTiana = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  width: `455px`,
  left: `55px`,
  top: `11px`,
}));

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1316px`,
  top: `23px`,
  cursor: `pointer`,
});

const Logout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `39px`,
  top: `0px`,
  cursor: `pointer`,
}));

const Vector1 = styled('img')({
  height: `24px`,
  width: `24px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GenericPortalSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `22px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const GenericPortalSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `800`,
  fontSize: `22px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const GenericPortal = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `22px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `256px`,
  top: `23px`,
});

const Group26788 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1260px`,
  height: `819px`,
  left: `90px`,
  top: `239px`,
});

const Rectangle7 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `1260px`,
  height: `819px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group26784 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `607px`,
  height: `244px`,
  left: `327px`,
  top: `184px`,
});

const SecondaryButton = styled('img')({
  height: `38px`,
  width: `206px`,
  position: `absolute`,
  left: `200px`,
  top: `206px`,
  cursor: `pointer`,
});

const YouHaveNoAllergiesOn = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `607px`,
  position: `absolute`,
  left: `0px`,
  top: `166px`,
}));

const MaskGroup2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `150px`,
  height: `150px`,
  left: `228px`,
  top: `0px`,
});

const Ellipse652 = styled('img')({
  height: `150px`,
  width: `150px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group = styled('div')({
  boxShadow: `0px -3px 6px rgba(201, 205, 217, 0.35)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `150px`,
  height: `150px`,
  left: `0px`,
  top: `0px`,
});

const MaskGroup = styled('img')({
  height: `150px`,
  width: `150px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Rectangle5617 = styled('img')({
  height: `6px`,
  width: `32px`,
  position: `absolute`,
  left: `37px`,
  top: `53px`,
});

const Rectangle5620 = styled('img')({
  height: `6px`,
  width: `32px`,
  position: `absolute`,
  left: `37px`,
  top: `95px`,
});

const Rectangle5618 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `76px`,
  height: `20px`,
  left: `37px`,
  top: `67px`,
});

const Vector2 = styled('img')({
  height: `20px`,
  width: `76px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Vector3 = styled('img')({
  height: `18px`,
  width: `74px`,
  position: `absolute`,
  left: `1px`,
  top: `1px`,
});

const Rectangle5619 = styled('img')({
  height: `18px`,
  width: `76px`,
  position: `absolute`,
  left: `37px`,
  top: `109px`,
});

const Group26781 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `52px`,
  height: `8px`,
  left: `49px`,
  top: `24px`,
});

const Ellipse654 = styled('img')({
  height: `8px`,
  width: `8px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Ellipse655 = styled('img')({
  height: `8px`,
  width: `8px`,
  position: `absolute`,
  left: `22px`,
  top: `0px`,
});

const Ellipse656 = styled('img')({
  height: `8px`,
  width: `8px`,
  position: `absolute`,
  left: `44px`,
  top: `0px`,
});

const Group26782 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `19.09px`,
  height: `26.37px`,
  left: `79px`,
  top: `78px`,
});

const Ellipse658 = styled('img')({
  height: `10px`,
  width: `10px`,
  position: `absolute`,
  left: `2px`,
  top: `0px`,
});

const Path25916 = styled('img')({
  height: `22.45px`,
  width: `19.09px`,
  position: `absolute`,
  left: `0px`,
  top: `4px`,
});

const Group26783 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1196px`,
  height: `92px`,
  left: `32px`,
  top: `665px`,
});

const Rectangle8 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `1196px`,
  height: `92px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const PrimaryButton = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  borderRadius: `25px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `10.5px 24px`,
  boxSizing: `border-box`,
  width: `152px`,
  height: `48px`,
  left: `1012px`,
  top: `22px`,
  overflow: `hidden`,
  cursor: `pointer`,
}));

const DangerBg = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(217, 59, 61, 1)`,
  width: `152px`,
  height: `48px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const ChevronLeft = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px 0px 0px 8px`,
  overflow: `hidden`,
});

const Vector4 = styled('img')({
  height: `12px`,
  width: `7.41px`,
  position: `absolute`,
  left: `8px`,
  top: `6px`,
});

const ActionL = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px`,
}));

const ChevronRight = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px 0px 0px 8px`,
  overflow: `hidden`,
});

const Vector5 = styled('img')({
  height: `12px`,
  width: `7.41px`,
  position: `absolute`,
  left: `9px`,
  top: `6px`,
});

const SecondaryButton1 = styled('img')({
  height: `48px`,
  width: `164px`,
  position: `absolute`,
  left: `832px`,
  top: `23px`,
  cursor: `pointer`,
});

const PleaseReviewYourInsuSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const PleaseReviewYourInsuSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(255, 120, 122, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const PleaseReviewYourInsu = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `780px`,
  position: `absolute`,
  left: `31px`,
  top: `15px`,
});

const Frame12 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `720px`,
  left: `270px`,
  top: `32px`,
});

const Step = styled('img')({
  height: `80px`,
  width: `120px`,
  flex: `1`,
  margin: `0px`,
});

const Step1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame4 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle2 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame3 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Frame15 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `16px`,
  width: `16px`,
  margin: `0px`,
});

const Q = styled('img')({
  height: `11px`,
  width: `14px`,
  position: `absolute`,
  left: `2px`,
  top: `3px`,
});

const Rectangle11 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step2 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame41 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle21 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame31 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Frame151 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `16px`,
  width: `16px`,
  margin: `0px`,
});

const Q1 = styled('img')({
  height: `11px`,
  width: `14px`,
  position: `absolute`,
  left: `2px`,
  top: `3px`,
});

const Rectangle13 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step3 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame42 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle22 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame32 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Frame152 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `16px`,
  width: `16px`,
  margin: `0px`,
});

const Q2 = styled('img')({
  height: `11px`,
  width: `14px`,
  position: `absolute`,
  left: `2px`,
  top: `3px`,
});

const Rectangle15 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText2 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step4 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame43 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle23 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame7 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `6px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Frame33 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  border: `4px solid rgba(255, 255, 255, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q11 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle16 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText3 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step5 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame44 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle24 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame34 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q12 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle17 = styled('div')({
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText4 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

