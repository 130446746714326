/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { _CONFIGS } from '../../Config/index';
import { useNavigate } from 'react-router-dom';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const usePatientPortalECheckInStep1 = () => {
  const data = {};
  const navigate = useNavigate();

  const handleNext = () => {    
    if(isDevMode){
      console.log("usePatientPortalECheckInStep1: handleNext Called"); 
    }
    navigate('/PatientPortalECheckInStep2');

  };
  const handleFinishLater = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep1: handleFinishLater Called"); 
    }
    navigate('/PatientPortalECheckIn');
  };

  const onLogOut = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep1: onLogOut Called"); 
    }

    navigate('/LogIn/PatientExperience');

  };

  const fns = { handleNext, handleFinishLater, onLogOut};
  return { data, fns };
};

export default usePatientPortalECheckInStep1;
