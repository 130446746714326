import axios from "axios";
import { _CONFIGS, _URLS } from '../Config/index.js';
import { setGlobal, getGlobal } from '../globals.js';

//Load up the environment variables that we'll be using
const SHORT_LINK_API = _URLS.SHORT_LINK_API;
const isDevMode = _CONFIGS.isDevMode;
const DECODE_SHORT_LINK_API =  _URLS.DECODE_SHORT_LINK_API;

export const durationSelections = [
  { label: "Never Expire", value: 1 },
  { label: "48 Hours", value: 2 }
  
]

export async function getPatientList() {
    const strEmrId = getGlobal("gEhrId");
    const patientList = await axios.get(_URLS.patientListUrl + strEmrId)
    .catch((e) => {
      console.log("Utilities.getPatientList: Unable to get patient list from " + _URLS.patientListUrl + strEmrId);
      console.log(e);
      return;
    });

    if(patientList) {
      setGlobal({gPatlist: patientList});
    }
    else {
      return null;
    }
}

export async function getShortSessionLink(baseLink) {
    try {
        if (isDevMode) { 
            console.log("Utilities.getShortSessionLink: Trying to get Session link"); 
        }

        //Get the link for the session
        const sessionLink = await axios(baseLink);

        //Show the output if in dev mode
        if (isDevMode) {
            console.log("Utilities.getShortSessionLink: Session Link: " + sessionLink.data.result.full_short_link);
          
            /* May want to use this later
            //See if we can do things with the link creation in case we want to manage expiration ourselves 
            var linkDate = Date(decodedRes.data.result.timestamp);
            console.log("Utilities.fetchPermanentLink: Short Link Date = " + linkDate.toString());
            console.log("Utilities.fetchPermanentLink: link Date value = " + linkDate);
  
            console.log("Utilities.fetchPermanentLink: Add 5 days to link creation date") 
            const expDate = new Date(linkDate);
            expDate.setDate(expDate.getDate() + 5)
            console.log("Utilities.fetchPermanentLink: Short Link creation date + 5 days = " + expDate.toString());
            */
        }

        return sessionLink.data.result.full_short_link; 
    } catch (err) {
        if(isDevMode){
            console.error("Utilities.getShortSessionLink: Error when retrieving Session Link");
        }

        console.error(err);
    }

    return "#";
}

export async function fetchPermanentLink(baseLink) {
  if(isDevMode){
      console.log("Utilities.fetchPermanentLink: trying...")
  }

  try {
      if(isDevMode){
          console.log("Utilities.fetchPermanentLink: baseLink = " + baseLink);
      }

      const res = await axios( SHORT_LINK_API + baseLink);
      var shortLink = res.data.result.full_short_link;      
      
      //Get the short link only
      if(isDevMode){
          console.log("Utilities.fetchPermanentLink: Shortened Active Link = " + shortLink);

          //Decode the short link code in case we need to see what the short link actually was when debugging
          const decodedRes = await axios( DECODE_SHORT_LINK_API + shortLink.substring( shortLink.lastIndexOf( "/" )+1 ));
          console.log("Utilities.fetchPermanentLink: Decoded Short Link = " + decodedRes.data.result.url);
          console.log("Utilities.fetchPermanentLink: Short Link Timestamp = " + decodedRes.data.result.timestamp);
      }

      return shortLink;

  } catch(err) {
      console.error("Utilities.fetchPermanentLink: Error when retrieving short Link");
      console.error(err);
      return "";
  }
}

/*
    *   Create a randomized prefix to add to the Encounter ID so that we can avoid bumping into other visits 
    */
export function getRandomPrefix(min, max) {
    const randomBuffer = new Uint32Array(1);

    window.crypto.getRandomValues(randomBuffer);

    let randomNumber = randomBuffer[0] / (0xffffffff + 1);

    min = Math.ceil(min);
    max = Math.floor(max);

    var randomPrefix = Math.floor(randomNumber * (max - min + 1)) + min;

    return randomPrefix
}

/**
*   Base64 encodes the current JSON token
**/
export function base64Encode(text) {
    return btoa(encodeURIComponent(text).replace(/%([0-9A-F]{2})/g,
        function(match, p1) { return String.fromCharCode(parseInt(p1, 16)); }));
}

export function timeout(delay) {
    return new Promise( res => setTimeout(res, delay) );
}

export async function getUserRole( getTokenSilently) {
    var strUserRole = "";
        
    try {
        const token = await getTokenSilently({
                authorizationParams: {
                audience: _URLS.AUTH0_AUDIENCE,
                scope: 'openid profile email offline_access',
            }
        });

        if (isDevMode)
            console.log("Token: " + token);
        
        const response = await fetch(_URLS.AUTH0_APIS, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        
        var userInfo = await response.json();

        strUserRole = userInfo[_CONFIGS.strUserInfoSub].role;

        if (isDevMode)
            console.log("User Role: " + strUserRole);
    } 
    catch (e) {
        console.error(e);
        return "ERROR";
    }

    return strUserRole;
}