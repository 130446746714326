/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { useNavigate } from 'react-router-dom';
import { _CONFIGS } from '../Config/index';
import { setGlobal } from '../../src/globals';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const useProviderExperience = () => {
  const data = {};
  const navigate = useNavigate();

  const handleEHRScheduledVisit = () => { 
    if(isDevMode){
      console.log("useProviderExperience: handleEHRScheduledVisit Called"); 
    }
    
    setGlobal({gShowAllPatients: _CONFIGS.bShowAllPatients});

    navigate('/ProviderEmrMessageVideoVisit');
   
  };

  const handleEHROnDemandVisit = () => { 
    if(isDevMode){
      console.log("useProviderExperience: handleEHRScheduledVisit Called"); 
    }

    setGlobal({gShowAllPatients: false});

    navigate('/ProviderEmrNextPatient');
  };

  const handleEHRCPCVisit = () => { 
    if(isDevMode){
      console.log("useProviderExperience: handleEHRScheduledVisit Called"); 
    }

    setGlobal({gShowAllPatients: false});

    navigate('/ProviderEmrPatientChart');
  };

  const launchEmbeddedConverge = () => { 
    if(isDevMode){
      console.log("useProviderExperience: launchEmbeddedConverge Called"); 
    }
    navigate('/NotAvailable');
   
  };
  
  const fns = { handleEHRScheduledVisit, handleEHROnDemandVisit, handleEHRCPCVisit, launchEmbeddedConverge };
  return { data, fns };
};

export default useProviderExperience;
