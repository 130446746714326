import { useEffect } from 'react';
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from 'react-router-dom';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications/lib/index';

import Home  from "./pages/Home";
import Login from './pages/Login';
import SharedLayout from './pages/SharedLayout';
import PatientExperience from "./pages/PatientExperience";
import ProviderExperience from "./pages/ProviderExperience";
import NotAvailable from "./pages/NotAvailable";
import IncorrectUserRole from "./pages/IncorrectUserRole";
import PortalExperience from "./pages/PortalExperience";
import PortalTHMenu from "./pages/PortalTHMenu";
import PortalUrgentCare from "./pages/PortalUrgentCare";
import Error from './pages/Error';
import 'bootstrap/dist/css/bootstrap.min.css';
import { _URLS, _CONFIGS } from '../src/Config/index';
import { getGlobal, setGlobal } from '../src/globals';

//EHR Patient Experience Components
import MyChart from "./pages/MyChart/MyChart";
import MyChartECheckInStep1 from "./pages/MyChart/MyChartECheckInStep1";
import MyChartECheckInStep2 from "./pages/MyChart/MyChartECheckInStep2";
import MyChartECheckInStep3 from "./pages/MyChart/MyChartECheckInStep3";
import MyChartECheckInStep4 from "./pages/MyChart/MyChartECheckInStep4";
import MyChartECheckInStep5 from "./pages/MyChart/MyChartECheckInStep5";
import MyChartECheckInStep6 from "./pages/MyChart/MyChartECheckInStep6";

import PatientPortalECheckIn from "./pages/Patient_Portal/PatientPortalECheckIn";
import PatientEmbeddedConverge from "./pages/Patient_Portal/PatientEmbeddedConverge";
import PatientPortalECheckInStep1 from "./pages/Patient_Portal/PatientPortalECheckInStep1";
import PatientPortalECheckInStep2 from "./pages/Patient_Portal/PatientPortalECheckInStep2";
import PatientPortalECheckInStep3 from "./pages/Patient_Portal/PatientPortalECheckInStep3";
import PatientPortalECheckInStep4 from "./pages/Patient_Portal/PatientPortalECheckInStep4";
import PatientPortalECheckInStep5 from "./pages/Patient_Portal/PatientPortalECheckInStep5";
import PatientPortalECheckInStep6 from "./pages/Patient_Portal/PatientPortalECheckInStep6";

//EHR Provider Experience Components
import ProviderEmrMessageVideoVisit from "./pages/Provider_EHR/ProviderEmrMessageVideoVisit";
import ProviderEmrDocumentingTakeNote from "./pages/Provider_EHR/ProviderEmrDocumentingTakeNote";
import ProviderEmrNextPatient from "./pages/Provider_EHR/ProviderEmrNextPatient";
import ProviderEmrCarepointCalling from "./pages/Provider_EHR/ProviderEmrCarepointCalling";
import ProviderEmrPatientChart from "./pages/Provider_EHR/ProviderPatientChart";

const isDevMode = _CONFIGS.isDevMode;

function App() {
  const { error, isLoading } = useAuth0();
  
  //Load the available patient list on startup so that we can use them in downstream routes
  useEffect(()=> {
    //Make sure that we set our Converge Tenant configs from the start
    setGlobal({gTenantId: _CONFIGS.tenant_id});
    setGlobal({gEhrId: _CONFIGS.ehr_id}); //Set a default EHR id for a patient
    setGlobal({gShowAllPatients: _CONFIGS.bShowAllPatients});
    setGlobal({gIssUrl: _URLS.iss_url});
    setGlobal({gOcUrl: _URLS.oc_url});

    //Now let's grab the patient list that we'll want to work with later
    async function getPatientList() {
      const patientList = await axios.get(_URLS.patientListUrl)
      .catch((e) => {
        console.log("App.getPatientList: Unable to get patient list from " + _URLS.patientListUrl);
        console.log(e);
      });

      if (isDevMode) {
        console.log("App.useEffect: Patient List Details:");
        console.log(patientList);
      }

      if(!getGlobal("gPatList"))
        setGlobal({gPatlist: patientList});
    }

      getPatientList();
    
  }, []);
  
  return (
    <div>
      <main>
        {error && <h3>Authentication Error: Please confirm that you're using an authorized user domain and you've verified your email {console.log(error)}</h3>}
        {!error && isLoading && <p className="loading"> Loading...</p>}
        {!error && !isLoading && (
            <>    
              <Routes>
                {/*MyChart Patient Experience Components*/}
                <Route path='MyChart' element={<MyChart/>} />
                <Route path='MyChartECheckInStep1' element={<MyChartECheckInStep1/>} />
                <Route path='MyChartECheckInStep2' element={<MyChartECheckInStep2/>} />
                <Route path='MyChartECheckInStep3' element={<MyChartECheckInStep3/>} />
                <Route path='MyChartECheckInStep4' element={<MyChartECheckInStep4/>} />
                <Route path='MyChartECheckInStep5' element={<MyChartECheckInStep5/>} />
                <Route path='MyChartECheckInStep6' element={<MyChartECheckInStep6/>} />

                {/*Generic EHR Patient Experience Components*/}
                <Route path='PatientPortalECheckIn' element={<PatientPortalECheckIn/>} />
                <Route path='PatientEmbeddedConverge' element={<PatientEmbeddedConverge/>} />
                <Route path='PatientPortalECheckInStep1' element={<PatientPortalECheckInStep1/>} />
                <Route path='PatientPortalECheckInStep2' element={<PatientPortalECheckInStep2/>} />
                <Route path='PatientPortalECheckInStep3' element={<PatientPortalECheckInStep3/>} />
                <Route path='PatientPortalECheckInStep4' element={<PatientPortalECheckInStep4/>} />
                <Route path='PatientPortalECheckInStep5' element={<PatientPortalECheckInStep5/>} />
                <Route path='PatientPortalECheckInStep6' element={<PatientPortalECheckInStep6/>} />

                {/*EHR Provider Experience Components*/}
                <Route path='ProviderEmrMessageVideoVisit' element={<ProviderEmrMessageVideoVisit/>} />
                <Route path='ProviderEmrDocumentingTakeNote' element={<ProviderEmrDocumentingTakeNote/>} />
                <Route path='ProviderEmrNextPatient' element={<ProviderEmrNextPatient/>} />
                <Route path='ProviderEmrCarepointCalling' element={<ProviderEmrCarepointCalling/>} />
                <Route path='ProviderEmrPatientChart' element={<ProviderEmrPatientChart/>} />

                <Route path='PortalExperience' element={<PortalExperience/>} />
                <Route path='PortalTHMenu' element={<PortalTHMenu/>} />
                <Route path='PortalUrgentCare' element={<PortalUrgentCare/>} />
                <Route path='/' exact replace element={<SharedLayout />}>
                    <Route index element={<Home />} />
                    <Route path='Login' element={<Login />}> {/*The LoginBUtton page checks for authentication, so acts as the protected route*/}
                          <Route path='PatientExperience' element={<PatientExperience />} />
                          <Route path='ProviderExperience' element={<ProviderExperience />} />
                    </Route>
                    <Route path='NotAvailable' element={<NotAvailable/>} />
                    <Route path='IncorrectUserRole' element={<IncorrectUserRole/>} />
                    <Route path='*' element={<Error />} />
                  </Route>
              </Routes>
              <NotificationContainer/>
            </>
        )}
      </main>
    </div>
  );
}

export default App;
 
//<Route path='/' replace element={<LoginButton />} />

// our-domain.com/welcome => Welcome Component
// our-domain.com/products => Products Component
// our-domain.com/product-detail/a-book