import { useNavigate } from 'react-router-dom';

function NotAvailable(props) {
    const navigate = useNavigate();

    return (       
        <section className='AmEx__NA-Section'>
            <h3 className='AmEx__NA-h3'>This functionality is not yet available. Please check back again later.</h3>
            <button className='AmEx__NA-Btn' onClick={() => navigate(-1)}>Go Back</button>
        </section>
    );
}

export default NotAvailable;