import "./PortalExp.css";
import { useAuth0 } from '@auth0/auth0-react';

const PortalUrgentCare=() => {
    const { isAuthenticated } = useAuth0();

    if (isAuthenticated){
        return (
            <section className='PortalExp-THUCMenu_Section'>
                
                    <section>
                        <h1 className='PortalExp-UCTitle_Section'>Urgent Care</h1>
                    </section>
                    
                    <hr className="PortalExp-THOption_Seperator"/>
                    
                    <iframe className="PortalExp-UCVisitFrame" id="OnDemand"
                        title="UrgentCareVisit"
                        allow="camera,microphone,geolocation"
                        allowFullScreen
                        src="https://amwell.com/">
                    </iframe>
            </section>
        );
    }
    else {
        return (
            <section className='AmEx__Portal-Section'>
                <h3 className='AmEx__Portal-h3'>Health Portal. Not Logged In</h3>
            </section>
        );
    }

  }

  export default PortalUrgentCare