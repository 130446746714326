/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *   Do not change the name of the hook
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { _CONFIGS } from '../../Config/index';
import { useNavigate } from 'react-router-dom';
import { getGlobal } from '../../globals';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const useMyChart = () => {
  const data = {};
  const navigate = useNavigate();
  
  /** the direct launch URL is configurable! */
  const { convergeUrl } = getGlobal('config') || {};
  
  const OnCheckIn = () => { 
    if(isDevMode){
      console.log("useMyChart: handleECheckIn Called"); 
    }
    navigate('/MyChartECheckInStep1');
  }
  ;
  const onViewDetails = () => {};
  
  const onVisitStart = () => { 
    if(isDevMode){
      console.log("useMyChart: handleODVisit Called");
    }

    window.open(convergeUrl, "_blank");
    
    
  };
  const onLogOut = () => {
    if(isDevMode){
      console.log("useMyChart: onLogOut Called"); 
    }

    navigate('/LogIn/PatientExperience');

  };

  const fns = { OnCheckIn, onViewDetails, onVisitStart, onLogOut };

  return { data, fns };
};

export default useMyChart;
