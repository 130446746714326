/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import Check2Image from 'src/assets/images/Check_Check.png';
import { styled } from '@mui/material/styles';

const Check1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `4.5px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
});

const Check2 = styled('img')({
  height: `4.5px`,
  width: `4.5px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

function Check(props) {
  return (
    <Check1 className={props.className}>
      <Check2 src={Check2Image} loading="lazy" alt={'Check'} />
    </Check1>
  );
}

export default Check;
