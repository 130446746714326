/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { useNavigate } from 'react-router-dom';
import { _CONFIGS } from '../Config/index';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const usePatientExperience = () => {
  const data = {};
  const navigate = useNavigate();

  const handleEHRScheduledVisit = () => { 
    if(isDevMode){
      console.log("usePatientExperience: handleEHRScheduledVisit Called"); 
    }
    navigate('/PatientPortalECheckIn');
   
  };

  const handleMyChart = () => { 
    if(isDevMode){
      console.log("usePatientExperience: handleMyChart Called"); 
    }
    navigate('/MyChart');
   
  };

  const fns = { handleEHRScheduledVisit, handleMyChart };
  return { data, fns };
};



export default usePatientExperience;
