/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/
import { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { base64Encode} from '../../modules/Utilities';
import { _URLS, _CONFIGS } from '../../Config/index';
import { getGlobal } from '../../globals';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

//Load environment varaibles for the visit session
var oc_url = _URLS.oc_url;
var iss_url = _URLS.iss_url;
var tenant_id = _CONFIGS.tenant_id;
var ehr_id = _CONFIGS.ehr_id;

//FHIR Based SMART Launch URL Components
const launchType = _URLS.LAUNCH_TYPE;
const userType = _URLS.PATIENT_USER;
const launchCmd = _URLS.LAUNCH_CMD;

const usePatientPortalECheckInStep6 = () => {
  const data = {};
  const navigate = useNavigate();
  var activeLink = useRef(oc_url + launchType + tenant_id + "/" + ehr_id + userType + iss_url + launchCmd);
  update_json();
  
  useEffect(() => {
    tenant_id = getGlobal("gTenantId");
    ehr_id = getGlobal("gEhrId");
    oc_url = getGlobal("gOcUrl");

  }, [])

  // Set session values
  //We need to create a unique prefix to the encounter to add to the encounter and avoid bumping into other visits 
  var encounterIdAppendix = _URLS.encounterIdAppendix;
  var provider_id = _URLS.provider_id;
  var patient_id = _URLS.patient_id;
  var mrn = _URLS.mrn;

  // Set intial json token values
  var token = {
    "patientMRN":mrn,
    "patientId":patient_id,
    "encounterId":encounterIdAppendix, //This value seems to be the only one being used at the moment. Will need to include the other JSON elements to see if an Auth server or something like that is being used.
    "providerConnectionExternalUserId":provider_id,
    "providerEncounterExternalUserId":"a6dfe8e5-f65e-4eda-a572-850f7ac0d7cf",
    "patientFirstName":"Aundrea",
    "patientLastName":"Grant",
    "patientPrefix":"Mrs.",
    "patientEmail":"AVLMEM-68b11ebtma-h2cbalq86k-b6y5d0lsot@aa.bb",
    "patientDOB":"1965-05-17",
    "patientGender":"F",
    "patientPhone":"555-998-9601",
    "encounterDepartmentState":"MA"
  },
  token_string = "",
  token64 = "";

  //Assign the visit ID (TBD: Make unique per visit)
  token.encounterId = "ConnectEHR_Demo_Visit1" + encounterIdAppendix;
  activeLink = oc_url + launchType + tenant_id + "/" + ehr_id + userType + iss_url + launchCmd;
  update_json();

  const handleOnSubmit = () => {  
    if(isDevMode){
      console.log("usePatientPortalECheckInStep6: handleOnSubmit Called"); 
      console.log("usePatientPortalECheckInStep6.handleOnSubmit determining visit ID");
      console.log("usePatientPortalECheckInStep6.handleOnSubmit Launching visit");
      console.log("usePatientPortalECheckInStep6.handleOnSubmit URL = " + activeLink);
    }

    window.open(activeLink, "_blank");

  };

  const handleFinishLater = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep6: handleFinishLater Called"); 
    }
    navigate('/PatientPortalECheckIn');

  };

  const onLogOut = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep6: onLogOut Called"); 
    }

    navigate('/LogIn/PatientExperience');

  };

  /**
  *   Base64 encodes the current JSON token
  *   Update the displayed JSON and base64 encoded "launch" param
  *   Update all links with the new encoded launch param
  *   From "updateLaunchParam()" in Visit Launcher
  **/
  function update_json() {
    if(isDevMode){
        console.log("usePatientPortalECheckInStep6.update_json: update_json called");
    }

    token_string = JSON.stringify(token); //(token, null, 2);
    token64 = encodeURIComponent(base64Encode(token_string));
    activeLink = activeLink + token64;

    if(isDevMode){
        console.log("usePatientPortalECheckInStep6.update_JSON - Patient Link: " + activeLink)
    }
  }

  const fns = { handleOnSubmit, handleFinishLater, onLogOut };
  return { data, fns };
};

export default usePatientPortalECheckInStep6;
