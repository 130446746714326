import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { setGlobal } from './globals';
import './index.css';
import App from './App';

/** we always need a default tenant, even if there is not one configured */
const DEFAULT_TENANT = 'awsdd';

/** publicly available address of the config service */
const publicConfigUrl = 'https://api.dev.amwell.systems/public-config/v1/config';

/** go ask public config what our JSON is */
const getConfig = (things) => fetch(publicConfigUrl, {
  method: 'POST',
  body: JSON.stringify(things),
  headers: {
    'Content-Type': 'application/json'
  }
}).then(response => response.json()).catch((e) => {
  console.error(e);
  return {};
});

/** plumb the tenant config into the query params */
const getConfigForTenant = tenant => getConfig({
  layers: ['base', tenant],
  root: 'auth',
  /** mixing local storage keys lets you override/test whatever comes back from public-config */
  mix: JSON.parse(localStorage.getItem('aw-local-auth0') || '{}'),
});

/** we want to look at the url parameters to see what tenant someone is trying to use */
const urlParams = new URLSearchParams(window.location.search);
/** we get that tenantKey and fallback to a default */
const tenantKey = urlParams.get('tenantKey') || DEFAULT_TENANT;

const appendTenantParameter = (tenantKey, uri) => {
  const original = new URL(uri);
  original.searchParams.set('tenantKey', tenantKey);
  return original.toString();
}

getConfigForTenant(tenantKey).then((config) => {
  /** we need to set this configuration key for other components rendering iframes and such */
  setGlobal({ config });
  /** the configured redirect URI might not include the tenantKey parameter, so pass it along to make sure we are consistent */
  const redirectUri = appendTenantParameter(tenantKey, config.redirectUri || window.location.href);
  ReactDOM.render(
    <React.StrictMode>
      <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        connection={config.connection}
        redirectUri={redirectUri}
        id="Auth0Prov"
      >
        <HashRouter>
          <App /> 
        </HashRouter>    
      </Auth0Provider>
      </React.StrictMode>,
  
    document.getElementById('root')
  );
});


