/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { FlatList, Text, StyleSheet, View, Image, Pressable } from 'react-native';
import { createRoot } from 'react-dom/client';
// import { NotificationManager } from 'react-notifications/lib/index';
import axios from "axios";
import { styled } from '@mui/material/styles';
import { _CONFIGS, _URLS } from '../../Config/index';
import {setGlobal, getGlobal } from '../../globals';
import useProviderEmrMessageVideoVisit from './useProviderEmrMessageVideoVisit';

//Visuals to use when selecting and unselecting "Video Visits"
import UnselectedVector44Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_44.png';
import VectorImage from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector.png';
import Vector1Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_1.png';
import Vector2Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_2.png';
import Vector3Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_3.png';
import Vector4Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_4.png';
import Vector5Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_5.png';
import ShapeImage from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape.png';
import Vector6Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_6.png';
import Shape1Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_1.png';
import Vector7Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_7.png';
import Vector8Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_8.png';
import Vector9Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_9.png';
import Vector10Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_10.png';
import Vector11Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_11.png';
import Vector12Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_12.png';
import Vector13Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_13.png';
import Vector14Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_14.png';
import Vector15Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_15.png';
import Vector16Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_16.png';
import Vector17Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_17.png';
import Vector18Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_18.png';
import Vector19Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_19.png';
import Vector20Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_20.png';
import Vector21Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_21.png';
import Vector22Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_22.png';
import Vector23Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_23.png';
import Vector24Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_24.png';
import Vector25Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_25.png';
import Vector26Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_26.png';
import Vector27Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_27.png';
import Ellipse1Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Ellipse_1.png';
import VectorImage1 from 'src/assets/images/PatientPortaleCheckInStep1_Vector.svg';
import Shape2Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_2.png';
import Shape3Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_3.png';
import Shape4Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_4.png';
import Shape5Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_5.png';
import Shape6Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_6.png';
import Shape7Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_7.png';
import Shape8Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_8.png';
import Vector28Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_28.png';
import Vector29Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_29.png';
import Vector30Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_30.png';
import Vector31Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_31.png';
import Vector32Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_32.png';
import Vector33Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_33.png';
import Vector34Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_34.png';
import Vector35Image from 'src/assets/images/videocam_ready_outline.png';
// import Vector36Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_36.png';
import Vector38Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_38.png';
// import Vector39Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_39.png';
// import Vector41Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_41.png';
import Vector42Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_42.png';
import Vector43Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_43.png';
import Reply2Image from 'src/assets/images/ProviderEMRMessageVideoVisit_reply.png';
import FlashOn1Image from 'src/assets/images/ProviderEMRMessageVideoVisit_flash_on.png';
import FolderSharedImage from 'src/assets/images/ProviderEMRMessageVideoVisit_folder_shared.png';
import IconStethoscopeImage from 'src/assets/images/ProviderEMRMessageVideoVisit_icon_Stethoscope.png';
import Note1Image from 'src/assets/images/ProviderEMRMessageVideoVisit_note.png';
import Vector45Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_45.png';
import Vector46Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_46.png';
import Vector47Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_47.png';
import Shape9Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Shape_9.png';
import Vector48Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_48.png';
import Vector49Image from 'src/assets/images/ProviderEMRMessageVideoVisit_Vector_49.png';
import UnionImage from 'src/assets/images/ProviderEMRMessageVideoVisit_Union.png';

var patientProfileRoot = null;

function ProviderEmrMessageVideoVisit(props) {
  const { fns } = useProviderEmrMessageVideoVisit();
  const [ strFullName, setPatientName ] = useState("");
  const [selectedId, setSelectedId] = useState();
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [bActiveVVBtn, setActiveVVBtn] = useState(false);

  const flatListRef = useRef(null); //Create a ref to the FlatList component
  const arrVisitReasons = [
    "Routine Care",
    "Migraines",
    "High BP",
    "Rash",
    "Cold/Flu",
    "Medication Mgmt",
    "Allergy Mgmt", 
    "Labs Review",
    "Rx Review",
    "Gastro Issues",
    "Chronic Illness",
    "Diabetes Mgmt"
  ];

  useEffect(() => {
    // const arrPatientList = getGlobal("gActiveUser");
    setGlobal({gSelectedUser: null}); //Make sure that we aren't displaying any patient details if we haven't selected a patient yet

    if(selectedItemIndex !== null && flatListRef.current) {
      flatListRef.current.scrollToIndex({index: selectedItemIndex});
    }

    if(!bActiveVVBtn) 
      setGlobal({gActivateVVBtn: false});

    getPatientList();
  }, [selectedItemIndex])

  async function getPatientList() {
    const strEmrId = getGlobal("gPatientMRN");
    var strEmrApiUrl = "";
    const bShowAll = getGlobal("gShowAllPatients");
    var bBuildPatientList = false;

    if (bShowAll) {
      strEmrApiUrl = _URLS.patientListUrl;
    }
    else {
      strEmrApiUrl = _URLS.patientSearchUrl + strEmrId;
    }

    var arrPatientList = getGlobal("gPatlist");

    if(!arrPatientList){
      bBuildPatientList = true;
    }
    else{
      var nCount = 0;
      arrPatientList.data.forEach( item => {nCount++});

      if(bShowAll && nCount === 1)
        bBuildPatientList = true;

      if(!bShowAll && nCount > 1){
        bBuildPatientList = true;
      }
    }

    if(bBuildPatientList){
      const patientDetails = await axios.get(strEmrApiUrl)
        .catch((e) => {
          console.log("ProviderEmrMessageVideoVisit.getPatientList: Unable to get patient list from " + strEmrApiUrl);
          console.log(e);
          return;
        });

        if(patientDetails) {
          setGlobal({gActiveUser: patientDetails});
          setGlobal({gPatlist: patientDetails});
          setPatientName(patientDetails.given_name + " " + patientDetails.family_name);
        }
        else {
          setPatientName("No Patient Available");
        }
    }
  }

  function createEncounters () {
    var arrEncounters = getGlobal("gEncountersList");
    var bBuildEncounters = false;
 
    if(!arrEncounters) {
      arrEncounters = [];
      bBuildEncounters = true;
    }

    if(arrEncounters){
      if(arrEncounters.length > 1 && !getGlobal("gShowAllPatients"))
        bBuildEncounters = true;

      if(arrEncounters.length === 1 && getGlobal("gShowAllPatients"))
        bBuildEncounters = true;
    }
    
      if(bBuildEncounters){
        //Remove all of the objects from the existing enouncters array
        if(arrEncounters.length > 0){
          arrEncounters.splice(0, arrEncounters.length);
        }
        
        var arrPatientList = getGlobal("gPatlist");

        //If we have a full patient name then we have a patient list to grab
        if(!arrPatientList) {
          getPatientList();
        }

        //We have the patient list & a cleaned up encounters array,so let's go ahead and (re)build our encounters array
        arrPatientList.data.forEach(item => {
          const newEncounter = {
            given_name: item.given_name,
            family_name: item.family_name,
            gender: item.gender,
            birth_date: item.birth_date,
            phone: item.phone,
            address: item.address,
            email: item.email,
            mrn: item.mrn,
            language_name: item.language_name,
            photo: item.photo,
            waitTime: (Math.floor(Math.random() * (10-2 + 1) + 1)),
            strVisitReason: getGlobal("gShowAllPatients") ? arrVisitReasons[Math.floor(Math.random() * (arrVisitReasons.length-1 + 0))] : "Routine Care",
            strLocation: getStateFromAddress(item.address)
        };

        arrEncounters.push(newEncounter);
      });
      
      setGlobal({gEncountersList: arrEncounters});
    }
  }

  function PatientDetail() {
    const patientList = getGlobal("gSelectedUser")

    if(patientList) {
      const today = new Date();
      const strLastAllergy = (today.getMonth() - 1) + "/" + today.getDate() + "/" + (today.getFullYear());

      return (
        <>
          <Rectangle10/>
          <Ellipse21 src={"data:image/png;base64," + patientList.photo} loading="lazy" alt={'Ellipse 2'} />
          <Group44>
            <TianaMaya1>{patientList.given_name + " " + patientList.family_name}</TianaMaya1>
            <Q32YearSFemaleDob020>
              {`Gender: ` + patientList.gender + `\tDOB: ` + patientList.birth_date + `\nMRN: ` + patientList.mrn + `\nPhone: ` + patientList.phone}
            </Q32YearSFemaleDob020>
          </Group44>
          <Conversation>{`Conversation`}</Conversation>
          <Line61></Line61>
          <Chat>
            <Group9>
              <Union src={UnionImage} loading="lazy" alt={'Union'} />
              <HelloDrJamesIJustCom>
                {`Hello Dr. Weiman! I just completed the eCheck-In as your recommended. See you soon!`}
              </HelloDrJamesIJustCom>
            </Group9>
            <Ellipse2 src={"data:image/png;base64," + patientList.photo} loading="lazy" alt={'Ellipse 2'} />
          </Chat>
          <EncounterReport>{`Encounter Report`}</EncounterReport>
          <Line7></Line7>
          <AllergyHistoryAsOfAp>{`Allergy History (as of ` + strLastAllergy + `)`}</AllergyHistoryAsOfAp>      
          <LinkToPatientEncount>{`Link to Patient Encounter Report`}</LinkToPatientEncount>
          <ViewAllConversationO>{`View all Conversation on this Encounter`}</ViewAllConversationO>
          <Line5></Line5>
          <NoAllergiesOnFile>{`No Allergies on file.`}</NoAllergiesOnFile>
          <Q3MinutesAgo>{`3 minutes ago`}</Q3MinutesAgo>
        </>
      )
    }
  }

  function UpdatePatientInfo() {

    if(!patientProfileRoot)
      patientProfileRoot = createRoot(document.getElementById("PatientDetail"));

    //Load the appropriate profile details
    const newPatientProfile = (
      <div>
        <PatientDetail />
      </div>
    );
    
    //Render
    patientProfileRoot.render(newPatientProfile);

    return (
      <PatientDetail />
    );
  }
  
  function OnDemandBtn() {
    return (
      <GroupOD onClick={fns.goNextPatient}>
        <PNPMenu>{`OnDemand`}</PNPMenu>
        <PNPHoriz>
          <Vector35 src={Vector35Image} loading="lazy" alt={'Vector'} />
        </PNPHoriz>
      </GroupOD>
    )
  }

  function VideoVisitBtn() {
    return (
      <Group32
        onClick={fns.handleVideoVisit}
        onMouseOver={fns.handleVVMouseOver}
        onMouseLeave={fns.handleVVMouseLeave}
        id="Group32"
      >
        <VirtualVisit3>Virtual Visit</VirtualVisit3>
        <VideocamOutline className="VideoVisitImage">
          <Vector44 src={UnselectedVector44Image} loading="lazy" alt={'Vector'} />
        </VideocamOutline>
      </Group32>
    )
  }

  function NotifyPatientBtn() {
    return (
      <Group33
        // Disabling for now as Dylan believes it best not to show this
        // onClick={handleNotifyPatient}
        // onMouseOver={fns.handleNPMouseOver}
        // onMouseLeave={fns.handleNPMouseLeave}
        id="Group33"
      >
        <NotifyPatient>{`Notify Patient`}</NotifyPatient>
        <NotificationsActive>
          <Vector45 src={Vector45Image} loading="lazy" alt={'Vector'} />
        </NotificationsActive>
      </Group33>
    )
  }

  // const handleNotifyPatient = () => {

  //   if(bActiveVVBtn)
  //     NotificationManager.success( 'A message been sent to ' + strFullName + ' to join the visit room.', 'Notification Sent!');

  // }

  function getStateFromAddress(address) {
      // Split the address by commas
      const addressParts = address.split(',');
  
      // The state abbreviation is the last part of the address
      const stateAbbreviation = addressParts[2].trim().split(' ')[0];
  
      return stateAbbreviation;
  }

  function onPatientSelect( item, index ) {
    setSelectedId(item.id);
    setSelectedItemIndex(index);
    setGlobal({gPatDetails: item});
    setGlobal({gSelectedUser: item})
    if(!bActiveVVBtn) {
      setGlobal({gActivateVVBtn: true})
      setActiveVVBtn(true);
    }

    UpdatePatientInfo();
  }

  const onePatient = ( {item, index} ) => {
    const backgroundColor = item.mrn === selectedId ? 'gray' : 'white';
    const textColor = item.mrn === selectedId ? 'white' : 'black';
    const strPatMrn = getGlobal("gPatientMRN");

    var strCheckinImage = null;
    var strCameraImage = Vector35Image;

    if (item.mrn === strPatMrn) {
      strCheckinImage = Vector34Image
      strCameraImage = Vector35Image
    }
    else {
      strCameraImage = Vector38Image
    }
    
    return (
        <Pressable onPress={() => onPatientSelect(item, index)} style={[styles.item, {backgroundColor}]}>
          <View style={ styles.imageContainer }>
            <Image source={Vector33Image} style={styles.image} />
            <Text style={[styles.waitTime, {color: textColor}]}>{item.waitTime + " min"}</Text>
          </View>
          <Text style={[styles.name, {color: textColor}]} >{ item.given_name + " " + item.family_name}</Text>
          <Text style={[styles.subText, {color: textColor}]} >{item.strVisitReason + "\t" + "Location: " + getStateFromAddress(item.address) }</Text>
          <View style={ styles.rightCol }>
            <Text style={[styles.eCheckIn, {color: textColor}]}>eCheck-In</Text>
            {/* 
              * TO DO: Show the patient as checked in and ready for video if they're the active user. 
              * Later, once we have a back end service for encounter management, we'll ask the 
              * server if we should display them as checked in or not.
             */}
            <Image source={strCheckinImage} style={styles.checkedIn} />
            <Text style={[styles.virtualVisit, {color: textColor}]}>Virtual Visit</Text>
            <Image source={strCameraImage} style={styles.videoIcon} />
          </View>
        </Pressable>
    );
  }

  const getItemLayout = (data, index) => ({
    length: 80, // Height of each item
    offset: 80 * index,
    index,
  });

  const onScrollToIndexFailed = () => {
    // Handle a failed scrollToIndex, if needed
    console.warn('Scroll to index failed');
  };

  function PatientSelectionGroup() {
    createEncounters(); 
    const arrEncounters = getGlobal("gEncountersList");

      return (
        <>
          <Rectangle8></Rectangle8>
          <div id="PatientDetail">
          <PatientDetail />
          </div>
          <Selection style={styles.container} id="PatientGroup">
            < FlatList style={styles.listContainer}
              ref={flatListRef}
              // data={patList.data}
              data={arrEncounters}
              renderItem={onePatient}
              ketExtractor={(item) => item.mrn} //Use the MRN as the key property
              extraData={selectedId}
              ItemSeparatorComponent={() => (<View style={styles.seperator} /> )}
              ListEmptyComponent={ <Text style={styles.name}>No Patients Available</Text>}
              getItemLayout={getItemLayout}
              onScrollToIndexFailed={onScrollToIndexFailed}
            />
          </Selection>
        </>
      );
  }

  return (
    <ProviderEmrMessageVi className={props.className}>
      <SubNav>
       <Rectangle4></Rectangle4>
        <Group16>
          <Home>{`Home`}</Home>
          <Home1>
            <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
          </Home1>
        </Group16>
        <Group17>
          <Refresh>{`Refresh`}</Refresh>
          <Refresh1>
            <Vector1 src={Vector1Image} loading="lazy" alt={'Vector'} />
          </Refresh1>
        </Group17>
        <Group20>
          <Search>{`Search`}</Search>
          <Search1>
            <Vector2 src={Vector2Image} loading="lazy" alt={'Vector'} />
          </Search1>
        </Group20>
        <Group21>
          <Attach>{`Attach`}</Attach>
          <Group>
            <Vector3 src={Vector3Image} loading="lazy" alt={'Vector'} />
          </Group>
        </Group21>
        <Group22>
          <OutOfContact>{`Out of Contact`}</OutOfContact>
          <IconsEmptyCalendar>
            <Vector4 src={Vector4Image} loading="lazy" alt={'Vector'} />
          </IconsEmptyCalendar>
        </Group22>
        <Group23>
          <More>{`More`}</More>
          <MoreHoriz>
            <Vector5 src={Vector5Image} loading="lazy" alt={'Vector'} />
          </MoreHoriz>
        </Group23>
        <OnDemandBtn />
        <Group18>
          <NewMessage>{`New Message`}</NewMessage>
          <Shape src={ShapeImage} loading="lazy" alt={'Shape'} />
          <CreateNewFolder>
            <Vector6 src={Vector6Image} loading="lazy" alt={'Vector'} />
          </CreateNewFolder>
        </Group18>
        <Group19>
          <ManagePools>{`Manage Pools`}</ManagePools>
          <Shape1 src={Shape1Image} loading="lazy" alt={'Shape'} />
          <GroupWork>
            <Vector7 src={Vector7Image} loading="lazy" alt={'Vector'} />
          </GroupWork>
        </Group19>
      </SubNav>
      <Navigation>
        <Rectangle1></Rectangle1>
        <Menu>
          <Vector8 src={Vector8Image} loading="lazy" alt={'Vector'} />
        </Menu>
        <GenericEmr>
          <GenericEmrSpan1>{`Generic`}</GenericEmrSpan1>
          <GenericEmrSpan2>{`EMR`}</GenericEmrSpan2>
        </GenericEmr>
        <LogosAmwellLogoCompa>
          <Frame>
            <Group1>
              <Vector9 src={Vector9Image} loading="lazy" alt={'Vector'} />
              <Vector10 src={Vector10Image} loading="lazy" alt={'Vector'} />
              <Vector11 src={Vector11Image} loading="lazy" alt={'Vector'} />
              <Vector12 src={Vector12Image} loading="lazy" alt={'Vector'} />
              <Vector13 src={Vector13Image} loading="lazy" alt={'Vector'} />
              <Vector14 src={Vector14Image} loading="lazy" alt={'Vector'} />
              <Vector15 src={Vector15Image} loading="lazy" alt={'Vector'} />
              <Vector16 src={Vector16Image} loading="lazy" alt={'Vector'} />
              <Vector17 src={Vector17Image} loading="lazy" alt={'Vector'} />
              <Vector18 src={Vector18Image} loading="lazy" alt={'Vector'} />
            </Group1>
            <Vector19 src={Vector19Image} loading="lazy" alt={'Vector'} />
            <Vector20 src={Vector20Image} loading="lazy" alt={'Vector'} />
            <Vector21 src={Vector21Image} loading="lazy" alt={'Vector'} />
            <Vector22 src={Vector22Image} loading="lazy" alt={'Vector'} />
            <Vector23 src={Vector23Image} loading="lazy" alt={'Vector'} />
            <Vector24 src={Vector24Image} loading="lazy" alt={'Vector'} />
            <Vector25 src={Vector25Image} loading="lazy" alt={'Vector'} />
            <Vector26 src={Vector26Image} loading="lazy" alt={'Vector'} />
            <Vector27 src={Vector27Image} loading="lazy" alt={'Vector'} />
          </Frame>
        </LogosAmwellLogoCompa>
        <Ellipse1 src={Ellipse1Image} loading="lazy" alt={'Ellipse 1'} />
      </Navigation>
      <Group4 onClick={fns.onLogOut}>
        <Logout>{`Logout`}</Logout>
        <Vector src={VectorImage1} loading="lazy" alt={'Vector'} />
      </Group4>
      <Group15>
        <Base></Base>
        <Inbox>{`Messages`}</Inbox>
        <Inbox1>{`Attached & Covering`}</Inbox1>
        <Inbox2>{`Follow-up`}</Inbox2>
        <Inbox3>{`Search`}</Inbox3>
        <Inbox4>{`Sent `}</Inbox4>
        <Inbox5>{`Completed`}</Inbox5>
        <Shape2 src={Shape2Image} loading="lazy" alt={'Shape'} />
        <Shape3 src={Shape3Image} loading="lazy" alt={'Shape'} />
        <Shape4 src={Shape4Image} loading="lazy" alt={'Shape'} />
        <Shape5 src={Shape5Image} loading="lazy" alt={'Shape'} />
        <Shape6 src={Shape6Image} loading="lazy" alt={'Shape'} />
        <Shape7 src={Shape7Image} loading="lazy" alt={'Shape'} />
        <Inbox6>
          <Inbox6Span1>{`Best Practice\n`}</Inbox6Span1>
          <Inbox6Span2>{`Incomplete...\nCare Comp...Med Adv\nPortal Admi...\nOpen Chart\nOpen Encount...\nPatient Escala...\nPatient Questi...\nPt Non-Clinic...\nPt Schedule R...\nVirtual Visit`}</Inbox6Span2>
        </Inbox6>
        <BaselineInbox24Px>
          <Shape8 src={Shape8Image} loading="lazy" alt={'Shape'} />
        </BaselineInbox24Px>
        <Q14>
          {`14\n8\n16\n2\n7\n10\n4\n9\n34\n1\n2`}
        </Q14>
        <Send>
          <Vector28 src={Vector28Image} loading="lazy" alt={'Vector'} />
        </Send>
        <Check>
          <Vector29 src={Vector29Image} loading="lazy" alt={'Vector'} />
        </Check>
        <Search2>
          <Vector30 src={Vector30Image} loading="lazy" alt={'Vector'} />
        </Search2>
        <FlashOn>
          <Vector31 src={Vector31Image} loading="lazy" alt={'Vector'} />
        </FlashOn>
        <SupervisorAccount>
          <Vector32 src={Vector32Image} loading="lazy" alt={'Vector'} />
        </SupervisorAccount>
      </Group15>
      <Rectangle7>
        <PatientSelectionGroup/>
      </Rectangle7>
      <Group37>
        <Rectangle13></Rectangle13>
        <Rectangle14></Rectangle14>
        <Group243>
          <Done>{`Done`}</Done>
          <Check4>
            <Vector42 src={Vector42Image} loading="lazy" alt={'Vector'} />
          </Check4>
        </Group243>
        <Group25>
          <Reply>{`Reply`}</Reply>
          <Reply1>
            <Vector43 src={Vector43Image} loading="lazy" alt={'Vector'} />
          </Reply1>
        </Group25>
        <Group27>
          <Forward>{`Forward`}</Forward>
          <Reply2 src={Reply2Image} loading="lazy" alt={'reply'} />
        </Group27>
        <Group28>
          <FollowUp>{`Follow Up`}</FollowUp>
          <FlashOn1 src={FlashOn1Image} loading="lazy" alt={'flash_on'} />
        </Group28>
        <Group29>
          <Chart>{`Chart`}</Chart>
          <FolderShared
            src={FolderSharedImage}
            loading="lazy"
            alt={'folder_shared'}
          />
        </Group29>
        <Group30>
          <Encounter>{`Encounter`}</Encounter>
          <IconStethoscope
            src={IconStethoscopeImage}
            loading="lazy"
            alt={'icon_Stethoscope'}
          />
        </Group30>
        <Group31>
          <Note>{`Note`}</Note>
          <Note1 src={Note1Image} loading="lazy" alt={'note'} />
        </Group31>
        <VideoVisitBtn />
        <NotifyPatientBtn/>
        <Group34>
          <NoShow>{`No Show`}</NoShow>
          <DoDisturbAlt>
            <Vector46 src={Vector46Image} loading="lazy" alt={'Vector'} />
          </DoDisturbAlt>
        </Group34>
        <Group36>
          <Take>{`Take`}</Take>
          <Rotate90DegreesCcw>
            <Vector47 src={Vector47Image} loading="lazy" alt={'Vector'} />
          </Rotate90DegreesCcw>
          <Shape9 src={Shape9Image} loading="lazy" alt={'Shape'} />
        </Group36>
        <Group35>
          <More1>{`More`}</More1>
          <MoreHoriz1>
            <Vector48 src={Vector48Image} loading="lazy" alt={'Vector'} />
          </MoreHoriz1>
        </Group35>
        <Group26>
          <ReplyAll>{`Reply All`}</ReplyAll>
          <ReplyAll1>
            <Vector49 src={Vector49Image} loading="lazy" alt={'Vector'} />
          </ReplyAll1>
        </Group26>
      </Group37>
    </ProviderEmrMessageVi>
  );
}

export default ProviderEmrMessageVideoVisit;

const styles = StyleSheet.create({
  listContainer: {
    height: 750,
  },
  container: {
    display: `flex`,
    flexDirection: `row`,
    padding: `0px`,
  },
  item: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 20,
  },
  subitem: {
    backgroundColor: 'lightgrey',
    fontSize: 15,
  },
  seperator: {
    backgroundColor: 'black',
    height: 1,
    width: '100%',
  },
  imageContainer: {
    borderRadius: 100,
    height: 25,
    width: 60,
    alignItems: 'center',
  },
  image: {
    height: `15.83px`,
    width: `18.33px`,
  },
  name: {
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(35, 42, 48, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `600`,
    fontSize: `16px`,
    letterSpacing: `0px`,
    
    lineHeight: `24px`,
    textTransform: `none`,
    position: `absolute`,
    left: `60px`,
    top: `0px`,
    marginTop: 15,
  },
  subText: {
    whiteSpace: `pre-wrap`,
    color: `rgba(35, 42, 48, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `400`,
    fontSize: `14px`,
    letterSpacing: `0px`,
    
    lineHeight: `24px`,
    textTransform: `none`,
    marginTop: 20,
  },
  rightCol: {
    display: `flex`,
    position: `absolute`,
    isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `150px`,
    height: `24px`,
    left: `350px`,
    top: `15px`,
  },
  eCheckIn: {
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(35, 42, 48, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `400`,
    fontSize: `14px`,
    letterSpacing: `0px`,
    
    lineHeight: `24px`,
    textTransform: `none`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  },
  virtualVisit: {
    textAlign: `right`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(35, 42, 48, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `400`,
    fontSize: `14px`,
    letterSpacing: `0px`,
    
    lineHeight: `24px`,
    textTransform: `none`,
    position: `absolute`,
    left: `0px`,
    top: `25px`,
  },
  waitTime: {
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(91, 107, 123, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `600`,
    fontSize: `12px`,
    
    lineHeight: `24px`,
    textTransform: `none`,
    position: `absolute`,
    left: `5px`,
    top: `15px`,
    width: '50px',
    textAlign: 'center',
  },
  checkedIn: {
    height: `11.17px`,
    width: `14.66px`,
    position: `absolute`,
    left: `85px`,
    top: `5px`,
  },
  videoIcon: {
    height: `15.95px`,
    width: `15.95px`,
    position: `absolute`,
    left: `85px`,
    top: `30px`,
  }
})

const ProviderEmrMessageVi = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1200px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const SubNav = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1439px`,
  height: `62px`,
  left: `1px`,
  top: `70px`,
});

const Rectangle4 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(240, 241, 242, 1)`,
  boxShadow: `0px 8px 36px rgba(0, 0, 0, 0.16)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1439px`,
  height: `62px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group16 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `75px`,
  height: `24px`,
  left: `27px`,
  top: `19px`,
});

const Home = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Home1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector = styled('img')({
  height: `14.17px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `3px`,
});

const Group17 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `88px`,
  height: `24px`,
  left: `134px`,
  top: `19px`,
});

const Refresh = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Refresh1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Group20 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `81px`,
  height: `24px`,
  left: `624px`,
  top: `19px`,
});

const Search = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Search1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector2 = styled('img')({
  height: `14.58px`,
  width: `14.58px`,
  position: `absolute`,
  left: `3px`,
  top: `3px`,
});

const Group21 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `79px`,
  height: `24px`,
  left: `737px`,
  top: `19px`,
});

const Attach = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Group = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector3 = styled('img')({
  height: `11.67px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `4px`,
});

const Group22 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `141px`,
  height: `24px`,
  left: `848px`,
  top: `19px`,
});

const OutOfContact = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const IconsEmptyCalendar = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector4 = styled('img')({
  height: `16.67px`,
  width: `15px`,
  position: `absolute`,
  left: `3px`,
  top: `1px`,
});

const Group23 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `69px`,
  height: `24px`,
  left: `1021px`,
  top: `19px`,
});

const GroupOD = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `69px`,
  height: `24px`,
  left: `1121px`,
  top: `19px`,
  cursor: 'pointer',
});

const More = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const MoreHoriz = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const PNPMenu = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const PNPHoriz = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector5 = styled('img')({
  height: `3.33px`,
  width: `13.33px`,
  position: `absolute`,
  left: `3px`,
  top: `8px`,
});

const Group18 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `151.99px`,
  height: `24px`,
  left: `254px`,
  top: `19px`,
});

const NewMessage = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Shape = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `142px`,
  top: `9px`,
});

const CreateNewFolder = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector6 = styled('img')({
  height: `13.33px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `3px`,
});

const Group19 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `153.99px`,
  height: `24px`,
  left: `438px`,
  top: `19px`,
});

const ManagePools = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `28px`,
  top: `0px`,
}));

const Shape1 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `144px`,
  top: `9px`,
});

const GroupWork = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `0px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector7 = styled('img')({
  height: `16.67px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Menu = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `28px`,
  top: `23px`,
  overflow: `hidden`,
});

const Vector8 = styled('img')({
  height: `12px`,
  width: `18px`,
  position: `absolute`,
  left: `3px`,
  top: `6px`,
});

const GenericEmrSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `300`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  
  lineHeight: `36px`,
  textTransform: `none`,
});

const GenericEmrSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `800`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  
  lineHeight: `36px`,
  textTransform: `none`,
});

const GenericEmr = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  
  lineHeight: `36px`,
  textTransform: `none`,
  position: `absolute`,
  left: `258px`,
  top: `17px`,
});

const LogosAmwellLogoCompa = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 0)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `160px`,
  height: `32px`,
  left: `76px`,
  top: `19px`,
  overflow: `hidden`,
});

const Frame = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `159.32px`,
  height: `26.5px`,
  left: `0px`,
  top: `3px`,
  overflow: `hidden`,
});

const Group1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `126.96px`,
  height: `16.39px`,
  left: `32px`,
  top: `5px`,
});

const Vector9 = styled('img')({
  height: `15.38px`,
  width: `13.33px`,
  position: `absolute`,
  left: `0px`,
  top: `1px`,
});

const Vector10 = styled('img')({
  height: `12.58px`,
  width: `12.27px`,
  position: `absolute`,
  left: `15px`,
  top: `4px`,
});

const Vector11 = styled('img')({
  height: `12.58px`,
  width: `12.91px`,
  position: `absolute`,
  left: `29px`,
  top: `4px`,
});

const Vector12 = styled('img')({
  height: `16.21px`,
  width: `3.75px`,
  position: `absolute`,
  left: `44px`,
  top: `0px`,
});

const Vector13 = styled('img')({
  height: `15.2px`,
  width: `7.72px`,
  position: `absolute`,
  left: `50px`,
  top: `1px`,
});

const Vector14 = styled('img')({
  height: `16.21px`,
  width: `12.17px`,
  position: `absolute`,
  left: `60px`,
  top: `0px`,
});

const Vector15 = styled('img')({
  height: `15.65px`,
  width: `15.04px`,
  position: `absolute`,
  left: `74px`,
  top: `1px`,
});

const Vector16 = styled('img')({
  height: `12.58px`,
  width: `12.91px`,
  position: `absolute`,
  left: `90px`,
  top: `4px`,
});

const Vector17 = styled('img')({
  height: `12.34px`,
  width: `7.58px`,
  position: `absolute`,
  left: `106px`,
  top: `4px`,
});

const Vector18 = styled('img')({
  height: `12.58px`,
  width: `12.27px`,
  position: `absolute`,
  left: `115px`,
  top: `4px`,
});

const Vector19 = styled('img')({
  height: `11.24px`,
  width: `15.57px`,
  position: `absolute`,
  left: `11px`,
  top: `8px`,
});

const Vector20 = styled('img')({
  height: `11.24px`,
  width: `15.56px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const Vector21 = styled('img')({
  height: `15.11px`,
  width: `11.24px`,
  position: `absolute`,
  left: `8px`,
  top: `11px`,
});

const Vector22 = styled('img')({
  height: `1.72px`,
  width: `2.85px`,
  position: `absolute`,
  left: `16px`,
  top: `11px`,
});

const Vector23 = styled('img')({
  height: `16.01px`,
  width: `11.24px`,
  position: `absolute`,
  left: `8px`,
  top: `0px`,
});

const Vector24 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `16px`,
  top: `6px`,
});

const Vector25 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `22px`,
  top: `19px`,
});

const Vector26 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `8px`,
  top: `14px`,
});

const Vector27 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `8px`,
  top: `20px`,
});

const Logout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `25px`,
  top: `0px`,
  cursor: `pointer`,
}));

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `1369px`,
  top: `12px`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1275px`,
  top: `23px`,
  cursor: `pointer`,
});

const Vector1 = styled('img')({
  height: `13.33px`,
  width: `13.32px`,
  position: `absolute`,
  left: `3px`,
  top: `3px`,
});

const Group15 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `213px`,
  height: `497px`,
  left: `0px`,
  top: `164px`,
});

const Base = styled('div')({
  backgroundColor: `rgba(211, 227, 253, 1)`,
  borderRadius: `0px 17px 17px 0px`,
  width: `213px`,
  height: `28px`,
  position: `absolute`,
  left: `0px`,
  top: `308px`,
});

const Inbox = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(51, 51, 51, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `13px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
  position: `absolute`,
  left: `56px`,
  top: `0px`,
}));

const Inbox1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(51, 51, 51, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `13px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
  position: `absolute`,
  left: `56px`,
  top: `358px`,
}));

const Inbox2 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(51, 51, 51, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `13px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
  position: `absolute`,
  left: `56px`,
  top: `388px`,
}));

const Inbox3 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(51, 51, 51, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `13px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
  position: `absolute`,
  left: `56px`,
  top: `418px`,
}));

const Inbox4 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(51, 51, 51, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `13px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
  position: `absolute`,
  left: `56px`,
  top: `448px`,
}));

const Inbox5 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(51, 51, 51, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `13px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
  position: `absolute`,
  left: `56px`,
  top: `478px`,
}));

const Shape2 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `8px`,
  top: `7px`,
});

const Shape3 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `18px`,
  top: `371px`,
});

const Shape4 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `18px`,
  top: `401px`,
});

const Shape5 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `18px`,
  top: `431px`,
});

const Shape6 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `18px`,
  top: `461px`,
});

const Shape7 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `18px`,
  top: `491px`,
});

const Inbox6Span1 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
}));

const Inbox6Span2 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `10px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
}));

const Inbox6 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
  position: `absolute`,
  left: `66px`,
  top: `27px`,
}));

const BaselineInbox24Px = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `13px`,
  height: `13px`,
  left: `33px`,
  top: `4px`,
});

const Shape8 = styled('img')({
  height: `13px`,
  width: `13px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Q14 = styled('div')({
  textAlign: `right`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(71, 126, 240, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `12px`,
  letterSpacing: `-0.03733333945274353px`,
  
  textTransform: `none`,
  position: `absolute`,
  left: `184px`,
  top: `53px`,
});

const Send = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `18px`,
  height: `18px`,
  left: `30px`,
  top: `449px`,
  overflow: `hidden`,
});

const Vector28 = styled('img')({
  height: `13.5px`,
  width: `15.75px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const Check = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `18px`,
  height: `18px`,
  left: `30px`,
  top: `479px`,
  overflow: `hidden`,
});

const Vector29 = styled('img')({
  height: `10.06px`,
  width: `13.19px`,
  position: `absolute`,
  left: `3px`,
  top: `4px`,
});

const Search2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `18px`,
  height: `18px`,
  left: `30px`,
  top: `419px`,
  overflow: `hidden`,
});

const Vector30 = styled('img')({
  height: `13.12px`,
  width: `13.12px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const FlashOn = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `18px`,
  height: `18px`,
  left: `30px`,
  top: `389px`,
  overflow: `hidden`,
});

const Vector31 = styled('img')({
  height: `15px`,
  width: `7.5px`,
  position: `absolute`,
  left: `5px`,
  top: `2px`,
});

const SupervisorAccount = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `18px`,
  height: `18px`,
  left: `30px`,
  top: `359px`,
  overflow: `hidden`,
});

const Vector32 = styled('img')({
  height: `10.5px`,
  width: `15px`,
  position: `absolute`,
  left: `2px`,
  top: `4px`,
});

const Rectangle7 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `1169px`,
  height: `894px`,
  position: `absolute`,
  left: `246px`,
  top: `148px`,
}));

const Line3 = styled('div')({
  border: `1px solid rgba(190, 195, 204, 1)`,
  width: `469px`,
  height: `0px`,
  position: `absolute`,
  left: `262px`,
  top: `376px`,
});

const Rectangle8 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `655px`,
  height: `750px`,
  position: `absolute`,
  left: `500px`,
  top: `115px`,
}));

const Group9 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `491px`,
  height: `56px`,
  left: `63px`,
  top: `8px`,
});

const Union = styled('img')({
  height: `56px`,
  width: `491px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const HelloDrJamesIJustCom = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `20px`,
  textTransform: `none`,
  width: `451px`,
  position: `absolute`,
  left: `30px`,
  top: `6px`,
}));

const Ellipse2 = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Rectangle10 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 0px 2px rgba(4, 14, 34, 0.01), 0px 0.5px 4px rgba(4, 14, 34, 0.03), 0px 1px 6px rgba(4, 14, 34, 0.04), 0px 1.5px 8px rgba(4, 14, 34, 0.06), 0px 2px 10px rgba(4, 14, 34, 0.08)`,
  borderRadius: `8px`,
  width: `625px`,
  height: `131px`,
  position: `absolute`,
  left: `510px`,
  top: `135px`,
}));

const Ellipse21 = styled('img')({
  height: `84px`,
  width: `84px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `560px`,
  top: `150px`,
});

const Group44 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `295px`,
  height: `78px`,
  left: `670px`,
  top: `155px`,
});

const Conversation = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `520px`,
  top: `280px`,
}));

const Line61 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `596px`,
  height: `0px`,
  position: `absolute`,
  left: `515px`,
  top: `310px`,
});

const Chat = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `654px`,
  height: `64px`,
  left: `520px`,
  top: `325px`,
});

const EncounterReport = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `520px`,
  top: `420px`,
  width: '155px',
}));

const Line7 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `596px`,
  height: `0px`,
  position: `absolute`,
  left: `515px`,
  top: `450px`,
});

const LinkToPatientEncount = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `520px`,
  top: `460px`,
  width: '250px',
});

const ViewAllConversationO = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `520px`,
  top: `485px`,
  width: '300px'
});

const AllergyHistoryAsOfAp = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `520px`,
  top: `535px`,
  width: '300px',
}));

const Line5 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `596px`,
  height: `0px`,
  position: `absolute`,
  left: `515px`,
  top: `565px`,
});

const NoAllergiesOnFile = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `520px`,
  top: `575px`,
  width: '150px',
}));

const Q3MinutesAgo = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  width: `100px`,
  left: `535px`,
  top: `600px`,
}));

const Rectangle9 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  width: `525px`,
  height: `104px`,
  position: `absolute`,
  left: `246px`,
  top: `272px`,
}));

const Line4 = styled('div')({
  border: `1px solid rgba(190, 195, 204, 1)`,
  width: `469px`,
  height: `0px`,
  position: `absolute`,
  left: `262px`,
  top: `480px`,
});

const TianaMaya = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `60px`,
  top: `0px`,
}));

const Group40 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `469px`,
  height: `72px`,
  left: `0px`,
  top: `0px`,
  cursor: `pointer`,
});

const Group24 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `39px`,
  height: `44px`,
  left: `0px`,
  top: `12px`,
});

const Q4Mins = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `20px`,
  width: '50px',
}));

const Warning = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `10px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector33 = styled('img')({
  height: `15.83px`,
  width: `18.33px`,
  position: `absolute`,
  left: `1px`,
  top: `2px`,
});

const SkinDisordersLocatioSpan1 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const SkinDisordersLocatioSpan2 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const SkinDisordersLocatioSpan3 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const SkinDisordersLocatio = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `59px`,
  top: `24px`,
}));

const Group38 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `93px`,
  height: `24px`,
  left: `376px`,
  top: `0px`,
});

const ECheckIn = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Check1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `73px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector34 = styled('img')({
  height: `11.17px`,
  width: `14.66px`,
  position: `absolute`,
  left: `3px`,
  top: `5px`,
});

const Group39 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `99px`,
  height: `24px`,
  left: `370px`,
  top: `30px`,
});

const VirtualVisit = styled('div')(({ theme }) => ({
  textAlign: `right`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const VideocamOff = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `79px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector35 = styled('img')({
  height: `15.95px`,
  width: `15.95px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const Selection = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `469px`,
  height: `72px`,
  left: `10px`,
  top: `110px`,
});

const Group42 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `469px`,
  height: `72px`,
  left: `0px`,
  top: `110px`,
});

const JohnMyung = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `60px`,
  top: `0px`,
}));

const Group401 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `469px`,
  height: `72px`,
  left: `0px`,
  top: `0px`,
});

const Group241 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `37px`,
  height: `44px`,
  left: `0px`,
  top: `12px`,
});

const Q2Days = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `20px`,
  width: '50px',
}));

const CalendarToday = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `9px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector36 = styled('img')({
  height: `18.33px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `1px`,
});

const HighBloodPressureLocSpan1 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const HighBloodPressureLocSpan2 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const HighBloodPressureLocSpan3 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const HighBloodPressureLoc = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `59px`,
  top: `24px`,
}));

const Group381 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `93px`,
  height: `24px`,
  left: `376px`,
  top: `0px`,
});

const ECheckIn1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group391 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `99px`,
  height: `24px`,
  left: `370px`,
  top: `30px`,
});

const VirtualVisit1 = styled('div')(({ theme }) => ({
  textAlign: `right`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const VideocamOff1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `79px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector38 = styled('img')({
  height: `15.95px`,
  width: `15.95px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const Group43 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `469px`,
  height: `72px`,
  left: `0px`,
  top: `210px`,
});

const NickMenza = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `60px`,
  top: `0px`,
}));

const Group402 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `469px`,
  height: `72px`,
  left: `0px`,
  top: `0px`,
});

const Group242 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `37px`,
  height: `44px`,
  left: `0px`,
  top: `12px`,
});

const Q2Days1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `20px`,
  width: '50px',
}));

const CalendarToday1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `9px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector39 = styled('img')({
  height: `18.33px`,
  width: `16.67px`,
  position: `absolute`,
  left: `2px`,
  top: `1px`,
});

const HeadachesAndMigraineSpan1 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const HeadachesAndMigraineSpan2 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const HeadachesAndMigraineSpan3 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
}));

const HeadachesAndMigraine = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `59px`,
  top: `24px`,
}));

const Group382 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `93px`,
  height: `24px`,
  left: `376px`,
  top: `0px`,
});

const ECheckIn2 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group392 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `99px`,
  height: `24px`,
  left: `370px`,
  top: `30px`,
});

const VirtualVisit2 = styled('div')(({ theme }) => ({
  textAlign: `right`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const VideocamOff2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `20px`,
  height: `20px`,
  left: `79px`,
  top: `2px`,
  overflow: `hidden`,
});

const Vector41 = styled('img')({
  height: `15.95px`,
  width: `15.95px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const Group37 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1137px`,
  height: `92px`,
  left: `262px`,
  top: `164px`,
});

const Rectangle13 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `1137px`,
  height: `92px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Rectangle14 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `0px 8px 8px 0px`,
  mixBlendMode: `multiply`,
  width: `716px`,
  height: `92px`,
  position: `absolute`,
  left: `421px`,
  top: `0px`,
}));

const Group243 = styled('div')({
  opacity: `0.20000000298023224`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `60px`,
  left: `32px`,
  top: `16px`,
});

const Done = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `2px`,
  top: `36px`,
}));

const Check4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `0px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector42 = styled('img')({
  height: `20.11px`,
  width: `26.39px`,
  position: `absolute`,
  left: `5px`,
  top: `8px`,
});

const Group25 = styled('div')({
  opacity: `0.20000000298023224`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `60px`,
  left: `100px`,
  top: `16px`,
});

const Reply = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `2px`,
  top: `36px`,
}));

const Reply1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `0px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector43 = styled('img')({
  height: `22.5px`,
  width: `27px`,
  position: `absolute`,
  left: `5px`,
  top: `8px`,
});

const Group27 = styled('div')({
  opacity: `0.20000000298023224`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `49px`,
  height: `60px`,
  left: `250px`,
  top: `16px`,
});

const Forward = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const Reply2 = styled('img')({
  height: `22.5px`,
  width: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const Group28 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `58px`,
  height: `60px`,
  left: `331px`,
  top: `16px`,
});

const FollowUp = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const FlashOn1 = styled('img')({
  height: `22.5px`,
  width: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const Group29 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `60px`,
  left: `453px`,
  top: `16px`,
});

const Chart = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `1px`,
  top: `36px`,
}));

const FolderShared = styled('img')({
  height: `22.5px`,
  width: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const Group30 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `60px`,
  height: `60px`,
  left: `521px`,
  top: `16px`,
});

const Encounter = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const IconStethoscope = styled('img')({
  height: `22.5px`,
  width: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const Group31 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `60px`,
  left: `613px`,
  top: `16px`,
});

const Note = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `4px`,
  top: `36px`,
}));

const Note1 = styled('img')({
  height: `22.5px`,
  width: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const Group32 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `67px`,
  height: `60px`,
  left: `681px`,
  top: `16px`,
});

const VirtualVisit3 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const VideocamOutline = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `16px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector44 = styled('img')({
  height: `18px`,
  width: `27px`,
  position: `absolute`,
  left: `5px`,
  top: `9px`,
});

const Group33 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `81px`,
  height: `60px`,
  left: `780px`,
  top: `16px`,
});

const NotifyPatient = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const NotificationsActive = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `23px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector45 = styled('img')({
  height: `29.25px`,
  width: `29.91px`,
  position: `absolute`,
  left: `3px`,
  top: `4px`,
});

const Group34 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `52px`,
  height: `60px`,
  left: `893px`,
  top: `16px`,
});

const NoShow = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
}));

const DoDisturbAlt = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `8px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector46 = styled('img')({
  height: `30px`,
  width: `30px`,
  position: `absolute`,
  left: `3px`,
  top: `3px`,
});

const Group36 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `53.99px`,
  height: `60px`,
  left: `977px`,
  top: `16px`,
});

const Take = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `4px`,
  top: `36px`,
}));

const Rotate90DegreesCcw = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `0px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector47 = styled('img')({
  height: `31.86px`,
  width: `31.71px`,
  position: `absolute`,
  left: `1px`,
  top: `1px`,
});

const Shape9 = styled('img')({
  height: `6.17px`,
  width: `9.99px`,
  position: `absolute`,
  left: `44px`,
  top: `15px`,
});

const Group35 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `60px`,
  left: `1063px`,
  top: `16px`,
});

const More1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `2px`,
  top: `36px`,
}));

const MoreHoriz1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `0px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector48 = styled('img')({
  height: `6px`,
  width: `24px`,
  position: `absolute`,
  left: `6px`,
  top: `15px`,
});

const Group26 = styled('div')({
  opacity: `0.20000000298023224`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `50px`,
  height: `60px`,
  left: `168px`,
  top: `16px`,
});

const ReplyAll = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `36px`,
  width: `65px`
}));

const ReplyAll1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `36px`,
  height: `36px`,
  left: `7px`,
  top: `0px`,
  overflow: `hidden`,
});

const Vector49 = styled('img')({
  height: `22.5px`,
  width: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const TianaMaya1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Q32YearSFemaleDob020 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  width: '400px',
  left: `0px`,
  top: `30px`,
}));

const SelectedPatient = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  position: `absolute`,
  width: `530px`,
  height: `104px`,
  left: `246px`,
  top: `272px`,
}));

