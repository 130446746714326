/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { styled } from '@mui/material/styles';
import Step from 'src/components/Step/Step';

function IntakeSteps(props) {
  return (
    <IntakeSteps1 className={props.className}>
      <Step1>
        <Step2 />
      </Step1>
      <Step21>
        <Frame4>
          <Rectangle2></Rectangle2>
          <Frame3>
            <Q1>{`2`}</Q1>
          </Frame3>
          <Rectangle1></Rectangle1>
        </Frame4>
        <StepText>{`Insurance`}</StepText>
      </Step21>
      <Step3>
        <Frame41>
          <Rectangle21></Rectangle21>
          <Frame31>
            <Q11>{`3`}</Q11>
          </Frame31>
          <Rectangle11></Rectangle11>
        </Frame41>
        <StepText1>{`Sign`}</StepText1>
      </Step3>
      <Step4>
        <Frame42>
          <Rectangle22></Rectangle22>
          <Frame32>
            <Q12>{`4`}</Q12>
          </Frame32>
          <Rectangle12></Rectangle12>
        </Frame42>
        <StepText2>{`Medications`}</StepText2>
      </Step4>
      <Step5>
        <Frame43>
          <Rectangle23></Rectangle23>
          <Frame33>
            <Q13>{`5`}</Q13>
          </Frame33>
          <Rectangle13></Rectangle13>
        </Frame43>
        <StepText3>{`Allergies`}</StepText3>
      </Step5>
      <Step6>
        <Frame44>
          <Rectangle24></Rectangle24>
          <Frame34>
            <Q14>{`6`}</Q14>
          </Frame34>
          <Rectangle14></Rectangle14>
        </Frame44>
        <StepText4>{`Health Issues`}</StepText4>
      </Step6>
    </IntakeSteps1>
  );
}

export default IntakeSteps;

const IntakeSteps1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: 'auto',
});

const Step1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Step2 = styled(Step)(({ theme }) => ({
  flex: `1`,
  width: `120px`,
  margin: `0px`,
}));

const Step21 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame4 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle2 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame3 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle1 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step3 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame41 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle21 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame31 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q11 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle11 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step4 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame42 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle22 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame32 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q12 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle12 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText2 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step5 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame43 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle23 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame33 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q13 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle13 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText3 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step6 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame44 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle24 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame34 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q14 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle14 = styled('div')({
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText4 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

