import "./PortalExp.css";
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { _VISUALS } from '../Config/index.js';

const ORG_TITLE_ICON = _VISUALS.TH_MENU_IMG;
const TH_CARETM_ICON = _VISUALS.TH_CARETM_ICON;
const TH_COMRS_ICON = _VISUALS.TH_COMRS_ICON;
const TH_DASHBD_ICON = _VISUALS.TH_DASHBD_ICON;
const TH_MYHR_ICON = _VISUALS.TH_MYHR_ICON;
const TH_SCHED_ICON = _VISUALS.TH_SCHED_ICON;
const TH_SEARCH_ICON = _VISUALS.TH_SEARCH_ICON;
const TH_VV_ICON = _VISUALS.TH_VV_ICON;

const PortalTHMenu=() => {
    const { isAuthenticated } = useAuth0();

    if (isAuthenticated){
        return (
            <section className='PortalExp-THMenu_Section'>
                <img className="PortalExp-THMenu_Icon" src= {ORG_TITLE_ICON} alt="Title"></img>
                    <section>
                        <h1 className='PortalExp-THMenu_Banner'>Access Care</h1>
                    </section>
                    
                    <hr className="PortalExp-THOption_Seperator"/>

                    <section className='PortalExp-THMenu_Option_Section'>
                        <span className='PortalExp-THMenu_Option_Text'>
                            <img className="PortalExp-THMenuOption_Icon" src= {TH_SEARCH_ICON} alt="Search"></img>                        
                            Find Care
                        </span>
                    
                        <hr className="PortalExp-THOption_Seperator"/>

                        <span className='PortalExp-THMenu_Option_Text'>
                            <img className="PortalExp-THMenuOption_Icon" src= {TH_SCHED_ICON} alt="Scheduled Visit"></img>                        
                            Manage Appointment
                        </span>
                    
                        <hr className="PortalExp-THOption_Seperator"/>
                    
                        <span className='PortalExp-THMenu_Option_Text'>
                            <img className="PortalExp-THMenuOption_Icon" src= {TH_COMRS_ICON} alt="Communication"></img>                        
                            Community Resources
                        </span>
                    
                        <hr className="PortalExp-THOption_Seperator"/>
                    
                        <span className='PortalExp-THMenu_Option_Text'>
                            <img className="PortalExp-THMenuOption_Icon" src= {TH_VV_ICON} alt="Virtual Visit"></img>
                            <Link className='PortalExp-THMenu_Option_Link' to='/PortalUrgentCare'>Virtual Visit with a Provider</Link>                            
                        </span>
                    
                        <hr className="PortalExp-THOption_Seperator"/>
                    
                        <span className='PortalExp-THMenu_Option_Text'>
                            <img className="PortalExp-THMenuOption_Icon" src= {TH_CARETM_ICON} alt="Care Team"></img>                        
                            Care Team
                        </span>
                    
                        <hr className="PortalExp-THOption_Seperator"/>
                    
                        <span className='PortalExp-THMenu_Option_Text'>
                            <img className="PortalExp-THMenuOption_Icon" src= {TH_DASHBD_ICON} alt="Dashboard"></img>                        
                            My Health Dashboard
                        </span>
                    
                        <hr className="PortalExp-THOption_Seperator"/>
                    
                        <span className='PortalExp-THMenu_Option_Text'>
                            <img className="PortalExp-THMenuOption_Icon" src= {TH_MYHR_ICON} alt="Health Record"></img>                        
                            My Health Records
                        </span>

                        <hr className="PortalExp-THOption_Seperator"/>
                    </section>
            </section>
        );
    }
    else {
        return (
            <section className='AmEx__Portal-Section'>
                <h3 className='AmEx__Portal-h3'>Health Portal. Not Logged In</h3>
            </section>
        );
    }

  }

  export default PortalTHMenu