import React from "react";
import "./footer.css";

const Footer = () => {
    return(
        <section className="footer">
            <hr className="footer-seperator"/>
            <section>
                <section className="footer-info">
                    <section className="footer-info-left">
                        <section className="footer-info__date">
                            &copy;{new Date().getFullYear()}
                        </section>
                    </section>
                    <section className="footer-info-center">
                        <section className="footer-info__privacy">
                            <a href="https://business.amwell.com/privacy-policy/">PRIVACY POLICY</a>
                        </section>
                    </section>
                    <section className="footer-info-right">
                        <section className="footer-info__TOU">
                            <a href="https://business.amwell.com/terms-of-use/">TERMS OF USE</a>
                        </section>
                    </section>
                </section>
            </section>
            <section className="footer-trademark">
                Amwell is a registered trademark of American Well Corporation.
            </section>
            <section className="footer-created">
                        Created by: Amwell Solutions Engineering
            </section>
        </section>
    )
}

export default Footer;