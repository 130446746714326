/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import QImage from '../../assets/images/IntakeSteps2___.png';
import { styled } from '@mui/material/styles';

function IntakeSteps2(props) {
  return (
    <IntakeSteps21 className={props.className}>
      <Frame12>
        <Step>
          <Frame4>
            <Rectangle2></Rectangle2>
            <Frame3>
              <Frame15>
                <Q src={QImage} loading="lazy" alt={''} />
              </Frame15>
            </Frame3>
            <Rectangle1></Rectangle1>
          </Frame4>
          <StepText>{`Personal Info`}</StepText>
        </Step>
        <Step1>
          <Frame41>
            <Rectangle21></Rectangle21>
            <Frame7>
              <Frame31>
                <Q1>{`2`}</Q1>
              </Frame31>
            </Frame7>
            <Rectangle11></Rectangle11>
          </Frame41>
          <StepText1>{`Insurance`}</StepText1>
        </Step1>
        <Step2>
          <Frame42>
            <Rectangle22></Rectangle22>
            <Frame32>
              <Q11>{`3`}</Q11>
            </Frame32>
            <Rectangle12></Rectangle12>
          </Frame42>
          <StepText2>{`Sign`}</StepText2>
        </Step2>
        <Step3>
          <Frame43>
            <Rectangle23></Rectangle23>
            <Frame33>
              <Q12>{`4`}</Q12>
            </Frame33>
            <Rectangle13></Rectangle13>
          </Frame43>
          <StepText3>{`Medications`}</StepText3>
        </Step3>
        <Step4>
          <Frame44>
            <Rectangle24></Rectangle24>
            <Frame34>
              <Q13>{`5`}</Q13>
            </Frame34>
            <Rectangle14></Rectangle14>
          </Frame44>
          <StepText4>{`Allergies`}</StepText4>
        </Step4>
        <Step5>
          <Frame45>
            <Rectangle25></Rectangle25>
            <Frame35>
              <Q14>{`6`}</Q14>
            </Frame35>
            <Rectangle15></Rectangle15>
          </Frame45>
          <StepText5>{`Health Issues`}</StepText5>
        </Step5>
      </Frame12>
    </IntakeSteps21>
  );
}

export default IntakeSteps2;

const IntakeSteps21 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `80px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
});

const Frame12 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `720px`,
  left: `0px`,
  top: `0px`,
});

const Step = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame4 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle2 = styled('div')({
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame3 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Frame15 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: `16px`,
  width: `16px`,
  margin: `0px`,
});

const Q = styled('img')({
  height: `11px`,
  width: `14px`,
  position: `absolute`,
  left: `2px`,
  top: `3px`,
});

const Rectangle1 = styled('div')({
  backgroundColor: `rgba(162, 240, 123, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame41 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle21 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame7 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `6px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Frame31 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  border: `4px solid rgba(255, 255, 255, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle11 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText1 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step2 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame42 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle22 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame32 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q11 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle12 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText2 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step3 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame43 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle23 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame33 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q12 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle13 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText3 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step4 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame44 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle24 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame34 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q13 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle14 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText4 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

const Step5 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  flex: `1`,
  margin: `0px`,
});

const Frame45 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  alignSelf: `stretch`,
  height: `48px`,
  margin: `0px`,
});

const Rectangle25 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const Frame35 = styled('div')({
  backgroundColor: `rgba(39, 95, 212, 1)`,
  borderRadius: `100px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `column`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `8px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const Q14 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `16px`,
  textTransform: `none`,
  width: `16px`,
  margin: `0px`,
});

const Rectangle15 = styled('div')({
  flex: `1`,
  height: `2px`,
  margin: `0px`,
});

const StepText5 = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(68, 68, 68, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `8px 0px 0px 0px`,
});

