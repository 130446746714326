import { useAuth0 } from '@auth0/auth0-react';
import PortalHome from './PortalHome';

const PortalExperience=() => {
    const { isAuthenticated } = useAuth0();

    if (isAuthenticated){        
        return (
            <section className='AmEx__Portal-Section'>
                <h1 className='AmEx__Portal-Banner'>Find Care</h1>
                <PortalHome/>
            </section>
        );
    }
    else {
        return (
            <section className='AmEx__Portal-Section'>
                <h3 className='AmEx__Portal-h3'>Health Portal. Not Logged In</h3>
            </section>
        );
    }

  }

  export default PortalExperience