/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { styled } from '@mui/material/styles';
import Check from 'src/components/Check/Check';
import XCircle from 'src/components/XCircle/XCircle';

const Chip1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  height: 'auto',
  width: 'fit-content',
});

const Chip2 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `2px solid rgba(0, 137, 6, 1)`,
  boxSizing: `border-box`,
  borderRadius: `32px`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px 12px`,
  width: `pppx`,
  height: `32px`,
  margin: `0px`,
  overflow: `hidden`,
}));

const LeadingIcon = styled(Check)(({ theme }) => ({
  width: `18px`,
  height: `18px`,
  margin: `0px 0px 0px 4px`,
}));

const Chip3 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px`,
});

const TrailingIcon = styled(XCircle)(({ theme }) => ({
  width: `18px`,
  height: `18px`,
  margin: `0px 0px 0px 4px`,
}));

function Chip(props) {
  return (
    <Chip1 className={props.className}>
      <Chip2>
        {false && <LeadingIcon />}
        <Chip3>{`Decline`}</Chip3>
        {false && <TrailingIcon />}
      </Chip2>
    </Chip1>
  );
}

export default Chip;
