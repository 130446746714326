//Import Dependancies
import { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { base64Encode} from '../modules/Utilities';
import { _URLS, _CONFIGS } from '../Config/index.js';
import usePatientExperience from './usePatientExperience';
import { getGlobal } from '../globals';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

//Load environment varaibles for the visit session
const oc_url = _URLS.oc_url;
const iss_url = _URLS.iss_url;
const tenant_id = _CONFIGS.tenant_id;
const ehr_id = _CONFIGS.ehr_id;

//FHIR Based SMART Launch URL Components
const launchType = _URLS.LAUNCH_TYPE;
const userType = _URLS.PATIENT_USER;
const launchCmd = _URLS.LAUNCH_CMD;

const nullableEntry = {
    previousVisitID: ""
};

const PatientExperience = props => {
    const { getAccessTokenSilently } = useAuth0();
    //Setup for Amwell Experience links
    const [editing, setEditing] = useState(nullableEntry);
    const [posts, setPosts] = useState(null);
    const { fns } = usePatientExperience();

    // Set session values
    //We need to create a unique prefix to the encounter to add to the encounter and avoid bumping into other visits 
    var encounterIdAppendix = _URLS.encounterIdAppendix;
    var provider_id = _URLS.provider_id;
    var patient_id = _URLS.patient_id;
    var mrn = _URLS.mrn;

    /** the direct launch URL is configurable! */
    const { convergeUrl } = getGlobal('config') || {};

    // Set intial json token values
    var token = {
		"patientMRN":mrn,
        "patientId":patient_id,
		"encounterId":encounterIdAppendix, //This value seems to be the only one being used at the moment. Will need to include the other JSON elements to see if an Auth server or something like that is being used.
		"providerConnectionExternalUserId":provider_id,
		"providerEncounterExternalUserId":"a6dfe8e5-f65e-4eda-a572-850f7ac0d7cf",
		"patientFirstName":"Aundrea",
		"patientLastName":"Grant",
		"patientPrefix":"Mrs.",
		"patientEmail":"AVLMEM-68b11ebtma-h2cbalq86k-b6y5d0lsot@aa.bb",
		"patientDOB":"1965-05-17",
		"patientGender":"F",
		"patientPhone":"555-998-9601",
		"encounterDepartmentState":"MA"
	},
    token_string = "",
    token64 = "",
    activeLink = "";

    /*
    useEffect(() => {
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                    audience: 'https://api.cvg01.amwell.systems/',
                    scope: 'openid profile email offline_access',
                    }
                });

                console.log("Token: " + token);
                
                const response = await fetch('https://amwellprod.amwell.auth0.com/userinfo', {
                    headers: {
                    Authorization: `Bearer ${token}`,
                    },
                });
                setPosts(await response.json());
            } 
            catch (e) {
                console.error(e);
            }
        })();
    }, [getAccessTokenSilently]);
    
    if (!posts) {
        return <div>Loading...</div>;
    }
    */

    return (
        <section className='AmEx__Patient-Section'>
            <h3>Patient Experience</h3>
            <button className='button' onClick={fns.handleMyChart} type="submit" value="" id="LaunchEHRSV" >MyChart</button>
            <br/>
            <button className='button' onClick={fns.handleEHRScheduledVisit} type="submit" value="" id="LaunchEHRSV" >Generic Patient Portal</button>
            <br/><br/>
            <h6>Alternaltely, please enter the Visit ID of your Scheduled Visit (No intake)</h6>
            <input className='encounterRandomPrefixInput' id="UserInputVisitPrefix" type="text" value={editing.previousVisitID} onChange={handleVisitIdInput}></input> 
            <br/>
            <button className='button' onClick={launchSVLink} type="submit">Join Scheduled Visit</button>
            <br/>
            <button className='button' onClick={launchODLink} type="submit">Launch Converge w/SSO</button>
        </section>
    );

    function handleVisitIdInput(e) {
        let newEdit = { ...editing};

        if(isDevMode){
            console.log("PatientExp.handleVisitIDInput - e:" + e);
        }

        newEdit.previousVisitID = e.target.value;
        
        setEditing(newEdit);
        
        prepareToStartVisit();
    }

    function launchODLink() {
        if(isDevMode){
            console.log("PatientExp launchODLink Called");
             console.log("useMyChart: URL = " + convergeUrl);
          }
          window.open(convergeUrl, "_blank");
    };

    function launchSVLink() {
        if(document.getElementById("UserInputVisitPrefix") !== null){
            if(document.getElementById("UserInputVisitPrefix").value !== "") {
                activeLink = oc_url + launchType + tenant_id + "/" + ehr_id + userType + iss_url + launchCmd;
                update_json();
                window.open(activeLink, "_blank");
            }
        }
    };

    function prepareToStartVisit(){
        //Grab the unique visit prefix and assign it to the enounter ID so that we can avoid bumping into another visit
        if(document.getElementById("UserInputVisitPrefix") !== null){
            if(isDevMode) {
                console.log("PatientExp prepareToStartVisit: token.encounterId: " + token.encounterId);
                console.log("PatientExp prepareToStartVisit: UserInputVisitPrefix: " + document.getElementById("UserInputVisitPrefix").value);
                console.log("PatientExp prepareToStartVisit: encounterIdAppendix: " + encounterIdAppendix);
            }

            if(document.getElementById("UserInputVisitPrefix").value !== "") {
                token.encounterId = document.getElementById("UserInputVisitPrefix").value + encounterIdAppendix;
                document.getElementById("UserInputVisitPrefix").href = activeLink
            }

            if(isDevMode) {
                console.log("ProviderExp prepareToStartVisit: Updated token.encounterId: " + token.encounterId);
            }
        }
    }

    /**
    *   Base64 encodes the current JSON token
    *   Update the displayed JSON and base64 encoded "launch" param
    *   Update all links with the new encoded launch param
    *   From "updateLaunchParam()" in Visit Launcher
    **/
    function update_json() {
        if(isDevMode){
            console.log("PaitientExp.update_json: update_json called");
        }
        
        if(document.getElementById("UserInputVisitPrefix") !== null) { //Wait until the DOM is loaded
            if(isDevMode){
                console.log("PatientExp.update_JSON - eID prefix: " + document.getElementById("UserInputVisitPrefix").value)
            }

            if(document.getElementById("UserInputVisitPrefix") !== "") {
                token.encounterId = document.getElementById("UserInputVisitPrefix").value + encounterIdAppendix;
            }
            else {
                token.encounterId = document.getElementById("UserInputVisitPrefix").value;
            }
        }
    
        token_string = JSON.stringify(token); //(token, null, 2);
        token64 = encodeURIComponent(base64Encode(token_string));
        activeLink = activeLink + token64;

        if(isDevMode){
            //console.log("update_JSON - Encoded Data2: " + token64)
            console.log("PatientExp.update_JSON - Patient Link: " + activeLink)
        }
    }
  }

  export default PatientExperience
