/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *   Do not change the name of the hook
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { useNavigate } from 'react-router-dom';
import { _URLS, _CONFIGS } from '../../Config/index';

const isDevMode = _CONFIGS.isDevMode;

const useProviderPatientChart = () => {
  const navigate = useNavigate();
  const data = {};

  const onConnectVideo = () => {
    if(isDevMode){
      console.log("useProviderPatientChart: onConnectVideo Called"); 
    }

    window.open(_URLS.CarePointCallingUrl, "_blank");
  };

  const onLogout = () => {
    if(isDevMode){
      console.log("useProviderEmrMessageVideoVisit: onLogOut Called"); 
    }

    navigate('/LogIn/ProviderExperience');
  };

  const fns = { onConnectVideo, onLogout };

  return { data, fns };
};

export default useProviderPatientChart;
