/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useState, useEffect } from 'react';
import ImgBackgroundImage from 'src/assets/images/MyChart_imgBackground.png';
import ImgHmcLogoImage from 'src/assets/images/MyChart_imgHMCLogo.png';
import ImgNamcLogoImage from 'src/assets/images/MyChart_imgNAMCLogo.png';
import ImgMomcLogoImage from 'src/assets/images/MyChart_imgMOMCLogo.png';
import ImgEdGoldenImage from 'src/assets/images/MyChart_ImgEdGolden.png';
import IconCalCircleImage from 'src/assets/images/MyChart_iconCalCircle.png';
import IconCalImage from 'src/assets/images/MyChart_iconCal.png';
import IconEmailCircleImage from 'src/assets/images/MyChart_iconEmailCircle.png';
import IconEmailImage from 'src/assets/images/MyChart_iconEmail.png';
import ImgTyroneMohrImage from 'src/assets/images/MyChart_imgTyroneMohr.png';
import IconCalCircle1Image from 'src/assets/images/MyChart_iconCalCircle_1.png';
import ImgCalImage from 'src/assets/images/MyChart_imgCal.png';
import IconEmailCircle1Image from 'src/assets/images/MyChart_iconEmailCircle_1.png';
import IconEmail1Image from 'src/assets/images/MyChart_iconEmail_1.png';
import ImgAndreaRogahnImage from 'src/assets/images/MyChart_imgAndreaRogahn.png';
import IconCalCircle2Image from 'src/assets/images/MyChart_iconCalCircle_2.png';
import ImgCal1Image from 'src/assets/images/MyChart_imgCal_1.png';
import IconEmailCircle2Image from 'src/assets/images/MyChart_iconEmailCircle_2.png';
import IconEmail2Image from 'src/assets/images/MyChart_iconEmail_2.png';
import ImgAnnaLopezImage from 'src/assets/images/MyChart_imgAnnaLopez.png';
import IconCalCircle3Image from 'src/assets/images/MyChart_iconCalCircle_3.png';
import IconCal1Image from 'src/assets/images/MyChart_iconCal_1.png';
import IconEmailCircle3Image from 'src/assets/images/MyChart_iconEmailCircle_3.png';
import IconEmail3Image from 'src/assets/images/MyChart_iconEmail_3.png';
import ImgElipseBub4Image from 'src/assets/images/MyChart_imgElipseBub4.png';
import ImgElipseBub3Image from 'src/assets/images/MyChart_imgElipseBub3.png';
import ImgElipseBub2Image from 'src/assets/images/MyChart_imgElipseBub2.png';
import ImgElipseBubActiveImage from 'src/assets/images/MyChart_imgElipseBubActive.png';
import ImgElipseBub1Image from 'src/assets/images/MyChart_imgElipseBub1.png';
import VectorImage from 'src/assets/images/MyChart_Vector.png';
import Vector1Image from 'src/assets/images/MyChart_Vector_1.png';
import ImgCalcBtn16Image from 'src/assets/images/MyChart_imgCalcBtn16.png';
import ImgCalcBtn15Image from 'src/assets/images/MyChart_imgCalcBtn15.png';
import ImgCalcBtn14Image from 'src/assets/images/MyChart_imgCalcBtn14.png';
import ImgCalcBtn13Image from 'src/assets/images/MyChart_imgCalcBtn13.png';
import ImgCalcBtn12Image from 'src/assets/images/MyChart_imgCalcBtn12.png';
import ImgCalcBtn11Image from 'src/assets/images/MyChart_imgCalcBtn11.png';
import ImgCalcBtn10Image from 'src/assets/images/MyChart_imgCalcBtn10.png';
import ImgCalcBtn9Image from 'src/assets/images/MyChart_imgCalcBtn9.png';
import ImgCalcBtn8Image from 'src/assets/images/MyChart_imgCalcBtn8.png';
import ImgCalcBtn7Image from 'src/assets/images/MyChart_imgCalcBtn7.png';
import ImgCalcBtn6Image from 'src/assets/images/MyChart_imgCalcBtn6.png';
import ImgCalcBtn5Image from 'src/assets/images/MyChart_imgCalcBtn5.png';
import ImgCalcBtn4Image from 'src/assets/images/MyChart_imgCalcBtn4.png';
import ImgCalcBtn3Image from 'src/assets/images/MyChart_imgCalcBtn3.png';
import ImgCalcBtn2Image from 'src/assets/images/MyChart_imgCalcBtn2.png';
import ImgCalcBtn1Image from 'src/assets/images/MyChart_imgCalcBtn1.png';
import ImgCalcBtn161Image from 'src/assets/images/MyChart_imgCalcBtn16_1.png';
import ImgCalcBtn151Image from 'src/assets/images/MyChart_imgCalcBtn15_1.png';
import ImgCalcBtn141Image from 'src/assets/images/MyChart_imgCalcBtn14_1.png';
import ImgCalcBtn131Image from 'src/assets/images/MyChart_imgCalcBtn13_1.png';
import ImgCalcBtn121Image from 'src/assets/images/MyChart_imgCalcBtn12_1.png';
import ImgCalcBtn111Image from 'src/assets/images/MyChart_imgCalcBtn11_1.png';
import ImgCalcBtn101Image from 'src/assets/images/MyChart_imgCalcBtn10_1.png';
import ImgCalcBtn91Image from 'src/assets/images/MyChart_imgCalcBtn9_1.png';
import ImgCalcBtn81Image from 'src/assets/images/MyChart_imgCalcBtn8_1.png';
import ImgCalcBtn71Image from 'src/assets/images/MyChart_imgCalcBtn7_1.png';
import ImgCalcBtn61Image from 'src/assets/images/MyChart_imgCalcBtn6_1.png';
import ImgCalcBtn51Image from 'src/assets/images/MyChart_imgCalcBtn5_1.png';
import ImgCalcBtn41Image from 'src/assets/images/MyChart_imgCalcBtn4_1.png';
import ImgCalcBtn31Image from 'src/assets/images/MyChart_imgCalcBtn3_1.png';
import ImgCalcBtn21Image from 'src/assets/images/MyChart_imgCalcBtn2_1.png';
import ImgCalcBtn17Image from 'src/assets/images/MyChart_imgCalcBtn1_1.png';
import GrpBulbIconImage from 'src/assets/images/MyChart_grpBulbIcon.png';
import ImgEnvBotCtrImage from 'src/assets/images/MyChart_imgEnvBotCtr.png';
import ImgEnvBotRgtImage from 'src/assets/images/MyChart_imgEnvBotRgt.png';
import ImgEnvBotLftImage from 'src/assets/images/MyChart_imgEnvBotLft.png';
import ImgEnvIconContentImage from 'src/assets/images/MyChart_imgEnvIconContent.png';
import ImgEnvFoldRgtImage from 'src/assets/images/MyChart_imgEnvFoldRgt.png';
import ImgEnvFoldLftImage from 'src/assets/images/MyChart_imgEnvFoldLft.png';
import ImgEnvFoldTopImage from 'src/assets/images/MyChart_imgEnvFoldTop.png';
import ImgVisitIcon2Image from 'src/assets/images/MyChart_imgVisitIcon2.png';
import ImgVisitIcon1Image from 'src/assets/images/MyChart_imgVisitIcon1.png';
import ImgViewAllIconImage from 'src/assets/images/MyChart_imgViewAllIcon.png';
import GrpMsgBubbleImage from 'src/assets/images/MyChart_grpMsgBubble.png';
import ImgDrIconImage from 'src/assets/images/MyChart_imgDrIcon.png';
import ImgEnvIconBotImage from 'src/assets/images/MyChart_imgEnvIconBot.png';
import ImgEnvIconTopImage from 'src/assets/images/MyChart_imgEnvIconTop.png';
import ImgPeopleIconImage from 'src/assets/images/MyChart_imgPeopleIcon.png';
import ImgHospIconImage from 'src/assets/images/MyChart_imgHospIcon.png';
import ImgClockIconImage from 'src/assets/images/MyChart_imgClockIcon.png';
import ImgCalBind2Image from 'src/assets/images/MyChart_imgCalBind2.png';
import ImgCalBind1Image from 'src/assets/images/MyChart_imgCalBind1.png';
import ImgCalDaysImage from 'src/assets/images/MyChart_imgCalDays.png';
import ImgCalPageTopImage from 'src/assets/images/MyChart_imgCalPageTop.png';
import ImgUserImage from 'src/assets/images/MyChart_imgUser.png';
import ImgUserDownArrowImage from 'src/assets/images/MyChart_imgUserDownArrow.png';
import ImgPillIconCircleImage from 'src/assets/images/MyChart_imgPillIconCircle.png';
import GrpPillIconImage from 'src/assets/images/MyChart_grpPillIcon.png';
import ImgTestResultsCircleImage from 'src/assets/images/MyChart_imgTestResultsCircle.png';
import GrpTestResultIconImage from 'src/assets/images/MyChart_grpTestResultIcon.png';
import ImgMessageCircleImage from 'src/assets/images/MyChart_imgMessageCircle.png';
import ImgMessageIconImage from 'src/assets/images/MyChart_imgMessageIcon.png';
import ImgCalCircleImage from 'src/assets/images/MyChart_imgCalCircle.png';
import ImgCalIconImage from 'src/assets/images/MyChart_imgCalIcon.png';
import ImgStripesCircleImage from 'src/assets/images/MyChart_imgStripesCircle.png';
import GrpIconStripesImage from 'src/assets/images/MyChart_grpIconStripes.png';
import ImgGlobeCircleImage from 'src/assets/images/MyChart_imgGlobeCircle.png';
import GrpLogOutImage from 'src/assets/images/MyChart_grpLogOut.png';
import ImgMyChartTitleImage from 'src/assets/images/MyChart_imgMyChartTitle.png';
import ImgEpicLogoImage from 'src/assets/images/MyChart_imgEpicLogo.png';
import { styled } from '@mui/material/styles';
import useMyChart from 'src/pages/MyChart/useMyChart';
import { getGlobal } from '../../globals';
import { getPatientList } from '../../modules/Utilities';

function MyChart(props) {
  const { fns } = useMyChart();
  const [ strFirstName, setPatientName ] = useState("");
  const today = new Date();
  const strLastLetter = (today.getMonth() - 1) + "/" + today.getDate() + "/" + (today.getFullYear());

  useEffect(() => {
    const activePatient = getGlobal("gActiveUser");
    
    if(!activePatient) {
      getPatientList();
      return;
    }
    else {
      //We have a patient list, so go ahead and set the first patient name in the list
      setPatientName(activePatient.given_name);
    }
  }, [])

  return (
    <MyChart1 className={props.className}>
      <ImgBackground
        src={ImgBackgroundImage}
        loading="lazy"
        alt={'imgBackground'}
      />
      <ImgCursorMask></ImgCursorMask>
      <ImgWhiteBackground></ImgWhiteBackground>
      <GrpLinkedAccounts>
        <TxtDetails>{`See Details (4 linked accounts)`}</TxtDetails>
        <GrpHmc>
          <TxtHmcTitle>{`Homeland Medical Center`}</TxtHmcTitle>
          <ImgHmcLogo src={ImgHmcLogoImage} loading="lazy" alt={'imgHMCLogo'} />
        </GrpHmc>
        <GrpNamc>
          <TxtNamcTitle>{`North Atlantic Medical Center`}</TxtNamcTitle>
          <ImgNamcLogo
            src={ImgNamcLogoImage}
            loading="lazy"
            alt={'imgNAMCLogo'}
          />
        </GrpNamc>
        <GrpMomc>
          <TxtMomcTitle>{`Meadow Oaks Medical Center`}</TxtMomcTitle>
          <ImgMomcLogo
            src={ImgMomcLogoImage}
            loading="lazy"
            alt={'imgMOMCLogo'}
          />
        </GrpMomc>
        <TxtSubTitle>
          {`Other places where you have health information`}
        </TxtSubTitle>
        <TxtTitle>{`Linked Accounts`}</TxtTitle>
      </GrpLinkedAccounts>
      <GrpCareTeams>
        <TxtCareTeamDetails>{`See Details (8 Providers)`}</TxtCareTeamDetails>
        <GrpEdGolden>
          <TxtEduardoGoldenMd>{`Eduardo Golden, MD`}</TxtEduardoGoldenMd>
          <TxtPrimaryCareProvid>{`Primary Care Provider`}</TxtPrimaryCareProvid>
          <ImgEdGolden
            src={ImgEdGoldenImage}
            loading="lazy"
            alt={'ImgEdGolden'}
          />
          <TxtFamilyPractice>{`Family Practice`}</TxtFamilyPractice>
          <GrpCalendarIcon>
            <IconCalCircle
              src={IconCalCircleImage}
              loading="lazy"
              alt={'iconCalCircle'}
            />
            <IconCalLayer>
              <IconCal src={IconCalImage} loading="lazy" alt={'iconCal'} />
            </IconCalLayer>
          </GrpCalendarIcon>
          <GrpEmailIcon>
            <IconEmailCircle
              src={IconEmailCircleImage}
              loading="lazy"
              alt={'iconEmailCircle'}
            />
            <IconEmail src={IconEmailImage} loading="lazy" alt={'iconEmail'} />
          </GrpEmailIcon>
        </GrpEdGolden>
        <GrpTyroneMohr>
          <TxtAnnaLopezMd>{`Tyrone Mohr, MD`}</TxtAnnaLopezMd>
          <TxtPrimaryCareProvid1>
            {`Primary Care Provider`}
          </TxtPrimaryCareProvid1>
          <ImgTyroneMohr
            src={ImgTyroneMohrImage}
            loading="lazy"
            alt={'imgTyroneMohr'}
          />
          <TxtFamilyPractice1>{`Family Practice`}</TxtFamilyPractice1>
          <GrpCal>
            <IconCalCircle1
              src={IconCalCircle1Image}
              loading="lazy"
              alt={'iconCalCircle'}
            />
            <GrpCalIcon>
              <ImgCal src={ImgCalImage} loading="lazy" alt={'imgCal'} />
            </GrpCalIcon>
          </GrpCal>
          <GrpEmail>
            <IconEmailCircle1
              src={IconEmailCircle1Image}
              loading="lazy"
              alt={'iconEmailCircle'}
            />
            <IconEmail1
              src={IconEmail1Image}
              loading="lazy"
              alt={'iconEmail'}
            />
          </GrpEmail>
        </GrpTyroneMohr>
        <GrpAndreaRogahn>
          <TxtAndreaRogahnMd>{`Andrea Rogahn, MD`}</TxtAndreaRogahnMd>
          <TxtPrimaryCareProvid2>
            {`Primary Care Provider`}
          </TxtPrimaryCareProvid2>
          <ImgAndreaRogahn
            src={ImgAndreaRogahnImage}
            loading="lazy"
            alt={'imgAndreaRogahn'}
          />
          <TxtFamilyPractice2>{`Family Practice`}</TxtFamilyPractice2>
          <GrpCal1>
            <IconCalCircle2
              src={IconCalCircle2Image}
              loading="lazy"
              alt={'iconCalCircle'}
            />
            <GrpCalIcon1>
              <ImgCal1 src={ImgCal1Image} loading="lazy" alt={'imgCal'} />
            </GrpCalIcon1>
          </GrpCal1>
          <GrpEmail1>
            <IconEmailCircle2
              src={IconEmailCircle2Image}
              loading="lazy"
              alt={'iconEmailCircle'}
            />
            <IconEmail2
              src={IconEmail2Image}
              loading="lazy"
              alt={'iconEmail'}
            />
          </GrpEmail1>
        </GrpAndreaRogahn>
        <GrpAnnaLopez>
          <TxtAnnaLopezMd1>{`Anna Lopez, MD`}</TxtAnnaLopezMd1>
          <TxtPrimaryCareProvid3>
            {`Primary Care Provider`}
          </TxtPrimaryCareProvid3>
          <ImgAnnaLopez
            src={ImgAnnaLopezImage}
            loading="lazy"
            alt={'imgAnnaLopez'}
          />
          <TxtFamilyPractice3>{`Family Practice`}</TxtFamilyPractice3>
          <GrpCal2>
            <IconCalCircle3
              src={IconCalCircle3Image}
              loading="lazy"
              alt={'iconCalCircle'}
            />
            <GrpCalIcon2>
              <IconCal1 src={IconCal1Image} loading="lazy" alt={'iconCal'} />
            </GrpCalIcon2>
          </GrpCal2>
          <GrpEmail2>
            <IconEmailCircle3
              src={IconEmailCircle3Image}
              loading="lazy"
              alt={'iconEmailCircle'}
            />
            <IconEmail3
              src={IconEmail3Image}
              loading="lazy"
              alt={'iconEmail'}
            />
          </GrpEmail2>
        </GrpAnnaLopez>
        <TxtCareTeamTitle>
          {`Your Care Team and Recent Providers`}
        </TxtCareTeamTitle>
      </GrpCareTeams>
      <GrpExplore>
        <GrpNextElipse>
          <ImgElipseBub4
            src={ImgElipseBub4Image}
            loading="lazy"
            alt={'imgElipseBub4'}
          />
          <ImgElipseBub3
            src={ImgElipseBub3Image}
            loading="lazy"
            alt={'imgElipseBub3'}
          />
          <ImgElipseBub2
            src={ImgElipseBub2Image}
            loading="lazy"
            alt={'imgElipseBub2'}
          />
          <ImgElipseBubActive
            src={ImgElipseBubActiveImage}
            loading="lazy"
            alt={'imgElipseBubActive'}
          />
          <ImgElipseBub1
            src={ImgElipseBub1Image}
            loading="lazy"
            alt={'imgElipseBub1'}
          />
        </GrpNextElipse>
        <GrpPlanTrip>
          <ImgPlanTripBox></ImgPlanTripBox>
          <GrpPlanTripBtn>
            <BtnPLanTrip></BtnPLanTrip>
            <TxtPlanTripBtn>{`Get an Estimate`}</TxtPlanTripBtn>
          </GrpPlanTripBtn>
          <TxtPlanTripDesc>
            {`Learn more about\nhow to make\na trip to `}
          </TxtPlanTripDesc>
          <TxtPlanTripTitle>{`Plan a trip to Doctor Office`}</TxtPlanTripTitle>
          <Layer1>
            <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
            <Vector1 src={Vector1Image} loading="lazy" alt={'Vector'} />
          </Layer1>
        </GrpPlanTrip>
        <GrpPriceEst>
          <IgGetEstBox></IgGetEstBox>
          <GrpGetEstBtn>
            <BtnGetEst></BtnGetEst>
            <TxtGetEstBtn>{`Get an Estimate`}</TxtGetEstBtn>
          </GrpGetEstBtn>
          <TxtEstDesc>
            {`Worried about how much a procedure will cost? Request an estimate before receiving care.`}
          </TxtEstDesc>
          <TxtEstTitle>{`Price Transparency`}</TxtEstTitle>
          <ImgCalcIcon>
            <ImgCalcBtn16
              src={ImgCalcBtn16Image}
              loading="lazy"
              alt={'imgCalcBtn16'}
            />
            <ImgCalcBtn15
              src={ImgCalcBtn15Image}
              loading="lazy"
              alt={'imgCalcBtn15'}
            />
            <ImgCalcBtn14
              src={ImgCalcBtn14Image}
              loading="lazy"
              alt={'imgCalcBtn14'}
            />
            <ImgCalcBtn13
              src={ImgCalcBtn13Image}
              loading="lazy"
              alt={'imgCalcBtn13'}
            />
            <ImgCalcBtn12
              src={ImgCalcBtn12Image}
              loading="lazy"
              alt={'imgCalcBtn12'}
            />
            <ImgCalcBtn11
              src={ImgCalcBtn11Image}
              loading="lazy"
              alt={'imgCalcBtn11'}
            />
            <ImgCalcBtn10
              src={ImgCalcBtn10Image}
              loading="lazy"
              alt={'imgCalcBtn10'}
            />
            <ImgCalcBtn9
              src={ImgCalcBtn9Image}
              loading="lazy"
              alt={'imgCalcBtn9'}
            />
            <ImgCalcBtn8
              src={ImgCalcBtn8Image}
              loading="lazy"
              alt={'imgCalcBtn8'}
            />
            <ImgCalcBtn7
              src={ImgCalcBtn7Image}
              loading="lazy"
              alt={'imgCalcBtn7'}
            />
            <ImgCalcBtn6
              src={ImgCalcBtn6Image}
              loading="lazy"
              alt={'imgCalcBtn6'}
            />
            <ImgCalcBtn5
              src={ImgCalcBtn5Image}
              loading="lazy"
              alt={'imgCalcBtn5'}
            />
            <ImgCalcBtn4
              src={ImgCalcBtn4Image}
              loading="lazy"
              alt={'imgCalcBtn4'}
            />
            <ImgCalcBtn3
              src={ImgCalcBtn3Image}
              loading="lazy"
              alt={'imgCalcBtn3'}
            />
            <ImgCalcBtn2
              src={ImgCalcBtn2Image}
              loading="lazy"
              alt={'imgCalcBtn2'}
            />
            <ImgCalcBtn1
              src={ImgCalcBtn1Image}
              loading="lazy"
              alt={'imgCalcBtn1'}
            />
          </ImgCalcIcon>
        </GrpPriceEst>
        <GrpPriceDocTalk>
          <ImgDocTalkBox></ImgDocTalkBox>
          <GrpDocTalkBtn>
            <ButtonStyle></ButtonStyle>
            <Label>{`Talk to a Doctor`}</Label>
          </GrpDocTalkBtn>
          <TxtDocTalkDesc>{`Price Transparency`}</TxtDocTalkDesc>
          <TxtDocTalkTitle>
            {`Worried about how much a procedure will cost? Request an estimate before receiving care.`}
          </TxtDocTalkTitle>
          <GrpCalcIcon>
            <ImgCalcBtn161
              src={ImgCalcBtn161Image}
              loading="lazy"
              alt={'imgCalcBtn16'}
            />
            <ImgCalcBtn151
              src={ImgCalcBtn151Image}
              loading="lazy"
              alt={'imgCalcBtn15'}
            />
            <ImgCalcBtn141
              src={ImgCalcBtn141Image}
              loading="lazy"
              alt={'imgCalcBtn14'}
            />
            <ImgCalcBtn131
              src={ImgCalcBtn131Image}
              loading="lazy"
              alt={'imgCalcBtn13'}
            />
            <ImgCalcBtn121
              src={ImgCalcBtn121Image}
              loading="lazy"
              alt={'imgCalcBtn12'}
            />
            <ImgCalcBtn111
              src={ImgCalcBtn111Image}
              loading="lazy"
              alt={'imgCalcBtn11'}
            />
            <ImgCalcBtn101
              src={ImgCalcBtn101Image}
              loading="lazy"
              alt={'imgCalcBtn10'}
            />
            <ImgCalcBtn91
              src={ImgCalcBtn91Image}
              loading="lazy"
              alt={'imgCalcBtn9'}
            />
            <ImgCalcBtn81
              src={ImgCalcBtn81Image}
              loading="lazy"
              alt={'imgCalcBtn8'}
            />
            <ImgCalcBtn71
              src={ImgCalcBtn71Image}
              loading="lazy"
              alt={'imgCalcBtn7'}
            />
            <ImgCalcBtn61
              src={ImgCalcBtn61Image}
              loading="lazy"
              alt={'imgCalcBtn6'}
            />
            <ImgCalcBtn51
              src={ImgCalcBtn51Image}
              loading="lazy"
              alt={'imgCalcBtn5'}
            />
            <ImgCalcBtn41
              src={ImgCalcBtn41Image}
              loading="lazy"
              alt={'imgCalcBtn4'}
            />
            <ImgCalcBtn31
              src={ImgCalcBtn31Image}
              loading="lazy"
              alt={'imgCalcBtn3'}
            />
            <ImgCalcBtn21
              src={ImgCalcBtn21Image}
              loading="lazy"
              alt={'imgCalcBtn2'}
            />
            <ImgCalcBtn17
              src={ImgCalcBtn17Image}
              loading="lazy"
              alt={'imgCalcBtn1'}
            />
          </GrpCalcIcon>
        </GrpPriceDocTalk>
        <GrpExplore1>
          <TxtExploreTitle>{`Explore More`}</TxtExploreTitle>
          <GrpBulbIcon
            src={GrpBulbIconImage}
            loading="lazy"
            alt={'grpBulbIcon'}
          />
        </GrpExplore1>
      </GrpExplore>
      <GrpViewLtr>
        <GrpViewLtrBtn>
          <BtnViewLtr></BtnViewLtr>
          <TxtViewLtr>{`View Letter`}</TxtViewLtr>
        </GrpViewLtrBtn>
        <TxtViewLtrDesc>
          {'New letter recieved on ' + strLastLetter + ' from Dr. Drew Walker'}
        </TxtViewLtrDesc>
        <GrpViewLtrIcon>
          <ImgEnvBotCtr
            src={ImgEnvBotCtrImage}
            loading="lazy"
            alt={'imgEnvBotCtr'}
          />
          <ImgEnvBotRgt
            src={ImgEnvBotRgtImage}
            loading="lazy"
            alt={'imgEnvBotRgt'}
          />
          <ImgEnvBotLft
            src={ImgEnvBotLftImage}
            loading="lazy"
            alt={'imgEnvBotLft'}
          />
          <ImgEnvIconContent
            src={ImgEnvIconContentImage}
            loading="lazy"
            alt={'imgEnvIconContent'}
          />
          <ImgEnvFoldRgt
            src={ImgEnvFoldRgtImage}
            loading="lazy"
            alt={'imgEnvFoldRgt'}
          />
          <ImgEnvFoldLft
            src={ImgEnvFoldLftImage}
            loading="lazy"
            alt={'imgEnvFoldLft'}
          />
          <ImgEnvFoldTop
            src={ImgEnvFoldTopImage}
            loading="lazy"
            alt={'imgEnvFoldTop'}
          />
        </GrpViewLtrIcon>
      </GrpViewLtr>
      <GrpVirtualVisits>
        <GrpVisitGetStarted onClick={fns.onVisitStart}>
          <BtnVisitGetStarted></BtnVisitGetStarted>
          <TxtVisitGetStarted>{`Get Started`}</TxtVisitGetStarted>
        </GrpVisitGetStarted>
        <TxtVisitDesc>
          {`Need care now with the next available provider or to schedule a visit with a specialist? We've got you covered!`}
        </TxtVisitDesc>
        <TxtVisitTitle>{`Have a Virtual Visit`}</TxtVisitTitle>
        <GrpVisitIcon>
          <ImgVisitIcon2
            src={ImgVisitIcon2Image}
            loading="lazy"
            alt={'imgVisitIcon2'}
          />
          <ImgVisitIcon1
            src={ImgVisitIcon1Image}
            loading="lazy"
            alt={'imgVisitIcon1'}
          />
        </GrpVisitIcon>
      </GrpVirtualVisits>
      <GrpQuestionnaireHelp>
        <GrpViewAll>
          <TxtViewAll>{`View All (4)`}</TxtViewAll>
          <ImgViewAllIcon
            src={ImgViewAllIconImage}
            loading="lazy"
            alt={'imgViewAllIcon'}
          />
        </GrpViewAll>
        <ImgMsgHelpDivider></ImgMsgHelpDivider>
        <GrpViewMsgBtn>
          <BtnViewMsg></BtnViewMsg>
          <TxtViewMsg>{`View Message`}</TxtViewMsg>
        </GrpViewMsgBtn>
        <GrpMsgBubble
          src={GrpMsgBubbleImage}
          loading="lazy"
          alt={'grpMsgBubble'}
        />
        <TxtMsgContent>
          {`Good afternoon, our nurses will be able to help you when you arrive`}
        </TxtMsgContent>
        <ImgDrIcon src={ImgDrIconImage} loading="lazy" alt={'imgDrIcon'} />
        <TxtMsgName>
          {`Dr. Anna\nLopex, MD`}
        </TxtMsgName>
        <TxtMsgHelpTitle>{`Questionnaire Help`}</TxtMsgHelpTitle>
        <ImgEnvIconBot
          src={ImgEnvIconBotImage}
          loading="lazy"
          alt={'imgEnvIconBot'}
        />
        <ImgEnvIconTop
          src={ImgEnvIconTopImage}
          loading="lazy"
          alt={'imgEnvIconTop'}
        />
      </GrpQuestionnaireHelp>
      <GrpUpcomingVisit>
        <GrpViewDetails onClick={fns.onViewDetails}>
          <TxtViewDetails>{`View Details`}</TxtViewDetails>
        </GrpViewDetails>
        <GrpECheckIn onClick={fns.OnCheckIn}>
          <BtnECheckIn></BtnECheckIn>
          <TxtECheckin>{`eCheck-In`}</TxtECheckin>
        </GrpECheckIn>
        <TxtDrName>{`with Dominic Bradley, MD`}</TxtDrName>
        <GrpPeopleIcon>
          <ImgPeopleIcon
            src={ImgPeopleIconImage}
            loading="lazy"
            alt={'imgPeopleIcon'}
          />
        </GrpPeopleIcon>
        <TxtVisitClinicName>{`Verona Cardiology Clinic`}</TxtVisitClinicName>
        <GrpHospIcon>
          <ImgHospIcon
            src={ImgHospIconImage}
            loading="lazy"
            alt={'imgHospIcon'}
          />
        </GrpHospIcon>
        <TxtStartTime>{`Starts at 11:30 AM ET`}</TxtStartTime>
        <GrpClockIcon>
          <ImgClockIcon
            src={ImgClockIconImage}
            loading="lazy"
            alt={'imgClockIcon'}
          />
        </GrpClockIcon>
        <TxtDay>{today.toLocaleDateString('default', {weekday: 'short'})}</TxtDay>
        <TxtDate>{today.getUTCDate()}</TxtDate>
        <TxtMonth>{today.toLocaleDateString('default', {month: 'short'})}</TxtMonth>
        <TxtUpcomingOfficeVis>{`Upcoming Office Visit`}</TxtUpcomingOfficeVis>
        <GrpCalIcon3>
          <ImgCalBind2
            src={ImgCalBind2Image}
            loading="lazy"
            alt={'imgCalBind2'}
          />
          <ImgCalBind1
            src={ImgCalBind1Image}
            loading="lazy"
            alt={'imgCalBind1'}
          />
          <GrpCalPage>
            <ImgCalDays
              src={ImgCalDaysImage}
              loading="lazy"
              alt={'imgCalDays'}
            />
            <ImgCalPageTop
              src={ImgCalPageTopImage}
              loading="lazy"
              alt={'imgCalPageTop'}
            />
          </GrpCalPage>
          <ImgCalSelection></ImgCalSelection>
        </GrpCalIcon3>
      </GrpUpcomingVisit>
      <TxtWelcomeTitle>{`Welcome!`}</TxtWelcomeTitle>
      <GrpnavigationBar>
        <GrpUserBtn>
          <ImgUser src={ImgUserImage} loading="lazy" alt={'imgUser'} />
          <TxtUserName>{strFirstName}</TxtUserName>
          <ImgUserDownArrow
            src={ImgUserDownArrowImage}
            loading="lazy"
            alt={'imgUserDownArrow'}
          />
        </GrpUserBtn>
        <GrpMedications>
          <GrpMedicationsIcon>
            <ImgPillIconCircle
              src={ImgPillIconCircleImage}
              loading="lazy"
              alt={'imgPillIconCircle'}
            />
            <GrpPillIcon
              src={GrpPillIconImage}
              loading="lazy"
              alt={'grpPillIcon'}
            />
          </GrpMedicationsIcon>
          <TxtMedications>{`Medications`}</TxtMedications>
        </GrpMedications>
        <GrpTestResults>
          <GrpTestResults1>
            <ImgTestResultsCircle
              src={ImgTestResultsCircleImage}
              loading="lazy"
              alt={'imgTestResultsCircle'}
            />
            <GrpTestResultIcon
              src={GrpTestResultIconImage}
              loading="lazy"
              alt={'grpTestResultIcon'}
            />
          </GrpTestResults1>
          <TxtTestResults>{`Test Results`}</TxtTestResults>
        </GrpTestResults>
        <GrpMessageCenter>
          <GrpMessageIcon>
            <ImgMessageCircle
              src={ImgMessageCircleImage}
              loading="lazy"
              alt={'imgMessageCircle'}
            />
            <ImgMessageIcon
              src={ImgMessageIconImage}
              loading="lazy"
              alt={'imgMessageIcon'}
            />
          </GrpMessageIcon>
          <TxtMessageCenter>{`Message Center`}</TxtMessageCenter>
        </GrpMessageCenter>
        <GrpVisits>
          <GrpVisits1>
            <ImgCalCircle
              src={ImgCalCircleImage}
              loading="lazy"
              alt={'imgCalCircle'}
            />
            <ImgCal2>
              <ImgCalIcon
                src={ImgCalIconImage}
                loading="lazy"
                alt={'imgCalIcon'}
              />
            </ImgCal2>
          </GrpVisits1>
          <TxtVisits>{`Visits`}</TxtVisits>
        </GrpVisits>
        <GrpMenu>
          <GrpMenuIcon>
            <ImgStripesCircle
              src={ImgStripesCircleImage}
              loading="lazy"
              alt={'imgStripesCircle'}
            />
            <GrpIconStripes
              src={GrpIconStripesImage}
              loading="lazy"
              alt={'grpIconStripes'}
            />
          </GrpMenuIcon>
          <TxtMenu>{`Menu`}</TxtMenu>
        </GrpMenu>
      </GrpnavigationBar>
      <GrpPageTitleBar>
        <GrpUnivIcon>
          <ImgUnitRect></ImgUnitRect>
          <ImgGlobe>
            <ImgGlobeCircle
              src={ImgGlobeCircleImage}
              loading="lazy"
              alt={'imgGlobeCircle'}
            />
          </ImgGlobe>
        </GrpUnivIcon>
        <BtnLogOut onClick={fns.onLogOut}>
          <GrpLogOut src={GrpLogOutImage} loading="lazy" alt={'grpLogOut'} />
          <TxtLogOut>{`Log Out`}</TxtLogOut>
        </BtnLogOut>
        <ImgMyChartTitle
          src={ImgMyChartTitleImage}
          loading="lazy"
          alt={'imgMyChartTitle'}
        />
        <GrpEpicLogo>
          <ImgEpicLogo
            src={ImgEpicLogoImage}
            loading="lazy"
            alt={'imgEpicLogo'}
          />
          <TxtMyChartLogo>{`MyChart by`}</TxtMyChartLogo>
        </GrpEpicLogo>
      </GrpPageTitleBar>
    </MyChart1>
  );
}

export default MyChart;

const MyChart1 = styled('div')({
  backgroundColor: `rgba(123, 163, 208, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1000px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const ImgBackground = styled('img')({
  height: `912px`,
  width: `1439px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `1px`,
  top: `0px`,
});

const ImgCursorMask = styled('div')({
  backgroundColor: `rgba(31, 97, 175, 1)`,
  width: `84px`,
  height: `98px`,
  position: `absolute`,
  left: `1356px`,
  top: `814px`,
});

const ImgWhiteBackground = styled('div')({
  backgroundColor: `rgba(246, 246, 246, 1)`,
  width: `1200px`,
  height: `800px`,
  position: `absolute`,
  left: `120px`,
  top: `113px`,
});

const GrpLinkedAccounts = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `6px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `351px`,
  height: `228px`,
  left: `923px`,
  top: `604px`,
  overflow: `hidden`,
});

const TxtDetails = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `74px`,
  top: `190px`,
});

const GrpHmc = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `102px`,
  height: `95px`,
  left: `237px`,
  top: `82px`,
});

const TxtHmcTitle = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `14px`,
  textTransform: `none`,
  width: `102px`,
  position: `absolute`,
  left: `0px`,
  top: `67px`,
}));

const ImgHmcLogo = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `19px`,
  top: `0px`,
});

const GrpNamc = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `102px`,
  height: `95px`,
  left: `123px`,
  top: `82px`,
});

const TxtNamcTitle = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `14px`,
  textTransform: `none`,
  width: `102px`,
  position: `absolute`,
  left: `0px`,
  top: `67px`,
}));

const ImgNamcLogo = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `19px`,
  top: `0px`,
});

const GrpMomc = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `102px`,
  height: `95px`,
  left: `9px`,
  top: `82px`,
});

const TxtMomcTitle = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `14px`,
  textTransform: `none`,
  width: `102px`,
  position: `absolute`,
  left: `0px`,
  top: `67px`,
}));

const ImgMomcLogo = styled('img')({
  height: `64px`,
  width: `64px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `19px`,
  top: `0px`,
});

const TxtSubTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  width: `277px`,
  position: `absolute`,
  left: `16px`,
  top: `34px`,
});

const TxtTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(45, 105, 139, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `16px`,
  top: `10px`,
});

const GrpCareTeams = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `6px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `360px`,
  height: `415px`,
  left: `920px`,
  top: `170px`,
  overflow: `hidden`,
});

const TxtCareTeamDetails = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `99px`,
  top: `380px`,
});

const GrpEdGolden = styled('div')({
  backgroundColor: `rgba(208, 232, 243, 1)`,
  borderRadius: `12px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `328px`,
  height: `71px`,
  left: `16px`,
  top: `302px`,
  overflow: `hidden`,
});

const TxtEduardoGoldenMd = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `12px`,
});

const TxtPrimaryCareProvid = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `30px`,
});

const ImgEdGolden = styled('img')({
  height: `40px`,
  width: `40px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `12px`,
  top: `16px`,
});

const TxtFamilyPractice = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `49px`,
});

const GrpCalendarIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `219px`,
  top: `16px`,
});

const IconCalCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const IconCalLayer = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `22px`,
  height: `24px`,
  left: `10px`,
  top: `9px`,
  overflow: `hidden`,
});

const IconCal = styled('img')({
  height: `24px`,
  width: `22px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpEmailIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `270px`,
  top: `16px`,
});

const IconEmailCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const IconEmail = styled('img')({
  height: `15.21px`,
  width: `25.39px`,
  position: `absolute`,
  left: `8px`,
  top: `13px`,
});

const GrpTyroneMohr = styled('div')({
  backgroundColor: `rgba(208, 232, 243, 1)`,
  borderRadius: `12px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `328px`,
  height: `71px`,
  left: `16px`,
  top: `215px`,
  overflow: `hidden`,
});

const TxtAnnaLopezMd = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `12px`,
});

const TxtPrimaryCareProvid1 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `30px`,
});

const ImgTyroneMohr = styled('img')({
  height: `40px`,
  width: `40px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `12px`,
  top: `16px`,
});

const TxtFamilyPractice1 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `48px`,
});

const GrpCal = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `219px`,
  top: `16px`,
});

const IconCalCircle1 = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpCalIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `22px`,
  height: `24px`,
  left: `10px`,
  top: `9px`,
  overflow: `hidden`,
});

const ImgCal = styled('img')({
  height: `24px`,
  width: `22px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpEmail = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `270px`,
  top: `16px`,
});

const IconEmailCircle1 = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const IconEmail1 = styled('img')({
  height: `15.21px`,
  width: `25.39px`,
  position: `absolute`,
  left: `8px`,
  top: `13px`,
});

const GrpAndreaRogahn = styled('div')({
  backgroundColor: `rgba(208, 232, 243, 1)`,
  borderRadius: `12px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `328px`,
  height: `71px`,
  left: `16px`,
  top: `128px`,
  overflow: `hidden`,
});

const TxtAndreaRogahnMd = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `12px`,
});

const TxtPrimaryCareProvid2 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `30px`,
});

const ImgAndreaRogahn = styled('img')({
  height: `40px`,
  width: `40px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `12px`,
  top: `16px`,
});

const TxtFamilyPractice2 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `48px`,
});

const GrpCal1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `219px`,
  top: `16px`,
});

const IconCalCircle2 = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpCalIcon1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `22px`,
  height: `24px`,
  left: `10px`,
  top: `9px`,
  overflow: `hidden`,
});

const ImgCal1 = styled('img')({
  height: `24px`,
  width: `22px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpEmail1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `270px`,
  top: `16px`,
});

const IconEmailCircle2 = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const IconEmail2 = styled('img')({
  height: `15.21px`,
  width: `25.39px`,
  position: `absolute`,
  left: `8px`,
  top: `13px`,
});

const GrpAnnaLopez = styled('div')({
  backgroundColor: `rgba(208, 232, 243, 1)`,
  borderRadius: `12px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `328px`,
  height: `71px`,
  left: `16px`,
  top: `41px`,
  overflow: `hidden`,
});

const TxtAnnaLopezMd1 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `12px`,
});

const TxtPrimaryCareProvid3 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `30px`,
});

const ImgAnnaLopez = styled('img')({
  height: `40px`,
  width: `40px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `12px`,
  top: `16px`,
});

const TxtFamilyPractice3 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `48px`,
});

const GrpCal2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `219px`,
  top: `16px`,
});

const IconCalCircle3 = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpCalIcon2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `22px`,
  height: `24px`,
  left: `10px`,
  top: `9px`,
  overflow: `hidden`,
});

const IconCal1 = styled('img')({
  height: `24px`,
  width: `22px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpEmail2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `270px`,
  top: `16px`,
});

const IconEmailCircle3 = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const IconEmail3 = styled('img')({
  height: `15.21px`,
  width: `25.39px`,
  position: `absolute`,
  left: `8px`,
  top: `13px`,
});

const TxtCareTeamTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(45, 105, 139, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `16px`,
  top: `10px`,
});

const GrpExplore = styled('div')({
  backgroundColor: `rgba(208, 231, 246, 1)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `672px`,
  height: `215px`,
  left: `190px`,
  top: `633px`,
  overflow: `hidden`,
});

const GrpNextElipse = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `114px`,
  height: `10px`,
  left: `279px`,
  top: `189px`,
});

const ImgElipseBub4 = styled('img')({
  height: `10px`,
  width: `10px`,
  position: `absolute`,
  left: `104px`,
  top: `0px`,
});

const ImgElipseBub3 = styled('img')({
  height: `10px`,
  width: `10px`,
  position: `absolute`,
  left: `78px`,
  top: `0px`,
});

const ImgElipseBub2 = styled('img')({
  height: `10px`,
  width: `10px`,
  position: `absolute`,
  left: `52px`,
  top: `0px`,
});

const ImgElipseBubActive = styled('img')({
  height: `10px`,
  width: `10px`,
  position: `absolute`,
  left: `26px`,
  top: `0px`,
});

const ImgElipseBub1 = styled('img')({
  height: `10px`,
  width: `10px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpPlanTrip = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `410px`,
  height: `107px`,
  left: `566px`,
  top: `65px`,
});

const ImgPlanTripBox = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `6px`,
  width: `410px`,
  height: `107px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpPlanTripBtn = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `125px`,
  height: `36px`,
  left: `274px`,
  top: `56px`,
});

const BtnPLanTrip = styled('div')({
  backgroundColor: `rgba(78, 204, 112, 1)`,
  borderRadius: `6px`,
  width: `125px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtPlanTripBtn = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `8px`,
  top: `9px`,
});

const TxtPlanTripDesc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `10px`,
  textTransform: `none`,
  width: `189px`,
  position: `absolute`,
  left: `75px`,
  top: `41px`,
});

const TxtPlanTripTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(15, 133, 224, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `75px`,
  top: `11px`,
});

const Layer1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `34px`,
  height: `50px`,
  left: `20px`,
  top: `28px`,
  overflow: `hidden`,
});

const Vector = styled('img')({
  height: `50px`,
  width: `7.77px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Vector1 = styled('img')({
  height: `50px`,
  width: `23.31px`,
  position: `absolute`,
  left: `11px`,
  top: `0px`,
});

const GrpPriceEst = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `410px`,
  height: `107px`,
  left: `134px`,
  top: `65px`,
});

const IgGetEstBox = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `6px`,
  width: `410px`,
  height: `107px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpGetEstBtn = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `125px`,
  height: `36px`,
  left: `274px`,
  top: `56px`,
});

const BtnGetEst = styled('div')({
  backgroundColor: `rgba(78, 204, 112, 1)`,
  borderRadius: `6px`,
  width: `125px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtGetEstBtn = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `8px`,
  top: `9px`,
});

const TxtEstDesc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `14px`,
  textTransform: `none`,
  width: `189px`,
  position: `absolute`,
  left: `75px`,
  top: `41px`,
});

const TxtEstTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(15, 133, 224, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `75px`,
  top: `11px`,
});

const ImgCalcIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `57px`,
  left: `18px`,
  top: `24px`,
});

const ImgCalcBtn16 = styled('img')({
  height: `57px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgCalcBtn15 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `48px`,
});

const ImgCalcBtn14 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `41px`,
});

const ImgCalcBtn13 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `34px`,
});

const ImgCalcBtn12 = styled('img')({
  height: `4.64px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `27px`,
});

const ImgCalcBtn11 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `20px`,
});

const ImgCalcBtn10 = styled('img')({
  height: `4.63px`,
  width: `19.65px`,
  position: `absolute`,
  left: `17px`,
  top: `48px`,
});

const ImgCalcBtn9 = styled('img')({
  height: `4.63px`,
  width: `8.13px`,
  position: `absolute`,
  left: `17px`,
  top: `27px`,
});

const ImgCalcBtn8 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `17px`,
  top: `20px`,
});

const ImgCalcBtn7 = styled('img')({
  height: `4.63px`,
  width: `8.13px`,
  position: `absolute`,
  left: `17px`,
  top: `34px`,
});

const ImgCalcBtn6 = styled('img')({
  height: `4.63px`,
  width: `8.13px`,
  position: `absolute`,
  left: `17px`,
  top: `41px`,
});

const ImgCalcBtn5 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `28px`,
  top: `41px`,
});

const ImgCalcBtn4 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `28px`,
  top: `34px`,
});

const ImgCalcBtn3 = styled('img')({
  height: `4.64px`,
  width: `8.12px`,
  position: `absolute`,
  left: `28px`,
  top: `27px`,
});

const ImgCalcBtn2 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `28px`,
  top: `20px`,
});

const ImgCalcBtn1 = styled('img')({
  height: `11.88px`,
  width: `33.18px`,
  position: `absolute`,
  left: `4px`,
  top: `5px`,
});

const GrpPriceDocTalk = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `410px`,
  height: `107px`,
  left: `-298px`,
  top: `65px`,
});

const ImgDocTalkBox = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `6px`,
  width: `410px`,
  height: `107px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpDocTalkBtn = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `125px`,
  height: `36px`,
  left: `274px`,
  top: `56px`,
});

const ButtonStyle = styled('div')({
  backgroundColor: `rgba(78, 204, 112, 1)`,
  borderRadius: `6px`,
  width: `125px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Label = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `9px`,
  top: `9px`,
});

const TxtDocTalkDesc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(15, 133, 224, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `75px`,
  top: `11px`,
});

const TxtDocTalkTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `11px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `14px`,
  textTransform: `none`,
  width: `189px`,
  position: `absolute`,
  left: `75px`,
  top: `41px`,
});

const GrpCalcIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `57px`,
  left: `18px`,
  top: `24px`,
});

const ImgCalcBtn161 = styled('img')({
  height: `57px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgCalcBtn151 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `48px`,
});

const ImgCalcBtn141 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `41px`,
});

const ImgCalcBtn131 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `34px`,
});

const ImgCalcBtn121 = styled('img')({
  height: `4.64px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `27px`,
});

const ImgCalcBtn111 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `5px`,
  top: `20px`,
});

const ImgCalcBtn101 = styled('img')({
  height: `4.63px`,
  width: `19.65px`,
  position: `absolute`,
  left: `17px`,
  top: `48px`,
});

const ImgCalcBtn91 = styled('img')({
  height: `4.63px`,
  width: `8.13px`,
  position: `absolute`,
  left: `17px`,
  top: `27px`,
});

const ImgCalcBtn81 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `17px`,
  top: `20px`,
});

const ImgCalcBtn71 = styled('img')({
  height: `4.63px`,
  width: `8.13px`,
  position: `absolute`,
  left: `17px`,
  top: `34px`,
});

const ImgCalcBtn61 = styled('img')({
  height: `4.63px`,
  width: `8.13px`,
  position: `absolute`,
  left: `17px`,
  top: `41px`,
});

const ImgCalcBtn51 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `28px`,
  top: `41px`,
});

const ImgCalcBtn41 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `28px`,
  top: `34px`,
});

const ImgCalcBtn31 = styled('img')({
  height: `4.64px`,
  width: `8.12px`,
  position: `absolute`,
  left: `28px`,
  top: `27px`,
});

const ImgCalcBtn21 = styled('img')({
  height: `4.63px`,
  width: `8.12px`,
  position: `absolute`,
  left: `28px`,
  top: `20px`,
});

const ImgCalcBtn17 = styled('img')({
  height: `11.88px`,
  width: `33.18px`,
  position: `absolute`,
  left: `4px`,
  top: `5px`,
});

const GrpExplore1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `138px`,
  height: `35px`,
  left: `14px`,
  top: `8px`,
});

const TxtExploreTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(15, 133, 224, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `47px`,
  top: `8px`,
});

const GrpBulbIcon = styled('img')({
  height: `35px`,
  width: `34.18px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpViewLtr = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 0px 1px rgba(4, 14, 34, 0.01), 0px 0.5px 2px rgba(4, 14, 34, 0.03), 0px 0.5px 3px rgba(4, 14, 34, 0.04), 0px 1px 4px rgba(4, 14, 34, 0.06), 0px 1px 6px rgba(4, 14, 34, 0.08)`,
  borderRadius: `6px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `744px`,
  height: `55px`,
  left: `160px`,
  top: `546px`,
  overflow: `hidden`,
}));

const GrpViewLtrBtn = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `114px`,
  height: `36px`,
  left: `603px`,
  top: `8px`,
});

const BtnViewLtr = styled('div')({
  backgroundColor: `rgba(78, 204, 112, 1)`,
  borderRadius: `6px`,
  width: `114px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtViewLtr = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `19px`,
  top: `9px`,
});

const TxtViewLtrDesc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  width: `472px`,
  position: `absolute`,
  left: `55px`,
  top: `19px`,
});

const GrpViewLtrIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `32px`,
  height: `32px`,
  left: `11px`,
  top: `10px`,
  overflow: `hidden`,
});

const ImgEnvBotCtr = styled('img')({
  height: `7.94px`,
  width: `29.31px`,
  position: `absolute`,
  left: `1px`,
  top: `24px`,
});

const ImgEnvBotRgt = styled('img')({
  height: `16.27px`,
  width: `12.34px`,
  position: `absolute`,
  left: `20px`,
  top: `15px`,
});

const ImgEnvBotLft = styled('img')({
  height: `16.27px`,
  width: `12.34px`,
  position: `absolute`,
  left: `0px`,
  top: `15px`,
});

const ImgEnvIconContent = styled('img')({
  height: `20.58px`,
  width: `22.86px`,
  position: `absolute`,
  left: `5px`,
  top: `4px`,
});

const ImgEnvFoldRgt = styled('img')({
  height: `4.79px`,
  width: `3.06px`,
  position: `absolute`,
  left: `29px`,
  top: `11px`,
});

const ImgEnvFoldLft = styled('img')({
  height: `4.79px`,
  width: `3.06px`,
  position: `absolute`,
  left: `0px`,
  top: `11px`,
});

const ImgEnvFoldTop = styled('img')({
  height: `2.68px`,
  width: `5.93px`,
  position: `absolute`,
  left: `13px`,
  top: `0px`,
});

const GrpVirtualVisits = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 0px 1px rgba(4, 14, 34, 0.01), 0px 0.5px 2px rgba(4, 14, 34, 0.03), 0px 0.5px 3px rgba(4, 14, 34, 0.04), 0px 1px 4px rgba(4, 14, 34, 0.06), 0px 1px 6px rgba(4, 14, 34, 0.08)`,
  borderRadius: `6px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `744px`,
  height: `85px`,
  left: `160px`,
  top: `445px`,
  overflow: `hidden`,
}));

const GrpVisitGetStarted = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `114px`,
  height: `36px`,
  left: `603px`,
  top: `19px`,
  cursor: `pointer`,
});

const BtnVisitGetStarted = styled('div')({
  backgroundColor: `rgba(78, 204, 112, 1)`,
  borderRadius: `6px`,
  width: `114px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtVisitGetStarted = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `18px`,
  top: `9px`,
});

const TxtVisitDesc = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  width: `472px`,
  position: `absolute`,
  left: `61px`,
  top: `35px`,
});

const TxtVisitTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `61px`,
  top: `13px`,
});

const GrpVisitIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `34px`,
  height: `34px`,
  left: `16px`,
  top: `13px`,
  overflow: `hidden`,
});

const ImgVisitIcon2 = styled('img')({
  height: `34px`,
  width: `24.29px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgVisitIcon1 = styled('img')({
  height: `19.43px`,
  width: `21.87px`,
  position: `absolute`,
  left: `12px`,
  top: `0px`,
});

const GrpQuestionnaireHelp = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 0px 1px rgba(4, 14, 34, 0.01), 0px 0.5px 2px rgba(4, 14, 34, 0.03), 0px 0.5px 3px rgba(4, 14, 34, 0.04), 0px 1px 4px rgba(4, 14, 34, 0.06), 0px 1px 6px rgba(4, 14, 34, 0.08)`,
  borderRadius: `6px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `744px`,
  height: `120px`,
  left: `160px`,
  top: `309px`,
  overflow: `hidden`,
}));

const GrpViewAll = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `110.07px`,
  height: `24px`,
  left: `316px`,
  top: `92px`,
});

const TxtViewAll = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `36px`,
  top: `0px`,
});

const ImgViewAllIcon = styled('img')({
  height: `18px`,
  width: `24.53px`,
  position: `absolute`,
  left: `0px`,
  top: `3px`,
});

const ImgMsgHelpDivider = styled('div')({
  border: `1px solid rgba(222, 224, 221, 1)`,
  width: `744px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `87px`,
});

const GrpViewMsgBtn = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `114px`,
  height: `36px`,
  left: `603px`,
  top: `39px`,
});

const BtnViewMsg = styled('div')({
  backgroundColor: `rgba(78, 204, 112, 1)`,
  borderRadius: `6px`,
  width: `114px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtViewMsg = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `7px`,
  top: `9px`,
});

const GrpMsgBubble = styled('img')({
  height: `34px`,
  width: `443.5px`,
  position: `absolute`,
  left: `146px`,
  top: `39px`,
});

const TxtMsgContent = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `175px`,
  top: `44px`,
});

const ImgDrIcon = styled('img')({
  height: `35px`,
  width: `35px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `105px`,
  top: `40px`,
});

const TxtMsgName = styled('div')({
  textAlign: `right`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `11px`,
  textTransform: `none`,
  position: `absolute`,
  left: `38px`,
  top: `49px`,
});

const TxtMsgHelpTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `49px`,
  top: `7px`,
});

const ImgEnvIconBot = styled('img')({
  height: `22px`,
  width: `30.66px`,
  position: `absolute`,
  left: `13px`,
  top: `11px`,
});

const ImgEnvIconTop = styled('img')({
  height: `14px`,
  width: `28.62px`,
  position: `absolute`,
  left: `14px`,
  top: `9px`,
});

const GrpUpcomingVisit = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 0px 1px rgba(4, 14, 34, 0.01), 0px 0.5px 2px rgba(4, 14, 34, 0.03), 0px 0.5px 3px rgba(4, 14, 34, 0.04), 0px 1px 4px rgba(4, 14, 34, 0.06), 0px 1px 6px rgba(4, 14, 34, 0.08)`,
  borderRadius: `6px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `744px`,
  height: `124px`,
  left: `160px`,
  top: `169px`,
  overflow: `hidden`,
}));

const GrpViewDetails = styled('div')({
  border: `1px solid rgba(78, 204, 112, 1)`,
  boxSizing: `border-box`,
  borderRadius: `6px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px 10px`,
  width: `114px`,
  left: `603px`,
  top: `65px`,
  cursor: `pointer`,
});

const TxtViewDetails = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(78, 204, 112, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px`,
});

const GrpECheckIn = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `114px`,
  height: `36px`,
  left: `603px`,
  top: `25px`,
  cursor: `pointer`,
});

const BtnECheckIn = styled('div')({
  backgroundColor: `rgba(78, 204, 112, 1)`,
  borderRadius: `6px`,
  width: `114px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtECheckin = styled('div')({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  position: `absolute`,
  left: `20px`,
  top: `9px`,
});

const TxtDrName = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `164px`,
  top: `89px`,
});

const GrpPeopleIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `18px`,
  height: `18px`,
  left: `138px`,
  top: `93px`,
  overflow: `hidden`,
});

const ImgPeopleIcon = styled('img')({
  height: `10.5px`,
  width: `15px`,
  position: `absolute`,
  left: `2px`,
  top: `4px`,
});

const TxtVisitClinicName = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `164px`,
  top: `65px`,
});

const GrpHospIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `18px`,
  height: `18px`,
  left: `138px`,
  top: `68px`,
  overflow: `hidden`,
});

const ImgHospIcon = styled('img')({
  height: `13.5px`,
  width: `15.75px`,
  position: `absolute`,
  left: `1px`,
  top: `2px`,
});

const TxtStartTime = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `164px`,
  top: `40px`,
});

const GrpClockIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `18px`,
  height: `18px`,
  left: `138px`,
  top: `43px`,
  overflow: `hidden`,
});

const ImgClockIcon = styled('img')({
  height: `15px`,
  width: `15px`,
  position: `absolute`,
  left: `2px`,
  top: `2px`,
});

const TxtDay = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `79px`,
  top: `89px`,
});

const TxtDate = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `30px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `76px`,
  top: `64px`,
});

const TxtMonth = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `82px`,
  top: `39px`,
});

const TxtUpcomingOfficeVis = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `49px`,
  top: `10px`,
});

const GrpCalIcon3 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `29px`,
  height: `29px`,
  left: `15px`,
  top: `8px`,
  overflow: `hidden`,
});

const ImgCalBind2 = styled('img')({
  height: `6.04px`,
  width: `3.63px`,
  position: `absolute`,
  left: `21px`,
  top: `0px`,
});

const ImgCalBind1 = styled('img')({
  height: `6.04px`,
  width: `3.63px`,
  position: `absolute`,
  left: `5px`,
  top: `0px`,
});

const GrpCalPage = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `29px`,
  height: `25.98px`,
  left: `0px`,
  top: `3px`,
});

const ImgCalDays = styled('img')({
  height: `19.28px`,
  width: `29px`,
  position: `absolute`,
  left: `0px`,
  top: `7px`,
});

const ImgCalPageTop = styled('img')({
  height: `5.49px`,
  width: `29px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgCalSelection = styled('div')({
  backgroundColor: `rgba(255, 112, 121, 1)`,
  borderRadius: `0.800000011920929px`,
  width: `3.8px`,
  height: `3.8px`,
  position: `absolute`,
  left: `22px`,
  top: `23px`,
});

const TxtWelcomeTitle = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `22px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `160px`,
  top: `129px`,
});

const GrpnavigationBar = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(246, 246, 246, 1)`,
  boxShadow: `0px 0.5px 2px rgba(4, 14, 34, 0.01), 0px 1px 4px rgba(4, 14, 34, 0.03), 0px 2px 6px rgba(4, 14, 34, 0.04), 0px 3px 12px rgba(4, 14, 34, 0.06), 0px 4px 14px rgba(4, 14, 34, 0.08)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `60px`,
  left: `0px`,
  top: `57px`,
  overflow: `hidden`,
}));

const GrpUserBtn = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 0px 1px rgba(4, 14, 34, 0.01), 0px 0.5px 2px rgba(4, 14, 34, 0.03), 0px 0.5px 3px rgba(4, 14, 34, 0.04), 0px 1px 4px rgba(4, 14, 34, 0.06), 0px 1px 6px rgba(4, 14, 34, 0.08)`,
  borderRadius: `8px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `2px 8px`,
  boxSizing: `border-box`,
  left: `1197px`,
  top: `7px`,
  overflow: `hidden`,
}));

const ImgUser = styled('img')({
  height: `41px`,
  width: `41px`,
  objectFit: `cover`,
  margin: `0px`,
});

const TxtUserName = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px 0px 0px 3px`,
});

const ImgUserDownArrow = styled('img')({
  height: `6px`,
  width: `8px`,
  margin: `0px 0px 0px 3px`,
});

const GrpMedications = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `133px`,
  height: `42px`,
  left: `689px`,
  top: `9px`,
});

const GrpMedicationsIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `0px`,
  top: `0px`,
});

const ImgPillIconCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpPillIcon = styled('img')({
  height: `24.73px`,
  width: `25.65px`,
  position: `absolute`,
  left: `8px`,
  top: `9px`,
});

const TxtMedications = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `42px`,
  top: `9px`,
});

const GrpTestResults = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `131px`,
  height: `42px`,
  left: `534px`,
  top: `9px`,
});

const GrpTestResults1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `0px`,
  top: `0px`,
});

const ImgTestResultsCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpTestResultIcon = styled('img')({
  height: `22px`,
  width: `21px`,
  position: `absolute`,
  left: `11px`,
  top: `10px`,
});

const TxtTestResults = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `40px`,
  top: `9px`,
});

const GrpMessageCenter = styled('div')(({ theme }) => ({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `158px`,
  height: `42px`,
  left: `352px`,
  top: `9px`,
}));

const GrpMessageIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `0px`,
  top: `0px`,
});

const ImgMessageCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgMessageIcon = styled('img')({
  height: `18px`,
  width: `24px`,
  position: `absolute`,
  left: `9px`,
  top: `12px`,
});

const TxtMessageCenter = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `42px`,
  top: `9px`,
});

const GrpVisits = styled('div')(({ theme }) => ({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `86px`,
  height: `42px`,
  left: `242px`,
  top: `9px`,
}));

const GrpVisits1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `0px`,
  top: `0px`,
});

const ImgCalCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgCal2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `22px`,
  height: `24px`,
  left: `10px`,
  top: `9px`,
  overflow: `hidden`,
});

const ImgCalIcon = styled('img')({
  height: `24px`,
  width: `22px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtVisits = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `40px`,
  top: `10px`,
});

const GrpMenu = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `93px`,
  height: `45px`,
  left: `125px`,
  top: `8px`,
});

const GrpMenuIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `45px`,
  height: `45px`,
  left: `0px`,
  top: `0px`,
});

const ImgStripesCircle = styled('img')({
  height: `45px`,
  width: `45px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpIconStripes = styled('img')({
  height: `18px`,
  width: `22px`,
  position: `absolute`,
  left: `12px`,
  top: `13px`,
});

const TxtMenu = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `54px`,
  top: `10px`,
});

const GrpPageTitleBar = styled('div')({
  backgroundColor: `rgba(31, 97, 175, 1)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `57px`,
  left: `0px`,
  top: `0px`,
  overflow: `hidden`,
});

const GrpUnivIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `45px`,
  height: `35px`,
  left: `1178px`,
  top: `11px`,
});

const ImgUnitRect = styled('div')({
  opacity: `0.20000000298023224`,
  backgroundColor: `rgba(217, 217, 217, 1)`,
  borderRadius: `8px`,
  width: `45px`,
  height: `35px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgGlobe = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `22px`,
  height: `22px`,
  left: `6px`,
  top: `6px`,
  overflow: `hidden`,
});

const ImgGlobeCircle = styled('img')({
  height: `22px`,
  width: `22px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

/*
const ImgDownArrow = styled('img')({
  height: `6px`,
  width: `8px`,
  position: `absolute`,
  left: `47px`,
  top: `26px`,
});
*/

const BtnLogOut = styled('div')({
  backgroundColor: `rgba(217, 217, 217, 0.2)`,
  borderRadius: `8px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `5px 6px`,
  boxSizing: `border-box`,
  left: `1229px`,
  top: `11px`,
  overflow: `hidden`,
  cursor: `pointer`,
});

const GrpLogOut = styled('img')({
  height: `19px`,
  width: `22px`,
  margin: `0px`,
});

const TxtLogOut = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px 0px 0px 2px`,
});

const ImgMyChartTitle = styled('img')({
  height: `49px`,
  width: `149px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `646px`,
  top: `4px`,
});

const GrpEpicLogo = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `48px`,
  height: `52px`,
  left: `127px`,
  top: `5px`,
});

const ImgEpicLogo = styled('img')({
  height: `48px`,
  width: `48px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `4px`,
});

const TxtMyChartLogo = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `8px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `2px`,
  top: `0px`,
});