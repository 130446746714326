/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import LogosAmwellLogoCompaImage from 'src/assets/images/PatientPortaleCheckInStep3_Logos_Amwell___Logo_company.svg';
import VectorImage from 'src/assets/images/PatientPortaleCheckInStep3_Vector.svg';
import GenericPortalImage from 'src/assets/images/PatientPortaleCheckInStep3_GenericPortal.svg';
import LargePrimaryButtonImage from 'src/assets/images/PatientPortaleCheckInStep3_Large_Primary_Button.png';
import LargeSecondaryButtonImage from 'src/assets/images/PatientPortaleCheckInStep3_Large_Secondary_Button.png';
import Screenshot20230421AtImage from 'src/assets/images/PatientPortaleCheckInStep3_Screenshot_2023_04_21_at_12_38_1.png';
import { styled } from '@mui/material/styles';
import Menu from 'src/components/Menu/Menu';
import IntakeSteps3 from './IntakeSteps3';
import SelectAcceptChip from '../../components/Chip/SelectAcceptChip';
import UnselectDeclineChip from '../../components/Chip/UnselectDeclineChip';
import usePatientPortalECheckInStep3 from './usePatientPortalECheckInStep3';
import { getGlobal } from '../../globals';
import { getPatientList } from '../../modules/Utilities';

function PatientPortalECheckInStep3(props) {
  const { fns } = usePatientPortalECheckInStep3();
  const { isAuthenticated, user } = useAuth0();
  const [ strFirstName, setPatientName ] = useState("");

  useEffect(() => {
    const arrPatientList = getGlobal("gActiveUser");
    
    if(!arrPatientList) {
      getPatientList();
      return;
    }
    else {
      //We have a patient list, so go ahead and set the first patient name in the list
      setPatientName("Welcome, " + arrPatientList.given_name + "!");
    }
  }, [])

  function Welcome() {
    if(strFirstName === "") {
      getPatientList();
      return null;
    }

    const patientList = getGlobal("gActiveUser");

    const imgPatient = "data:image/png;base64," + patientList.photo;

    if(patientList.given_name === "" || patientList.photo === "") {
      return null;
    }

    return (
      <>
        <WelcomeTiana id='WelcomeLabel'>{strFirstName}</WelcomeTiana>
        <Ellipse1 id='WelcomeImage' src={imgPatient} loading="lazy" alt={'Ellipse 1'} />
      </>
    );
  }

  if (isAuthenticated) {
    if(!user.email_verified) {
      return ( 
        <>
          <div>
            <h3>Please verify your account before continuing</h3>
          </div>
        </>
      ) 
    }
    else {
      return (
        <PatientPortalECheckI className={props.className}>
          <Rectangle14></Rectangle14>
          <ECheckIn>{`eCheck-in`}</ECheckIn>
          <SubNav>
            <Rectangle4></Rectangle4>
            <Group6>
              <Rectangle12></Rectangle12>
              <Dashboard>{`Dashboard`}</Dashboard>
            </Group6>
            <Visits>{`Visits`}</Visits>
            <MessageCenter>{`Message Center`}</MessageCenter>
            <TestResults>{`Test Results`}</TestResults>
            <Medications>{`Medications`}</Medications>
          </SubNav>
          <Navigation>
            <Rectangle1></Rectangle1>
            <LogosAmwellLogoCompa
              src={LogosAmwellLogoCompaImage}
              loading="lazy"
              alt={'Logos/Amwell - Logo company'}
            />
            <Menu1 />
            <Group5>
              <Welcome />
            </Group5>
          </Navigation>
          <Group4 onClick={fns.onLogOut}>
            <Logout>{`Logout`}</Logout>
            <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
          </Group4>
          <GenericPortal
            src={GenericPortalImage}
            loading="lazy"
            alt={'GenericPortal'}
          />
          <Group26788>
            <Rectangle7></Rectangle7>
            <Rectangle15></Rectangle15>
            <Group26783>
              <Rectangle8></Rectangle8>
              <LargePrimaryButton
                onClick={fns.handleOnNext}
                src={LargePrimaryButtonImage}
                loading="lazy"
                alt={'Large Primary Button'}
              />
              <LargeSecondaryButton
                onClick={fns.handleOnFinishLater}
                src={LargeSecondaryButtonImage}
                loading="lazy"
                alt={'Large Secondary Button'}
              />
              <PleaseReviewSignYour>
                <PleaseReviewSignYourSpan1>{`Please review & sign  your here before process to the next step. `}</PleaseReviewSignYourSpan1>
                <PleaseReviewSignYourSpan2>{`Please call 911 if you have an emergency.`}</PleaseReviewSignYourSpan2>
              </PleaseReviewSignYour>
            </Group26783>
            <Frame12>
              <Intake />
            </Frame12>
            <Screenshot20230421At
              src={Screenshot20230421AtImage}
              loading="lazy"
              alt={'Screenshot 2023-04-21 at 12.38 1'}
            />
            <Rectangle5621></Rectangle5621>
            <Group26785>
              <Chip1 />
              <Chip2 />
            </Group26785>
          </Group26788>
        </PatientPortalECheckI>
      );
    }
  }
  else {       
    return (
        <>
          <br/>
          <h5>You're not currently Logged In.</h5>
          <h4>Please click the link above to log in.</h4>
          <br/>
        </>
    )     
  }
}

export default PatientPortalECheckInStep3;

const PatientPortalECheckI = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1024px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Rectangle14 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  width: `1440px`,
  height: `1058px`,
  position: `absolute`,
  left: `0px`,
  top: `70px`,
}));

const ECheckIn = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `32px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `194px`,
}));

const SubNav = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `62px`,
  left: `0px`,
  top: `70px`,
});

const Rectangle4 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 8px 36px rgba(0, 0, 0, 0.16)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `62px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group6 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `200px`,
  height: `42px`,
  left: `90px`,
  top: `20px`,
});

const Rectangle12 = styled('div')({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  width: `49px`,
  height: `6px`,
  position: `absolute`,
  left: `151px`,
  top: `36px`,
});

const Dashboard = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Visits = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `241px`,
  top: `20px`,
});

const MessageCenter = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `339px`,
  top: `20px`,
}));

const TestResults = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `536px`,
  top: `20px`,
}));

const Medications = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `693px`,
  top: `20px`,
}));

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LogosAmwellLogoCompa = styled('img')({
  height: `auto`,
  width: `160px`,
  position: `absolute`,
  left: `76px`,
  top: `19px`,
});

const Menu1 = styled(Menu)(({ theme }) => ({
  width: `24px`,
  height: `24px`,
  position: `absolute`,
  left: `28px`,
  top: `23px`,
}));

const Group5 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `210px`,
  height: `46px`,
  left: `1056px`,
  top: `12px`,
});

const WelcomeTiana = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  width: `455px`,
  left: `55px`,
  top: `11px`,
}));

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1316px`,
  top: `23px`,
});

const Logout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `39px`,
  top: `0px`,
  cursor: `pointer`,
}));

const Vector = styled('img')({
  height: `auto`,
  width: `24px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GenericPortal = styled('img')({
  height: `auto`,
  width: `155px`,
  position: `absolute`,
  left: `256px`,
  top: `23px`,
});

const Group26788 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1260px`,
  height: `819px`,
  left: `90px`,
  top: `239px`,
});

const Rectangle7 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `1260px`,
  height: `819px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Rectangle15 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `970px`,
  height: `489px`,
  position: `absolute`,
  left: `145px`,
  top: `144px`,
}));

const Group26783 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1196px`,
  height: `92px`,
  left: `32px`,
  top: `665px`,
});

const Rectangle8 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `1196px`,
  height: `92px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LargePrimaryButton = styled('img')({
  height: `48px`,
  width: `152px`,
  position: `absolute`,
  left: `1012px`,
  top: `22px`,
  cursor: `pointer`,
});

const LargeSecondaryButton = styled('img')({
  height: `48px`,
  width: `164px`,
  position: `absolute`,
  left: `832px`,
  top: `23px`,
  cursor: `pointer`,
});

const PleaseReviewSignYourSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const PleaseReviewSignYourSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(255, 120, 122, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const PleaseReviewSignYour = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `607px`,
  position: `absolute`,
  left: `31px`,
  top: `22px`,
});

const Frame12 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `720px`,
  left: `270px`,
  top: `32px`,
});

const Intake = styled(IntakeSteps3)(({ theme }) => ({
  margin: `0px`,
}));

const Screenshot20230421At = styled('img')({
  height: `489px`,
  width: `678px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `270px`,
  top: `144px`,
});

const Rectangle5621 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(240, 241, 242, 1)`,
  borderRadius: `24px 24px 0px 0px`,
  width: `340px`,
  height: `70px`,
  position: `absolute`,
  left: `439px`,
  top: `563px`,
}));

const Group26785 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `207px`,
  height: `32px`,
  left: `506px`,
  top: `588px`,
});

const Chip1 = styled(SelectAcceptChip)(({ theme }) => ({
  width: `99px`,
  height: `32px`,
  position: `absolute`,
  left: `108px`,
  top: `0px`,
}));

const Chip2 = styled(UnselectDeclineChip)(({ theme }) => ({
  width: `98px`,
  height: `32px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));
