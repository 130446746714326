import { useNavigate } from 'react-router-dom';

function IncorrectUserRole(props) {
    const navigate = useNavigate();

    return ( 
        <section className='AmEx__NA-Section'>
            <h3 className='AmEx__NA-h3'>Incorrect User Type</h3>
            <h4 className='AmEx__NA-h3'>Please log in as a user with access to the user type you've selected.</h4>
            <button className='AmEx__NA-Btn' onClick={() => navigate(-1)}>Go Back</button>
        </section>
    );
}

export default IncorrectUserRole;