/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { _CONFIGS } from '../../Config/index';
import { useNavigate } from 'react-router-dom';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const usePatientPortalECheckInStep5 = () => {
  const data = {};
  const navigate = useNavigate();

  const handleFinishLater = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep5: handleOnFinishLater Called"); 
    }
    navigate('/PatientPortalECheckIn');

  };
  const handleOnNext = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep5: handleOnNext Called"); 
    }
    navigate('/PatientPortalECheckInStep6');
  
  };

  const handleAddAllergies = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep5: handleAddAllergies Called"); 
    }
  
  };

  const handleLogOut = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep5: onLogOut Called"); 
    }
    navigate('/LogIn/PatientExperience');
  
  };

  const fns = {
    handleFinishLater,
    handleOnNext,
    handleAddAllergies,
    handleLogOut,
  };
  return { data, fns };
};

export default usePatientPortalECheckInStep5;
