//Use below for TEST tenant until AWSDD is fully working
export const _CONFIGS = {
    /*SE Sandbox*/
    tenant_id: "AWSDI",
    ehr_id: "STAGE-AWSDI-EPIC-1",
    //tenant_id: "AWSDD",
    //ehr_id: "DEMO-AWSDD-EPIC-1",
    /*SC Demo Converge tenant*/
    //tenant_id: "DEMO",
    //ehr_id: "DEMO-DEMO-SMARTHEALTHIT-1",
    //ehr_id: "PROD-DEMO-SMARTHEALTHIT-1",
    /*Test tenant launched to from Generic SMART Launcher*/
    //tenant_id: "TEST",
    //ehr_id: "epic",
    isDevMode: false,
    bShowAllPatients: true,
    encounterIdAppendix: "AVLVIS-ilwhoo8d2m-q2p6c092w4-x3r7fvffdq",
    provider_id: "a6dfe8e5-f65e-4eda-a572-850f7ac0d7cf",
    patient_id: "689892bd-dcbe-41fc-8651-38a1d0893853",
    mrn: "67cbf090-4ddb-4799-99ff-a28abe2740b1",
    patientListUrl: "https://sandbox2-amwell-solutions.onrender.com/fhir/Patient?identifier=",
    //patientListUrl: "https://sandbox.amwell.solutions/fhir/Patient?identifier=f",
    strUserInfoSub: "http://converge.amwell.com/undefined",
}

export const _VISUALS = {
    ORG_TITLE_ICON: "/images/Org_Title_Icon.png",
    COPY_ICON: "/images/copy-icon.svg",
    CHAT_ICON: "/images/Chat_Icon.jpg",
    TH_ICON: "/images/TH_Icon.jpg",
    FIND_PROV_ICON: "/images/Find_Prov_Icon.jpg",
    TH_MENU_IMG: "/images/TH_Menu_Img.png",
    TH_CARETM_ICON: "/images/TH_CareTm_Icon.png",
    TH_COMRS_ICON: "/images/TH_ComRs_Icon.png",
    TH_DASHBD_ICON: "/images/TH_Dashbd_Icon.png",
    TH_MYHR_ICON: "/images/TH_MyHR_Icon.png",
    TH_SCHED_ICON: "/images/TH_Sched_Icon.png",
    TH_SEARCH_ICON: "/images/TH_Search_Icon.png",
    TH_VV_ICON: "/images/TH_VV_Icon.png",
}
