/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preserve that ability, always export from Quest to regenerate this file.
 *   To setup element attributes and CSS properties, use the Quest web app
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useState, useEffect } from 'react';
import axios from "axios";
import { _URLS } from '../../Config/index';
import {setGlobal, getGlobal } from '../../globals';

//Imported visuals
import ImgHematologyDropDowImage from 'src/assets/images/ProviderPatientChart_imgHematologyDropDown.png';
import ImgProblemsDropDownVImage from 'src/assets/images/ProviderPatientChart_imgProblemsDropDownVector.png';
import ImgChemDropDownVectoImage from 'src/assets/images/ProviderPatientChart_imgChemDropDownVector.png';
import VideocamVectorImage from 'src/assets/images/ProviderPatientChart_videocam_vector.png';
import BtnSearchPatientImage from 'src/assets/images/ProviderPatientChart_btnSearchPatient.png';
import ImgProviderImage from 'src/assets/images/ProviderPatientChart_imgProvider.png';
import ImgLogoutImage from 'src/assets/images/ProviderPatientChart_imgLogout.png';
import OrgNameVectorImage from 'src/assets/images/ProviderPatientChart_OrgNameVector.png';
import OrgNameVector1Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_1.png';
import OrgNameVector2Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_2.png';
import OrgNameVector3Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_3.png';
import OrgNameVector4Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_4.png';
import OrgNameVector5Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_5.png';
import OrgNameVector6Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_6.png';
import OrgNameVector7Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_7.png';
import OrgNameVector8Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_8.png';
import OrgNameVector9Image from 'src/assets/images/ProviderPatientChart_OrgNameVector_9.png';
import OrgVectorImage from 'src/assets/images/ProviderPatientChart_OrgVector.png';
import OrgVector1Image from 'src/assets/images/ProviderPatientChart_OrgVector_1.png';
import OrgVector2Image from 'src/assets/images/ProviderPatientChart_OrgVector_2.png';
import OrgVector3Image from 'src/assets/images/ProviderPatientChart_OrgVector_3.png';
import OrgVector4Image from 'src/assets/images/ProviderPatientChart_OrgVector_4.png';
import OrgVector5Image from 'src/assets/images/ProviderPatientChart_OrgVector_5.png';
import OrgVector6Image from 'src/assets/images/ProviderPatientChart_OrgVector_6.png';
import OrgVector7Image from 'src/assets/images/ProviderPatientChart_OrgVector_7.png';
import OrgVector8Image from 'src/assets/images/ProviderPatientChart_OrgVector_8.png';
import MenuVectorImage from 'src/assets/images/ProviderPatientChart_menuVector.png';
import { styled } from '@mui/material/styles';
import useProviderPatientChart from './useProviderPatientChart';

function ProviderPatientChart(props) {
  const { fns } = useProviderPatientChart();
  const [ strFullName, setPatientName ] = useState("");
  const arrPatientList = getGlobal("gActiveUser");

  useEffect(() => {
    const arrPatientList = getGlobal("gActiveUser");
    
    if(!arrPatientList) {
      getPatientList();
      return;
    }
    else {
      //We have a patient list, so go ahead and set the first patient name in the list
      setPatientName(arrPatientList.given_name + " " + arrPatientList.family_name);
    }

  }, [])

  async function getPatientList() {
    const strEmrId = getGlobal("gEhrId");
    const patientDetails = await axios.get(_URLS.patientListUrl + strEmrId)
      .catch((e) => {
        console.log("ProviderEmrMessageVideoVisit.getPatientList: Unable to get patient list from " + _URLS.patientListUrl  + strEmrId);
        console.log(e);
        return;
      });

      if(patientDetails) {
        setGlobal({gActiveUser: patientDetails});
        setPatientName(patientDetails.given_name + " " + patientDetails.family_name);
      }
      else {
        setPatientName("No Patients Available");
      }
  }

  var today = new Date();
  var DOB = new Date(arrPatientList.birth_date);
  var age_now = today.getFullYear() - DOB.getFullYear();
  
  return (
    <ProviderPatientChart1 className={props.className}>
      <MedHistoryRightPanel></MedHistoryRightPanel>
      <HematologyPanel>
        <HematologyPanelOutli></HematologyPanelOutli>
        <TxtHematologyDates>
          {`02/15/17\n06:00\n\n02/15/17\n06:00\n\n02/15/17\n06:00\n\n02/15/17\n06:00\n\n02/15/17\n06:00\n\n`}
        </TxtHematologyDates>
        <TxtHematologyDatesTi>{`View all`}</TxtHematologyDatesTi>
        <TxtHematologyResults>
          {`Hgb`}
        </TxtHematologyResults>
        <TxtHematologyResults1>{`Test Results`}</TxtHematologyResults1>
        <ImgHematologyDropDow
          src={ImgHematologyDropDowImage}
          loading="lazy"
          alt={'imgHematologyDropDown'}
        />
        <HematologySeperator></HematologySeperator>
        <TxtHematologyTitle>{`Blood Bank`}</TxtHematologyTitle>
      </HematologyPanel>
      <Problems>
        <ProblemsOutline></ProblemsOutline>
        <TxtLastTakenDate>
          {`02/15/17\n06:00\n\n02/15/17\n06:00\n\n02/15/17\n06:00\n\n02/15/17\n06:00\n\n02/15/17\n06:00`}
        </TxtLastTakenDate>
        <TxtLastTakenTitle>{`Last Taken`}</TxtLastTakenTitle>
        <TxtMedsName>
          {`Lisinopril 5mg tablet\n(5 mg PO QDAY #30 tab)\n\nGlyburide 2.5 mg tablet\n(2.5 mg PO QDAY #30 tab)\n\nSt. John’s wort 150 mg capsule\n(300 mg PO QDAY #30 cap)\n\nRosuvastatin\n(20 mg PO QDAY)\n\nAspirin \n(81 mg PO QDAY)`}
        </TxtMedsName>
        <TxtMedsTitle>{`Medications`}</TxtMedsTitle>
        <ImgProblemsDropDownV
          src={ImgProblemsDropDownVImage}
          loading="lazy"
          alt={'imgProblemsDropDownVector'}
        />
        <ProblemsTitleSeperat></ProblemsTitleSeperat>
        <TxtProblemTitle>{`Problem List`}</TxtProblemTitle>
      </Problems>
      <LabResults>
        <LabResultsOutline></LabResultsOutline>
        <ChemPanel>
          <ChecmResultsOutline></ChecmResultsOutline>
          <TxtChemOrder>
            {`BP Systolic\nBP Diastolic\nPulse Rate\nPulse Rhythm\nResp Rate\nWeight\nBMI\nBody Temperature\nOxygen Saturation\nAuscul Heart\nExercise\nSmok Hx PPD\nAlcohol Use\nSodium\nPotassium\nChloride\nCo2`}
          </TxtChemOrder>
          <TxtChemResults>
            {`122\n84\n66\nRegular\n84\n210\nN/A\n98.6°F\n97%\nS1, S2, no murmur, rub or gallop\n1\n1/2\n141\n4.7\n101\n23\n9.3`}
          </TxtChemResults>
          <TxtChemDate>
            {`02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017\n02 /15/ 2017`}
          </TxtChemDate>
          <TxtChemTitle>{`Clinical Vitals`}</TxtChemTitle>
          <ChemResultsSeperator></ChemResultsSeperator>
          <ChemDropDown>
            <ImgChemDropDownVecto
              src={ImgChemDropDownVectoImage}
              loading="lazy"
              alt={'imgChemDropDownVector'}
            />
          </ChemDropDown>
        </ChemPanel>
        <LabResultsSeperator></LabResultsSeperator>
        <TxtFormTypeTitle>
          <TxtFormTypeTitleSpan1>{`Flowsheet `}</TxtFormTypeTitleSpan1>
          <TxtFormTypeTitleSpan2>{`(Enterprise/Medicine/Cardiology/Cardiac)`}</TxtFormTypeTitleSpan2>
        </TxtFormTypeTitle>
      </LabResults>
      <NavigationMenu>
        <NavigationMenuOutlin></NavigationMenuOutlin>
        <TxtNavNotes>{`Notes`}</TxtNavNotes>
        <TxtNavFlowsheet>{`Flowsheet`}</TxtNavFlowsheet>
        <TxtNavPatientDocs>{`Patient Docs`}</TxtNavPatientDocs>
        <TxtNavGrowthChart>{`Growth Chart`}</TxtNavGrowthChart>
        <TxtNavMedications>{`Medications`}</TxtNavMedications>
        <TxtNavOrders>{`Orders`}</TxtNavOrders>
        <TxtNavProblems>{`Problems`}</TxtNavProblems>
        <TxtNavMedicalSummary>{`Medical Summary`}</TxtNavMedicalSummary>
      </NavigationMenu>
      <ActiveMenuSelection></ActiveMenuSelection>
      <Demographics>
        <ChartHeader>
          <ChartHeaderOutline></ChartHeaderOutline>
          <ChartHeaderGroup>
            <TxtDemographics5>
              {arrPatientList.phone + "\nBlue Cross Blue Shield MA"}
            </TxtDemographics5>
            <TxtDemographics4>
              {`Phone Number:\nInsurance:`}
            </TxtDemographics4>
            <TxtDemographics3>
              {`5.8”\n160 lbs`}
            </TxtDemographics3>
            <TxtDemographics2>
              {`Height:\nWeight:`}
            </TxtDemographics2>
            <TxtDemographics1>
              <TxtDemographics1Span1>{age_now +" Year(s), " + arrPatientList.gender + ", DOB: " + arrPatientList.birth_date +"\nPatient ID: "}</TxtDemographics1Span1>
              <TxtDemographics1Span2>{arrPatientList.mrn}</TxtDemographics1Span2>
            </TxtDemographics1>
            <BtnVideoConnect onClick={fns.onConnectVideo}>
              <ContentVideoConnect>
                <VideocamOutline>
                  <VideocamVector
                    src={VideocamVectorImage}
                    loading="lazy"
                    alt={'videocam_vector'}
                  />
                </VideocamOutline>
                <TxtVideoConnect>{`Connect to Video`}</TxtVideoConnect>
              </ContentVideoConnect>
            </BtnVideoConnect>
            <PatientName>{strFullName}</PatientName>
            <ImgPatient
              src={"data:image/png;base64," + arrPatientList.photo}
              loading="lazy"
              alt={'imgPatient'}
            />
          </ChartHeaderGroup>
        </ChartHeader>
      </Demographics>
      <SearchPatientGroup>
        <Sidebareoutline></Sidebareoutline>
        <TxtSearchPatient>{`Search Patient`}</TxtSearchPatient>
        <BtnSearchPatient
          src={BtnSearchPatientImage}
          loading="lazy"
          alt={'btnSearchPatient'}
        />
      </SearchPatientGroup>
      <Navigation>
        <NavigateOutline></NavigateOutline>
        <ImgProvider
          src={ImgProviderImage}
          loading="lazy"
          alt={'imgProvider'}
        />
        <LogoutGroup onClick={fns.onLogout}>
          <TxtLogout>{`Logout`}</TxtLogout>
          <ImgLogout src={ImgLogoutImage} loading="lazy" alt={'imgLogout'} />
        </LogoutGroup>
        <TxtEmrLogo>
          <TxtEmrLogoSpan1>{`Generic`}</TxtEmrLogoSpan1>
          <TxtEmrLogoSpan2>{`EMR`}</TxtEmrLogoSpan2>
        </TxtEmrLogo>
        <NavigationSeperator></NavigationSeperator>
        <OrgLogo>
          <OrgFrame>
            <OrgNameGroup>
              <OrgNameVector
                src={OrgNameVectorImage}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector1
                src={OrgNameVector1Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector2
                src={OrgNameVector2Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector3
                src={OrgNameVector3Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector4
                src={OrgNameVector4Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector5
                src={OrgNameVector5Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector6
                src={OrgNameVector6Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector7
                src={OrgNameVector7Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector8
                src={OrgNameVector8Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
              <OrgNameVector9
                src={OrgNameVector9Image}
                loading="lazy"
                alt={'OrgNameVector'}
              />
            </OrgNameGroup>
            <OrgVector src={OrgVectorImage} loading="lazy" alt={'OrgVector'} />
            <OrgVector1
              src={OrgVector1Image}
              loading="lazy"
              alt={'OrgVector'}
            />
            <OrgVector2
              src={OrgVector2Image}
              loading="lazy"
              alt={'OrgVector'}
            />
            <OrgVector3
              src={OrgVector3Image}
              loading="lazy"
              alt={'OrgVector'}
            />
            <OrgVector4
              src={OrgVector4Image}
              loading="lazy"
              alt={'OrgVector'}
            />
            <OrgVector5
              src={OrgVector5Image}
              loading="lazy"
              alt={'OrgVector'}
            />
            <OrgVector6
              src={OrgVector6Image}
              loading="lazy"
              alt={'OrgVector'}
            />
            <OrgVector7
              src={OrgVector7Image}
              loading="lazy"
              alt={'OrgVector'}
            />
            <OrgVector8
              src={OrgVector8Image}
              loading="lazy"
              alt={'OrgVector'}
            />
          </OrgFrame>
        </OrgLogo>
        <Menu>
          <MenuVector src={MenuVectorImage} loading="lazy" alt={'menuVector'} />
        </Menu>
      </Navigation>
    </ProviderPatientChart1>
  );
}

export default ProviderPatientChart;


const ProviderPatientChart1 = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1200px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const MedHistoryRightPanel = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `520px`,
  height: `725px`,
  position: `absolute`,
  left: `890px`,
  top: `324px`,
}));

const HematologyPanel = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `429px`,
  left: `910px`,
  top: `780px`,
});

const HematologyPanelOutli = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `411px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const TxtHematologyDates = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `352px`,
  top: `109px`,
}));

const TxtHematologyDatesTi = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `352px`,
  top: `69px`,
}));

const TxtHematologyResults = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `109px`,
}));

const TxtHematologyResults1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `69px`,
}));

const ImgHematologyDropDow = styled('img')({
  height: `7.12px`,
  width: `15px`,
  position: `absolute`,
  left: `445px`,
  top: `19px`,
});

const HematologySeperator = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const TxtHematologyTitle = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `14px`,
}));

const Problems = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `429px`,
  left: `910px`,
  top: `341px`,
});

const ProblemsOutline = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `411px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const TxtLastTakenDate = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `352px`,
  top: `109px`,
}));

const TxtLastTakenTitle = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `352px`,
  top: `69px`,
}));

const TxtMedsName = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `109px`,
}));

const TxtMedsTitle = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `69px`,
}));

const ImgProblemsDropDownV = styled('img')({
  height: `7.12px`,
  width: `15px`,
  position: `absolute`,
  left: `445px`,
  top: `21px`,
});

const ProblemsTitleSeperat = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const TxtProblemTitle = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `14px`,
}));

const LabResults = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `710px`,
  height: `757px`,
  left: `150px`,
  top: `326px`,
});

const LabResultsOutline = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `710px`,
  height: `725px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const ChemPanel = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `650px`,
  height: `647px`,
  left: `30px`,
  top: `110px`,
});

const ChecmResultsOutline = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `650px`,
  height: `614px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const TxtChemOrder = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `32px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `71px`,
}));

const TxtChemResults = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `32px`,
  textTransform: `none`,
  position: `absolute`,
  left: `218px`,
  top: `71px`,
}));

const TxtChemDate = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `32px`,
  textTransform: `none`,
  position: `absolute`,
  left: `509px`,
  top: `71px`,
}));

const TxtChemTitle = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `13px`,
}));

const ChemResultsSeperator = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `650px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const ChemDropDown = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `15px`,
  height: `8px`,
  left: `605px`,
  top: `21px`,
  overflow: `hidden`,
});

const ImgChemDropDownVecto = styled('img')({
  height: `8px`,
  width: `15px`,
  position: `absolute`,
  left: `0px`,
  top: `1px`,
});

const LabResultsSeperator = styled('div')({
  border: `1px solid rgba(190, 195, 204, 1)`,
  width: `710px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `84px`,
});

const TxtFormTypeTitleSpan1 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `20px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
}));

const TxtFormTypeTitleSpan2 = styled('span')(({ theme }) => ({
  whiteSpace: `pre-wrap`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
}));

const TxtFormTypeTitle = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `nullpx`,
  letterSpacing: `NaNpx`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `30px`,
  top: `30px`,
}));

const NavigationMenu = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1320px`,
  height: `62px`,
  left: `120px`,
  top: `244px`,
});

const NavigationMenuOutlin = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 8px 36px rgba(0, 0, 0, 0.16)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1320px`,
  height: `62px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const TxtNavNotes = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `1128px`,
  top: `19px`,
}));

const TxtNavFlowsheet = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `987px`,
  top: `19px`,
}));

const TxtNavPatientDocs = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `824px`,
  top: `19px`,
}));

const TxtNavGrowthChart = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `652px`,
  top: `19px`,
}));

const TxtNavMedications = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `491px`,
  top: `19px`,
}));

const TxtNavOrders = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `381px`,
  top: `19px`,
}));

const TxtNavProblems = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `246px`,
  top: `19px`,
}));

const TxtNavMedicalSummary = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `30px`,
  top: `19px`,
}));

const ActiveMenuSelection = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  width: `168px`,
  height: `6px`,
  position: `absolute`,
  left: `150px`,
  top: `300px`,
}));

const Demographics = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1320px`,
  height: `174px`,
  left: `120px`,
  top: `70px`,
});

const ChartHeader = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1320px`,
  height: `174px`,
  left: `0px`,
  top: `0px`,
});

const ChartHeaderOutline = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1320px`,
  height: `174px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ChartHeaderGroup = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1030px`,
  height: `120px`,
  left: `30px`,
  top: `27px`,
});

const TxtDemographics5 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(26, 31, 36, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `835px`,
  top: `53px`,
}));

const TxtDemographics4 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `696px`,
  top: `52px`,
}));

const TxtDemographics3 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(26, 31, 36, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `580px`,
  top: `52px`,
}));

const TxtDemographics2 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `504px`,
  top: `52px`,
}));

const TxtDemographics1Span1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const TxtDemographics1Span2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(71, 126, 240, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const TxtDemographics1 = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `150px`,
  top: `55px`,
});

const BtnVideoConnect = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  borderRadius: `8px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `0px`,
  boxSizing: `border-box`,
  left: `345px`,
  top: `0px`,
  cursor: `pointer`,
}));

const ContentVideoConnect = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `2px 16px`,
  boxSizing: `border-box`,
  margin: `0px`,
});

const VideocamOutline = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px`,
  overflow: `hidden`,
});

const VideocamVector = styled('img')({
  height: `12px`,
  width: `18px`,
  position: `absolute`,
  left: `3px`,
  top: `6px`,
});

const TxtVideoConnect = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `700`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  textTransform: `none`,
  margin: `0px 0px 0px 4px`,
}));

const PatientName = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `600`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `150px`,
  top: `0px`,
}));

const ImgPatient = styled('img')({
  height: `120px`,
  width: `120px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const SearchPatientGroup = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `120px`,
  height: `931px`,
  left: `0px`,
  top: `70px`,
});

const Sidebareoutline = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `120px`,
  height: `931px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const TxtSearchPatient = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Open Sans`,
  fontWeight: `400`,
  fontSize: `12px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `16px`,
  top: `97px`,
});

const BtnSearchPatient = styled('img')({
  height: `48px`,
  width: `47.02px`,
  position: `absolute`,
  left: `36px`,
  top: `41px`,
});

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const NavigateOutline = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const ImgProvider = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `1369px`,
  top: `12px`,
});

const LogoutGroup = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1259px`,
  top: `23px`,
  cursor: `pointer`,
});

const TxtLogout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `39px`,
  top: `0px`,
}));

const ImgLogout = styled('img')({
  height: `24px`,
  width: `24px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtEmrLogoSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `300`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
});

const TxtEmrLogoSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `800`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
});

const TxtEmrLogo = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
  position: `absolute`,
  left: `258px`,
  top: `17px`,
});

const NavigationSeperator = styled('div')({
  border: `1px solid rgba(190, 195, 204, 1)`,
  width: `26px`,
  height: `0px`,
  position: `absolute`,
  left: `259px`,
  top: `35px`,
});

const OrgLogo = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 0)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `160px`,
  height: `32px`,
  left: `76px`,
  top: `19px`,
  overflow: `hidden`,
});

const OrgFrame = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `159.32px`,
  height: `26.5px`,
  left: `0px`,
  top: `3px`,
  overflow: `hidden`,
});

const OrgNameGroup = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `126.96px`,
  height: `16.39px`,
  left: `32px`,
  top: `5px`,
});

const OrgNameVector = styled('img')({
  height: `12.58px`,
  width: `12.27px`,
  position: `absolute`,
  left: `115px`,
  top: `4px`,
});

const OrgNameVector1 = styled('img')({
  height: `12.34px`,
  width: `7.58px`,
  position: `absolute`,
  left: `106px`,
  top: `4px`,
});

const OrgNameVector2 = styled('img')({
  height: `12.58px`,
  width: `12.91px`,
  position: `absolute`,
  left: `90px`,
  top: `4px`,
});

const OrgNameVector3 = styled('img')({
  height: `15.65px`,
  width: `15.04px`,
  position: `absolute`,
  left: `74px`,
  top: `1px`,
});

const OrgNameVector4 = styled('img')({
  height: `16.21px`,
  width: `12.17px`,
  position: `absolute`,
  left: `60px`,
  top: `0px`,
});

const OrgNameVector5 = styled('img')({
  height: `15.2px`,
  width: `7.72px`,
  position: `absolute`,
  left: `50px`,
  top: `1px`,
});

const OrgNameVector6 = styled('img')({
  height: `16.21px`,
  width: `3.75px`,
  position: `absolute`,
  left: `44px`,
  top: `0px`,
});

const OrgNameVector7 = styled('img')({
  height: `12.58px`,
  width: `12.91px`,
  position: `absolute`,
  left: `29px`,
  top: `4px`,
});

const OrgNameVector8 = styled('img')({
  height: `12.58px`,
  width: `12.27px`,
  position: `absolute`,
  left: `15px`,
  top: `4px`,
});

const OrgNameVector9 = styled('img')({
  height: `15.38px`,
  width: `13.33px`,
  position: `absolute`,
  left: `0px`,
  top: `1px`,
});

const OrgVector = styled('img')({
  height: `11.24px`,
  width: `15.57px`,
  position: `absolute`,
  left: `11px`,
  top: `8px`,
});

const OrgVector1 = styled('img')({
  height: `11.24px`,
  width: `15.56px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const OrgVector2 = styled('img')({
  height: `15.11px`,
  width: `11.24px`,
  position: `absolute`,
  left: `8px`,
  top: `11px`,
});

const OrgVector3 = styled('img')({
  height: `1.72px`,
  width: `2.85px`,
  position: `absolute`,
  left: `16px`,
  top: `11px`,
});

const OrgVector4 = styled('img')({
  height: `16.01px`,
  width: `11.24px`,
  position: `absolute`,
  left: `8px`,
  top: `0px`,
});

const OrgVector5 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `16px`,
  top: `6px`,
});

const OrgVector6 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `22px`,
  top: `19px`,
});

const OrgVector7 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `8px`,
  top: `14px`,
});

const OrgVector8 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `8px`,
  top: `22px`,
});

const Menu = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `28px`,
  top: `23px`,
  overflow: `hidden`,
});

const MenuVector = styled('img')({
  height: `12px`,
  width: `18px`,
  position: `absolute`,
  left: `3px`,
  top: `6px`,
});