import { useAuth0 } from '@auth0/auth0-react';

import LoginScreen from './Login';

const Home = () => {
  const { isAuthenticated } = useAuth0(); 
  
  if (isAuthenticated) {
    return (
      <section className='section'>
        <br/>
        <LoginScreen />
      </section>
    );
  }
  else {
    return (
      <section className='section'>
        <br/>
        <h5>You're not currently Logged In.</h5>
        <h4>Please click the link above to log in.</h4>
      </section>
    );
  }
};
export default Home;
