//Use for AWSDD Tenant (Prod)
export const _URLS = {
    AWV: "https://amwell.com/landing.htm",
    AWN: "https://demo.amwell.solutions",
    //oc_url: "https://cvg01.auth.cvg01.amwell.systems",
    oc_url: "https://stg01.auth.stg01.amwell.systems",
    iss_url: "https://launcher.americanwell.com/ehr/iss",
    //iss_url: "https://sandbox.amwell.solutions",
    SHORT_LINK_API: "https://api.shrtco.de/v2/shorten?url=",
    DECODE_SHORT_LINK_API: "https://api.shrtco.de/v2/info?code=",
    LAUNCH_TYPE: "/app/",
    PROVIDER_USER: "/practitioner/launch?iss=",
    PATIENT_USER: "/patient/launch?iss=",
    LAUNCH_CMD: "&launch=",
    //All with f in MRN 
    //patientListUrl: "https://sandbox.amwell.solutions/fhir/Patient?identifier=f",
    //Shawn Freeman
    //patientListUrl: "https://sandbox2.amwell.solutions/fhir/Patient?identifier=97738414703",
    //URL to pull all patients in the SE sandbox "EMR"
    patientListUrl: "https://sandbox2.amwell.solutions/get_all_patients",
    patientSearchUrl: "https://sandbox2-amwell-solutions.onrender.com/fhir/Patient?identifier=",
    //Emilee Lane
    //patientListUrl: "https://sandbox.amwell.solutions/fhir/Patient?identifier=877234922",
    AWForCliniciansUrl: "https://americanwellforclinicians.com/hub/provider",
    ConvergeUrl: "https://amg-consumer-client.cvg01.amwell.systems/auth0?tenant=AWSDI",
    CarePointCallingUrl: "https://cvg01.auth.cvg01.amwell.systems/app/CPTDD/smarthealthit/practitioner/launch?iss=https%3A%2F%2Flaunch.smarthealthit.org%2Fv%2Fr4%2Ffhir&launch=WzAsIjY3Y2JmMDkwLTRkZGItNDc5OS05OWZmLWEyOGFiZTI3NDBiMSIsImYyNTZkM2JhLWJiNzAtNDYxMy1hNjMxLTgyNWQ1MDBjNTdmYSIsIkFVVE8iLDAsMCwwLCIiLCIiLCIiLCIiLCIiLCIiLCIiLDAsMV0",
    ProviderDashboardUrl: "https://amg-provider-client.cvg01.amwell.systems/DEMO/dashboard",
    AUTH0_APIS: 'https://amwellidpmocks.amwell-dev.auth0.com/userinfo',
    AUTH0_AUDIENCE: 'https://api.cvg01.amwell.systems/',
}

/*
//Use below for test tenant until AWSDD is fully working
export const _URLS = {
    AWV: "https://amwell.com/landing.htm",
    AWN: "https://demo.amwell.solutions",
    oc_url: "https://cvg01.auth.cvg01.amwell.systems",
    iss_url: "https://launch.smarthealthit.org/v/r4/fhir" //SMART Health IT iss server to take advantage of their FHIR server for callbacks (auto populate patient details)
    //iss_url: "https://launcher.americanwell.com/ehr/iss" //Amwell iss server
}
*/

/*
https://cvg01.auth.cvg01.amwell.systems/app/CPTDD/smarthealthit/practitioner/launch?iss=https%3A%2F%2Flaunch.smarthealthit.org%2Fv%2Fr4%2Ffhir&launch=WzAsIjY3Y2JmMDkwLTRkZGItNDc5OS05OWZmLWEyOGFiZTI3NDBiMSIsImYyNTZkM2JhLWJiNzAtNDYxMy1hNjMxLTgyNWQ1MDBjNTdmYSIsIkFVVE8iLDAsMCwwLCIiLCIiLCIiLCIiLCIiLCIiLCIiLDAsMV0

https://cvg01.auth.cvg01.amwell.systems/
app/
CPTDD/
smarthealthit/practitioner/
launch?

iss=https%3A%2F%2Flaunch.smarthealthit.org%2Fv%2Fr4%2Ffhir&launch=WzAsIjY3Y2JmMDkwLTRkZGItNDc5OS05OWZmLWEyOGFiZTI3NDBiMSIsImYyNTZkM2JhLWJiNzAtNDYxMy1hNjMxLTgyNWQ1MDBjNTdmYSIsIkFVVE8iLDAsMCwwLCIiLCIiLCIiLCIiLCIiLCIiLCIiLDAsMV0
*/