/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import LargePrimaryButtonImage from 'src/assets/images/PatientPortaleCheckInStep6_Large_Primary_Button.png';
import LargeSecondaryButtonImage from 'src/assets/images/PatientPortaleCheckInStep6_Large_Secondary_Button.png';
import LogosAmwellLogoCompaImage from 'src/assets/images/PatientPortaleCheckInStep6_Logos_Amwell___Logo_company.svg';
import VectorImage from 'src/assets/images/PatientPortaleCheckInStep6_Vector.svg';
import GenericPortalImage from 'src/assets/images/PatientPortaleCheckInStep6_GenericPortal.svg';
import MediumSecondaryButtoImage from 'src/assets/images/PatientPortaleCheckInStep6_Medium_Secondary_Button.png';
import Ellipse652Image from 'src/assets/images/PatientPortaleCheckInStep6_Ellipse_652.png';
import MaskGroupImage from 'src/assets/images/PatientPortaleCheckInStep6_Mask_Group.png';
import Rectangle5617Image from 'src/assets/images/PatientPortaleCheckInStep6_Rectangle_5617.png';
import Rectangle5620Image from 'src/assets/images/PatientPortaleCheckInStep6_Rectangle_5620.png';
import Vector1Image from 'src/assets/images/PatientPortaleCheckInStep6_Vector.png';
import Vector2Image from 'src/assets/images/PatientPortaleCheckInStep6_Vector_1.png';
import Rectangle5619Image from 'src/assets/images/PatientPortaleCheckInStep6_Rectangle_5619.png';
import Ellipse654Image from 'src/assets/images/PatientPortaleCheckInStep6_Ellipse_654.png';
import Ellipse655Image from 'src/assets/images/PatientPortaleCheckInStep6_Ellipse_655.png';
import Ellipse656Image from 'src/assets/images/PatientPortaleCheckInStep6_Ellipse_656.png';
import Ellipse658Image from 'src/assets/images/PatientPortaleCheckInStep6_Ellipse_658.png';
import Path25916Image from 'src/assets/images/PatientPortaleCheckInStep6_Path_25916.png';
import { styled } from '@mui/material/styles';
import IntakeSteps6 from './IntakeSteps6';
import Menu from 'src/components/Menu/Menu';
import usePatientPortalECheckInStep6 from './usePatientPortalECheckInStep6';
import { getGlobal } from '../../globals';
import { getPatientList } from '../../modules/Utilities';

function PatientPortalECheckInStep6(props) {
  const { fns } = usePatientPortalECheckInStep6();
  const { isAuthenticated, user } = useAuth0();
  const [ strFirstName, setPatientName ] = useState("");

  useEffect(() => {
    const arrPatientList = getGlobal("gActiveUser");
    
    if(!arrPatientList) {
      getPatientList();
      return;
    }
    else {
      //We have a patient list, so go ahead and set the first patient name in the list
      setPatientName("Welcome, " + arrPatientList.given_name + "!");
    }
  }, [])

  function Welcome() {
    if(strFirstName === "") {
      getPatientList();
      return null;
    }

    const patientList = getGlobal("gActiveUser");

    const imgPatient = "data:image/png;base64," + patientList.photo;

    if(patientList.given_name === "" || patientList.photo === "") {
      return null;
    }

    return (
      <>
        <WelcomeTiana id='WelcomeLabel'>{strFirstName}</WelcomeTiana>
        <Ellipse1 id='WelcomeImage' src={imgPatient} loading="lazy" alt={'Ellipse 1'} />
      </>
    );
  }

  if (isAuthenticated) {
    if(!user.email_verified) {
      return ( 
        <>
          <div>
            <h3>Please verify your account before continuing</h3>
          </div>
        </>
      ) 
    }
    else {
      return (
        <PatientPortalECheckI className={props.className}>
          <Rectangle14></Rectangle14>
          <Rectangle7></Rectangle7>
          <Group26783>
            <Rectangle8></Rectangle8>
            <LargePrimaryButton
              onClick={fns.handleOnSubmit}
              src={LargePrimaryButtonImage}
              loading="lazy"
              alt={'Large Primary Button'}
            />
            <LargeSecondaryButton
              onClick={fns.handleFinishLater}
              src={LargeSecondaryButtonImage}
              loading="lazy"
              alt={'Large Secondary Button'}
            />
            <PleaseReviewYourHeal>
              <PleaseReviewYourHealSpan1>{`Please review your health issues and make sure the list is up-to-date. To edit an existing health issue, please delete the health issue then add it back with the necessary changes. `}</PleaseReviewYourHealSpan1>
              <PleaseReviewYourHealSpan2>{`Please call 911 if you have an emergency.`}</PleaseReviewYourHealSpan2>
            </PleaseReviewYourHeal>
          </Group26783>
          <ECheckIn>{`eCheck-in`}</ECheckIn>
          <Frame12>
            <Intake />
          </Frame12>
          <SubNav>
            <Rectangle4></Rectangle4>
            <Group6>
              <Rectangle12></Rectangle12>
              <Dashboard>{`Dashboard`}</Dashboard>
            </Group6>
            <Visits>{`Visits`}</Visits>
            <MessageCenter>{`Message Center`}</MessageCenter>
            <TestResults>{`Test Results`}</TestResults>
            <Medications>{`Medications`}</Medications>
          </SubNav>
          <Navigation>
            <Rectangle1></Rectangle1>
            <LogosAmwellLogoCompa
              src={LogosAmwellLogoCompaImage}
              loading="lazy"
              alt={'Logos/Amwell - Logo company'}
            />
            <Menu1 />
            <Group5>
              <Welcome />
            </Group5>
          </Navigation>
          <Group4 onClick={fns.onLogOut}>
            <Logout>{`Logout`}</Logout>
            <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
          </Group4>
          <GenericPortal
            src={GenericPortalImage}
            loading="lazy"
            alt={'GenericPortal'}
          />
          <Group26784>
            <MediumSecondaryButto
              src={MediumSecondaryButtoImage}
              loading="lazy"
              alt={'Medium Secondary Button'}
            />
            <YouHaveNoHealthIssue>
              {`You have no health issue on file.`}
            </YouHaveNoHealthIssue>
            <MaskGroup2>
              <Ellipse652
                src={Ellipse652Image}
                loading="lazy"
                alt={'Ellipse 652'}
              />
              <Group>
                <MaskGroup src={MaskGroupImage} loading="lazy" alt={'Mask Group'} />
              </Group>
              <Rectangle5617
                src={Rectangle5617Image}
                loading="lazy"
                alt={'Rectangle 5617'}
              />
              <Rectangle5620
                src={Rectangle5620Image}
                loading="lazy"
                alt={'Rectangle 5620'}
              />
              <Rectangle5618>
                <Vector1 src={Vector1Image} loading="lazy" alt={'Vector'} />
                <Vector2 src={Vector2Image} loading="lazy" alt={'Vector'} />
              </Rectangle5618>
              <Rectangle5619
                src={Rectangle5619Image}
                loading="lazy"
                alt={'Rectangle 5619'}
              />
              <Group26781>
                <Ellipse654
                  src={Ellipse654Image}
                  loading="lazy"
                  alt={'Ellipse 654'}
                />
                <Ellipse655
                  src={Ellipse655Image}
                  loading="lazy"
                  alt={'Ellipse 655'}
                />
                <Ellipse656
                  src={Ellipse656Image}
                  loading="lazy"
                  alt={'Ellipse 656'}
                />
              </Group26781>
              <Group26782>
                <Ellipse658
                  src={Ellipse658Image}
                  loading="lazy"
                  alt={'Ellipse 658'}
                />
                <Path25916 src={Path25916Image} loading="lazy" alt={'Path 25916'} />
              </Group26782>
            </MaskGroup2>
          </Group26784>
        </PatientPortalECheckI>
      );
    }
  }
  else {       
    return (
        <>
          <br/>
          <h5>You're not currently Logged In.</h5>
          <h4>Please click the link above to log in.</h4>
          <br/>
        </>
    )     
  }
}

export default PatientPortalECheckInStep6;

const PatientPortalECheckI = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1024px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Rectangle14 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  width: `1440px`,
  height: `1058px`,
  position: `absolute`,
  left: `0px`,
  top: `70px`,
}));

const Rectangle7 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `1260px`,
  height: `628px`,
  position: `absolute`,
  left: `90px`,
  top: `239px`,
}));

const Group26783 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1196px`,
  height: `92px`,
  left: `122px`,
  top: `731px`,
});

const Rectangle8 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `1196px`,
  height: `92px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LargePrimaryButton = styled('img')({
  height: `48px`,
  width: `152px`,
  position: `absolute`,
  left: `1012px`,
  top: `22px`,
  cursor: `pointer`,
});

const LargeSecondaryButton = styled('img')({
  height: `48px`,
  width: `164px`,
  position: `absolute`,
  left: `832px`,
  top: `23px`,
  cursor: `pointer`,
});

const PleaseReviewYourHealSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const PleaseReviewYourHealSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(255, 120, 122, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const PleaseReviewYourHeal = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `780px`,
  position: `absolute`,
  left: `31px`,
  top: `15px`,
});

const ECheckIn = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `32px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `90px`,
  top: `194px`,
}));

const Frame12 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `720px`,
  left: `360px`,
  top: `271px`,
});

const Intake = styled(IntakeSteps6)(({ theme }) => ({
  margin: `0px`,
}));

const SubNav = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `62px`,
  left: `0px`,
  top: `70px`,
});

const Rectangle4 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 8px 36px rgba(0, 0, 0, 0.16)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `62px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Group6 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `200px`,
  height: `42px`,
  left: `90px`,
  top: `20px`,
});

const Rectangle12 = styled('div')({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  width: `49px`,
  height: `6px`,
  position: `absolute`,
  left: `151px`,
  top: `36px`,
});

const Dashboard = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Visits = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `241px`,
  top: `20px`,
});

const MessageCenter = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `339px`,
  top: `20px`,
}));

const TestResults = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `536px`,
  top: `20px`,
}));

const Medications = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `693px`,
  top: `20px`,
}));

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const LogosAmwellLogoCompa = styled('img')({
  height: `auto`,
  width: `160px`,
  position: `absolute`,
  left: `76px`,
  top: `19px`,
});

const Menu1 = styled(Menu)(({ theme }) => ({
  width: `24px`,
  height: `24px`,
  position: `absolute`,
  left: `28px`,
  top: `23px`,
}));

const Group5 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `210px`,
  height: `46px`,
  left: `1056px`,
  top: `12px`,
});

const WelcomeTiana = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  width: `455px`,
  left: `55px`,
  top: `11px`,
}));

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1316px`,
  top: `23px`,
});

const Logout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `39px`,
  top: `0px`,
  cursor: `pointer`,
}));

const Vector = styled('img')({
  height: `auto`,
  width: `24px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GenericPortal = styled('img')({
  height: `auto`,
  width: `155px`,
  position: `absolute`,
  left: `256px`,
  top: `23px`,
});

const Group26784 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `607px`,
  height: `244px`,
  left: `417px`,
  top: `423px`,
});

const MediumSecondaryButto = styled('img')({
  height: `38px`,
  width: `260px`,
  position: `absolute`,
  left: `173px`,
  top: `206px`,
});

const YouHaveNoHealthIssue = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `607px`,
  position: `absolute`,
  left: `0px`,
  top: `166px`,
}));

const MaskGroup2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `150px`,
  height: `150px`,
  left: `228px`,
  top: `0px`,
});

const Ellipse652 = styled('img')({
  height: `150px`,
  width: `150px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group = styled('div')({
  boxShadow: `0px -3px 6px rgba(201, 205, 217, 0.35)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `150px`,
  height: `150px`,
  left: `0px`,
  top: `0px`,
});

const MaskGroup = styled('img')({
  height: `150px`,
  width: `150px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Rectangle5617 = styled('img')({
  height: `6px`,
  width: `32px`,
  position: `absolute`,
  left: `37px`,
  top: `53px`,
});

const Rectangle5620 = styled('img')({
  height: `6px`,
  width: `32px`,
  position: `absolute`,
  left: `37px`,
  top: `95px`,
});

const Rectangle5618 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `76px`,
  height: `20px`,
  left: `37px`,
  top: `67px`,
});

const Vector1 = styled('img')({
  height: `20px`,
  width: `76px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Vector2 = styled('img')({
  height: `18px`,
  width: `74px`,
  position: `absolute`,
  left: `1px`,
  top: `1px`,
});

const Rectangle5619 = styled('img')({
  height: `18px`,
  width: `76px`,
  position: `absolute`,
  left: `37px`,
  top: `109px`,
});

const Group26781 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `52px`,
  height: `8px`,
  left: `49px`,
  top: `24px`,
});

const Ellipse654 = styled('img')({
  height: `8px`,
  width: `8px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Ellipse655 = styled('img')({
  height: `8px`,
  width: `8px`,
  position: `absolute`,
  left: `22px`,
  top: `0px`,
});

const Ellipse656 = styled('img')({
  height: `8px`,
  width: `8px`,
  position: `absolute`,
  left: `44px`,
  top: `0px`,
});

const Group26782 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `19.09px`,
  height: `26.37px`,
  left: `79px`,
  top: `78px`,
});

const Ellipse658 = styled('img')({
  height: `10px`,
  width: `10px`,
  position: `absolute`,
  left: `2px`,
  top: `0px`,
});

const Path25916 = styled('img')({
  height: `22.45px`,
  width: `19.09px`,
  position: `absolute`,
  left: `0px`,
  top: `4px`,
});

