//Import Dependancies
import { useEffect, useState } from 'react';
import { durationSelections, getShortSessionLink, fetchPermanentLink , base64Encode} from '../modules/Utilities';
import { _URLS, _CONFIGS } from '../Config/index.js';
import { setGlobal } from '../globals';
import useProviderExperience from './useProviderExperience';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

//Load environment varaibles for the visit session
const urlProvHub = _URLS.AWForCliniciansUrl;
const urlAWN = _URLS.CarePointCallingUrl;
const oc_url = _URLS.oc_url;
const iss_url = _URLS.iss_url;
const tenant_id = _CONFIGS.tenant_id;
const ehr_id = _CONFIGS.ehr_id;

//FHIR Based SMART Launch URL Components
const launchType = _URLS.LAUNCH_TYPE;
const userType = _URLS.PROVIDER_USER;
const launchCmd = _URLS.LAUNCH_CMD;

const nullableEntry = {
    previousVisitID: ""
};

const ProviderExperience = props => {
    //Setup for Amwell Experience links
    const [editing, setEditing] = useState(nullableEntry);
    setGlobal({gActivateVVBtn: true});

    const { fns } = useProviderExperience();

    // Set session values
    //We need to create a unique prefix to the encounter to add to the encounter and avoid bumping into other visits
    var encounterIdAppendix = _URLS.encounterIdAppendix;
    var provider_id = _URLS.provider_id;
    var patient_id = _URLS.patient_id;
    var mrn = _URLS.mrn;

    // Set intial json token values
    var token = {
		"patientMRN":mrn,
        "patientId":patient_id,
		"encounterId":encounterIdAppendix, //This value seems to be the only one being used at the moment. Will need to include the other JSON elements to see if an Auth server or something like that is being used.
		"providerConnectionExternalUserId":provider_id,
		"providerEncounterExternalUserId":"a6dfe8e5-f65e-4eda-a572-850f7ac0d7cf",
		"patientFirstName":"Aundrea",
		"patientLastName":"Grant",
		"patientPrefix":"Mrs.",
		"patientEmail":"AVLMEM-68b11ebtma-h2cbalq86k-b6y5d0lsot@aa.bb",
		"patientDOB":"1965-05-17",
		"patientGender":"F",
		"patientPhone":"555-998-9601",
		"encounterDepartmentState":"MA"
	},
    token_string = "",
    token64 = "",
    activeLink = "",
    durationTerm = 1;

    //Make sure to grab the encounter ID prefix along with other initialization activities after the DOM is mounted
    useEffect(() => {
        if(isDevMode){
            console.log("ProviderExp.useEffect: useEffect called");
        }

        //Update the Scheduled Visit link
        updateSVLink();
        
        if(isDevMode){
            console.log("ProviderExp.useEffect: Active Link: " + activeLink);
        }
    });

    return (
        <section className='AmEx__Provider-Section'>
            <h3>Provider Experience</h3>
            <button className='button' onClick={fns.handleEHRScheduledVisit} type="submit" value="" id="LaunchEHRSV" >Scheduled Visit in EHR</button>
            <br/>
            <button className='button' onClick={fns.handleEHROnDemandVisit} type="submit" value="" id="LaunchEHRSV" >OnDemand in EHR</button
            ><br/>
            <button className='button' onClick={launchProviderDashboard} type="submit">Demo Dashboard</button>
            <br/>
            <button className='button' onClick={fns.handleEHRCPCVisit} type="submit" value="" id="LaunchEHRSV" >Carepoint Calling in EHR</button>
            <br/>
            <button className='button' onClick={launchAWNow} type="submit">Converge Launch w/SSO</button>
            <br/><br/>
            <h6>Enter a Visit ID if you'd like to create or join a specific scheduled visit</h6>
            <input className='encounterRandomPrefixInput' type="text" id="UserInputVisitPrefix" value={editing.previousVisitID} onChange={handleVisitIdInput} ></input>
            <br/>
            <button className='button' onClick={launchSVLink} type="submit" value="" id="LaunchEHRSV" >Launch Scheduled Visit</button>
            <br/>
            <button className='button' onClick={launchProviderConverge} type="submit">Log in to Converge</button>
        </section>
    );
    
    function launchAWNow() {
        window.open(urlAWN, "_blank");
    };

    function launchProviderDashboard() {
        window.open(_URLS.ProviderDashboardUrl, "_blank");
    };

    function launchProviderConverge() {
        window.open(urlProvHub, "_blank");
    };

    function launchSVLink() {
        if(document.getElementById("UserInputVisitPrefix") !== null){
            if(document.getElementById("UserInputVisitPrefix").value !== "") {
                activeLink = oc_url + launchType + tenant_id + "/" + ehr_id + userType + iss_url + launchCmd;
                update_json();
                window.open(activeLink, "_blank");
            }
        }
    };

    function handleVisitIdInput(e) {
        let newEdit = { ...editing};

        if(isDevMode){
            console.log("ProviderExp.handleVisitIDInput - value: " + e.target.value);
        }

        newEdit.previousVisitID = e.target.value;

        setEditing(newEdit);
        
        prepareToStartVisit();
    }

    function resetActiveLink() {
        activeLink = oc_url + launchType + tenant_id + "/" + ehr_id + userType + encodeURIComponent(iss_url) + launchCmd;
    }

    async function updateSVLink() {
        //Update the SV link
        var updatedLink = "#";

        if(isDevMode) {
            console.log("PatientExp.updateSVLink called");
        }

        //Refresh the active link to get rid of any short links wemay have previously built
        resetActiveLink();
        update_json();

        //Now shorten the active link
        if(durationTerm === 1) { //Permanent
            updatedLink = await fetchPermanentLink(activeLink);
        }
        else if(durationTerm === 2){ //48 Hour Session starting now
            updatedLink = await getShortSessionLink(activeLink);
        }

        if(isDevMode) {
            if(updatedLink === "#"){
                console.log("ProviderExp.updateSVLink: Nothing returned from fetchShortLink");
            }
            else {
                console.log("ProviderExp.updateSVLink activeLink: " + activeLink);
            }
        }   
        
        //activeLink = document.getElementById("LaunchProvSV").href = updatedLink;
        if(isDevMode) {
            console.log("PatientExp.updateSVLink active link = " + updatedLink);
        }
    };

    function prepareToStartVisit(){
        //Grab the unique visit prefix and assign it to the enounter ID so that we can avoid bumping into another visit
        if(document.getElementById("UserInputVisitPrefix") !== null){
            if(isDevMode) {
                console.log("ProviderExp prepareToStartVisit: token.encounterId: " + token.encounterId);
                //console.log("ProviderExp prepareToStartVisit: defaultVisitPrefix: " + document.getElementById("defaultVisitPrefix").value);
                console.log("ProviderExp prepareToStartVisit: UserInputVisitPrefix: " + document.getElementById("UserInputVisitPrefix").value);
                console.log("ProviderExp prepareToStartVisit: encounterIdAppendix: " + encounterIdAppendix);
            }

            if(document.getElementById("UserInputVisitPrefix").value !== "") {
                token.encounterId = document.getElementById("UserInputVisitPrefix").value + encounterIdAppendix;
                document.getElementById("UserInputVisitPrefix").href = activeLink
            }/*
            else {
                token.encounterId = document.getElementById("defaultVisitPrefix").value + encounterIdAppendix;
            }*/

            if(isDevMode) {
                console.log("ProviderExp prepareToStartVisit: Updated token.encounterId: " + token.encounterId);
            }
        }
    }
   
    /**
    *   Base64 encodes the current JSON token
    *   Update the displayed JSON and base64 encoded "launch" param
    *   Update all links with the new encoded launch param
    *   From "updateLaunchParam()" in Visit Launcher
    **/
    function update_json() {
        if(isDevMode){
            console.log("ProviderExp.update_json: CurrentTermOption = " + durationSelections[durationTerm-1].label); 
        }
        
        if(document.getElementById("UserInputVisitPrefix") !== null) { //Wait until the DOM is loaded
            //Do we have a value entered by the user?
            if(document.getElementById("UserInputVisitPrefix").value !== "") {
                if(isDevMode){
                    console.log("ProviderExp.update_JSON - eID prefix: " + document.getElementById("UserInputVisitPrefix").value)
                }
                token.encounterId = document.getElementById("UserInputVisitPrefix").value + encounterIdAppendix;
            }
            //No user input value, so use the default
            /*else {
                token.encounterId = document.getElementById("defaultVisitPrefix").value + encounterIdAppendix;
            }*/
        }
    
        token_string = JSON.stringify(token);
        token64 = encodeURIComponent(base64Encode(token_string));
        activeLink = activeLink + token64;

        if(isDevMode){
            //console.log("update_JSON - Encoded Data2: " + token64)
            console.log("ProviderExp.update_JSON - Provider Link: " + activeLink)
        }
    }
  }

  export default ProviderExperience
