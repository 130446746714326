/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect } from 'react';

import ImgBackgroundImage from 'src/assets/images/MyChart_imgBackground.png';
import ImgUserImage from 'src/assets/images/MyChart_imgUser.png';
import ImgUserDownArrowImage from 'src/assets/images/MyChart_imgUserDownArrow.png';
import ImgPillIconCircleImage from 'src/assets/images/MyChart_imgPillIconCircle.png';
import GrpPillIconImage from 'src/assets/images/MyChart_grpPillIcon.png';
import ImgTestResultsCircleImage from 'src/assets/images/MyChart_imgTestResultsCircle.png';
import GrpTestResultIconImage from 'src/assets/images/MyChart_grpTestResultIcon.png';
import ImgMessageCircleImage from 'src/assets/images/MyChart_imgMessageCircle.png';
import ImgMessageIconImage from 'src/assets/images/MyChart_imgMessageIcon.png';
import ImgCalCircleImage from 'src/assets/images/MyChart_imgCalCircle.png';
import ImgCalIconImage from 'src/assets/images/MyChart_imgCalIcon.png';
import ImgStripesCircleImage from 'src/assets/images/MyChart_imgStripesCircle.png';
import GrpIconStripesImage from 'src/assets/images/MyChart_grpIconStripes.png';
import ImgGlobeCircleImage from 'src/assets/images/MyChart_imgGlobeCircle.png';
//import ImgDownArrowImage from 'src/assets/images/MyChart_imgDownArrow.png';
import GrpLogOutImage from 'src/assets/images/MyChart_grpLogOut.png';
import ImgMyChartTitleImage from 'src/assets/images/MyChart_imgMyChartTitle.png';
import ImgEpicLogoImage from 'src/assets/images/MyChart_imgEpicLogo.png';

import Screenshot20230421AtImage from 'src/assets/images/PatientPortaleCheckInStep3_Screenshot_2023_04_21_at_12_38_1.png';
import IntakeSteps3 from '../Patient_Portal/IntakeSteps3';
import UselectedNoChip from '../../components/Chip/UnselectDeclineChip';
import SelectYesChip from '../../components/Chip/SelectAcceptChip';
import LargePrimaryButtonImage from 'src/assets/images/PatientPortaleCheckInStep2_Large_Primary_Button.png';
import LargeSecondaryButtonImage from 'src/assets/images/PatientPortaleCheckInStep2_Large_Secondary_Button.png';

import { styled } from '@mui/material/styles';
import useMyChartECheckInStep3 from './useMyChartECheckInStep3';
import { getGlobal } from '../../globals';
import { getPatientList } from '../../modules/Utilities';

function MyChartECheckInStep3(props) {
  const { fns } = useMyChartECheckInStep3();
  const { isAuthenticated, user } = useAuth0();
  const [ strFirstName, setPatientName ] = useState("");

  useEffect(() => {
    const patientList = getGlobal("gActiveUser");
    
    if(!patientList) {
      getPatientList();
      return;
    }
    else {
      //We have a patient list, so go ahead and set the first patient name in the list
      setPatientName(patientList.given_name);
    }
  }, [])

  if (isAuthenticated) {
    if(!user.email_verified) {
      return ( 
        <>
          <div>
            <h3>Please verify your account before continuing</h3>
          </div>
        </>
      ) 
    }
    else {
      return (
        <MyChartECheckIn2 className={props.className}>
          <ImgBackground src={ImgBackgroundImage} loading="lazy" alt={'imgBackground'}/>
          <ImgCursorMask></ImgCursorMask>
          <Rectangle7></Rectangle7>
          <Group26783>
            <Rectangle8></Rectangle8>
            <LargePrimaryButton
              onClick={fns.handleNext}
              src={LargePrimaryButtonImage}
              loading="lazy"
              alt={'Large Primary Button'}
            />
            <LargeSecondaryButton
              onClick={fns.handleFinishLater}
              src={LargeSecondaryButtonImage}
              loading="lazy"
              alt={'Large Secondary Button'}
            />

            <PleaseReviewSignYour>
              <PleaseReviewSignYourSpan1>{`Please review & accept before proceeding to the next step.\n`}</PleaseReviewSignYourSpan1>
              <PleaseReviewSignYourSpan2>{`Please call 911 if you have an emergency.`}</PleaseReviewSignYourSpan2>
            </PleaseReviewSignYour>
          </Group26783>
          
          
          <ECheckIn>{`eCheck-in`}</ECheckIn>
          <Frame12>
            <Intake />
          </Frame12>
          
          <Group26788>
            <Rectangle15></Rectangle15>
            <Screenshot20230421At
              src={Screenshot20230421AtImage}
              loading="lazy"
              alt={'Screenshot 2023-04-21 at 12.38 1'}
            />

            <Rectangle5621>
              <Group26785>                          
                <Chip1 />
                <Chip2 />
              </Group26785>
            </Rectangle5621>

          </Group26788>
          
          <GrpnavigationBar>
            <GrpUserBtn>
              <ImgUser src={ImgUserImage} loading="lazy" alt={'imgUser'} />
              <TxtUserName>{strFirstName}</TxtUserName>
              <ImgUserDownArrow
                src={ImgUserDownArrowImage}
                loading="lazy"
                alt={'imgUserDownArrow'}
              />
            </GrpUserBtn>
            <GrpMedications>
              <GrpMedicationsIcon>
                <ImgPillIconCircle
                  src={ImgPillIconCircleImage}
                  loading="lazy"
                  alt={'imgPillIconCircle'}
                />
                <GrpPillIcon
                  src={GrpPillIconImage}
                  loading="lazy"
                  alt={'grpPillIcon'}
                />
              </GrpMedicationsIcon>
              <TxtMedications>{`Medications`}</TxtMedications>
            </GrpMedications>
            <GrpTestResults>
              <GrpTestResults1>
                <ImgTestResultsCircle
                  src={ImgTestResultsCircleImage}
                  loading="lazy"
                  alt={'imgTestResultsCircle'}
                />
                <GrpTestResultIcon
                  src={GrpTestResultIconImage}
                  loading="lazy"
                  alt={'grpTestResultIcon'}
                />
              </GrpTestResults1>
              <TxtTestResults>{`Test Results`}</TxtTestResults>
            </GrpTestResults>
            <GrpMessageCenter>
              <GrpMessageIcon>
                <ImgMessageCircle
                  src={ImgMessageCircleImage}
                  loading="lazy"
                  alt={'imgMessageCircle'}
                />
                <ImgMessageIcon
                  src={ImgMessageIconImage}
                  loading="lazy"
                  alt={'imgMessageIcon'}
                />
              </GrpMessageIcon>
              <TxtMessageCenter>{`Message Center`}</TxtMessageCenter>
            </GrpMessageCenter>
            <GrpVisits>
              <GrpVisits1>
                <ImgCalCircle
                  src={ImgCalCircleImage}
                  loading="lazy"
                  alt={'imgCalCircle'}
                />
                <ImgCal2>
                  <ImgCalIcon
                    src={ImgCalIconImage}
                    loading="lazy"
                    alt={'imgCalIcon'}
                  />
                </ImgCal2>
              </GrpVisits1>
              <TxtVisits>{`Visits`}</TxtVisits>
            </GrpVisits>
            <GrpMenu>
              <GrpMenuIcon>
                <ImgStripesCircle
                  src={ImgStripesCircleImage}
                  loading="lazy"
                  alt={'imgStripesCircle'}
                />
                <GrpIconStripes
                  src={GrpIconStripesImage}
                  loading="lazy"
                  alt={'grpIconStripes'}
                />
              </GrpMenuIcon>
              <TxtMenu>{`Menu`}</TxtMenu>
            </GrpMenu>
          </GrpnavigationBar>
          <GrpPageTitleBar>
            <GrpUnivIcon>
              <ImgUnitRect></ImgUnitRect>
              <ImgGlobe>
                <ImgGlobeCircle
                  src={ImgGlobeCircleImage}
                  loading="lazy"
                  alt={'imgGlobeCircle'}
                />
              </ImgGlobe>
              {/*
              <ImgDownArrow
                src={ImgDownArrowImage}
                loading="lazy"
                alt={'imgDownArrow'}
              />
            */}
            </GrpUnivIcon>
            <BtnLogOut onClick={fns.onLogOut}>
              <GrpLogOut src={GrpLogOutImage} loading="lazy" alt={'grpLogOut'} />
              <TxtLogOut>{`Log Out`}</TxtLogOut>
            </BtnLogOut>
            <ImgMyChartTitle
              src={ImgMyChartTitleImage}
              loading="lazy"
              alt={'imgMyChartTitle'}
            />
            <GrpEpicLogo>
              <ImgEpicLogo
                src={ImgEpicLogoImage}
                loading="lazy"
                alt={'imgEpicLogo'}
              />
              <TxtMyChartLogo>{`MyChart by`}</TxtMyChartLogo>
            </GrpEpicLogo>
          </GrpPageTitleBar>

        </MyChartECheckIn2>
      );
    }
  }
  else {       
    return (
        <>
          <br/>
          <h5>You're not currently Logged In.</h5>
          <h4>Please click the link above to log in.</h4>
          <br/>
        </>
    )     
  }
}

export default MyChartECheckInStep3;

const MyChartECheckIn2 = styled('div')({
  backgroundColor: `rgba(123, 163, 208, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1000px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Rectangle7 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `1200px`,
  height: `800px`,
  position: `absolute`,
  left: `120px`,
  top: `113px`,
}));

const Rectangle8 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `1196px`,
  height: `92px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const ImgBackground = styled('img')({
  height: `912px`,
  width: `1439px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `1px`,
  top: `0px`,
});

const ImgCursorMask = styled('div')({
  backgroundColor: `rgba(31, 97, 175, 1)`,
  width: `84px`,
  height: `98px`,
  position: `absolute`,
  left: `1356px`,
  top: `814px`,
});

const GrpnavigationBar = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(246, 246, 246, 1)`,
  boxShadow: `0px 0.5px 2px rgba(4, 14, 34, 0.01), 0px 1px 4px rgba(4, 14, 34, 0.03), 0px 2px 6px rgba(4, 14, 34, 0.04), 0px 3px 12px rgba(4, 14, 34, 0.06), 0px 4px 14px rgba(4, 14, 34, 0.08)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `60px`,
  left: `0px`,
  top: `57px`,
  overflow: `hidden`,
}));

const GrpUserBtn = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 0px 1px rgba(4, 14, 34, 0.01), 0px 0.5px 2px rgba(4, 14, 34, 0.03), 0px 0.5px 3px rgba(4, 14, 34, 0.04), 0px 1px 4px rgba(4, 14, 34, 0.06), 0px 1px 6px rgba(4, 14, 34, 0.08)`,
  borderRadius: `8px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `2px 8px`,
  boxSizing: `border-box`,
  left: `1197px`,
  top: `7px`,
  overflow: `hidden`,
}));

const ImgUser = styled('img')({
  height: `41px`,
  width: `41px`,
  objectFit: `cover`,
  margin: `0px`,
});

const TxtUserName = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(31, 97, 175, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px 0px 0px 3px`,
});

const ImgUserDownArrow = styled('img')({
  height: `6px`,
  width: `8px`,
  margin: `0px 0px 0px 3px`,
});

const GrpMedications = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `133px`,
  height: `42px`,
  left: `689px`,
  top: `9px`,
});

const GrpMedicationsIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `0px`,
  top: `0px`,
});

const ImgPillIconCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpPillIcon = styled('img')({
  height: `24.73px`,
  width: `25.65px`,
  position: `absolute`,
  left: `8px`,
  top: `9px`,
});

const TxtMedications = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `42px`,
  top: `9px`,
});

const GrpTestResults = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `131px`,
  height: `42px`,
  left: `534px`,
  top: `9px`,
});

const GrpTestResults1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `0px`,
  top: `0px`,
});

const ImgTestResultsCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpTestResultIcon = styled('img')({
  height: `22px`,
  width: `21px`,
  position: `absolute`,
  left: `11px`,
  top: `10px`,
});

const TxtTestResults = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `40px`,
  top: `9px`,
});

const GrpMessageCenter = styled('div')(({ theme }) => ({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `158px`,
  height: `42px`,
  left: `352px`,
  top: `9px`,
}));

const GrpMessageIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `0px`,
  top: `0px`,
});

const ImgMessageCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgMessageIcon = styled('img')({
  height: `18px`,
  width: `24px`,
  position: `absolute`,
  left: `9px`,
  top: `12px`,
});

const TxtMessageCenter = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `42px`,
  top: `9px`,
});

const GrpVisits = styled('div')(({ theme }) => ({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `86px`,
  height: `42px`,
  left: `242px`,
  top: `9px`,
}));

const GrpVisits1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `42px`,
  height: `42px`,
  left: `0px`,
  top: `0px`,
});

const ImgCalCircle = styled('img')({
  height: `42px`,
  width: `42px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgCal2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `22px`,
  height: `24px`,
  left: `10px`,
  top: `9px`,
  overflow: `hidden`,
});

const ImgCalIcon = styled('img')({
  height: `24px`,
  width: `22px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const TxtVisits = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `40px`,
  top: `10px`,
});

const GrpMenu = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `93px`,
  height: `45px`,
  left: `125px`,
  top: `8px`,
});

const GrpMenuIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `45px`,
  height: `45px`,
  left: `0px`,
  top: `0px`,
});

const ImgStripesCircle = styled('img')({
  height: `45px`,
  width: `45px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const GrpIconStripes = styled('img')({
  height: `18px`,
  width: `22px`,
  position: `absolute`,
  left: `12px`,
  top: `13px`,
});

const TxtMenu = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `54px`,
  top: `10px`,
});

const GrpPageTitleBar = styled('div')({
  backgroundColor: `rgba(31, 97, 175, 1)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `57px`,
  left: `0px`,
  top: `0px`,
  overflow: `hidden`,
});

const GrpUnivIcon = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `45px`,
  height: `35px`,
  left: `1178px`,
  top: `11px`,
});

const ImgUnitRect = styled('div')({
  opacity: `0.20000000298023224`,
  backgroundColor: `rgba(217, 217, 217, 1)`,
  borderRadius: `8px`,
  width: `45px`,
  height: `35px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const ImgGlobe = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `22px`,
  height: `22px`,
  left: `6px`,
  top: `6px`,
  overflow: `hidden`,
});

const ImgGlobeCircle = styled('img')({
  height: `22px`,
  width: `22px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

/*
const ImgDownArrow = styled('img')({
  height: `6px`,
  width: `8px`,
  position: `absolute`,
  left: `47px`,
  top: `26px`,
});
*/

const BtnLogOut = styled('div')({
  backgroundColor: `rgba(217, 217, 217, 0.2)`,
  borderRadius: `8px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `center`,
  padding: `5px 6px`,
  boxSizing: `border-box`,
  left: `1229px`,
  top: `11px`,
  overflow: `hidden`,
  cursor: `pointer`,
});

const GrpLogOut = styled('img')({
  height: `19px`,
  width: `22px`,
  margin: `0px`,
});

const TxtLogOut = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px 0px 0px 2px`,
});

const ImgMyChartTitle = styled('img')({
  height: `49px`,
  width: `149px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `646px`,
  top: `4px`,
});

const GrpEpicLogo = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `48px`,
  height: `52px`,
  left: `127px`,
  top: `5px`,
});

const ImgEpicLogo = styled('img')({
  height: `48px`,
  width: `48px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `4px`,
});

const TxtMyChartLogo = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `8px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `2px`,
  top: `0px`,
});

const Group26783 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1196px`,
  height: `92px`,
  left: `122px`,
  top: `815px`,
});

const LargePrimaryButton = styled('img')({
  height: `48px`,
  width: `152px`,
  position: `absolute`,
  left: `1012px`,
  top: `22px`,
  cursor: `pointer`,
});

const LargeSecondaryButton = styled('img')({
  height: `48px`,
  width: `164px`,
  position: `absolute`,
  left: `832px`,
  top: `23px`,
  cursor: `pointer`,
});

const ECheckIn = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `32px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `200px`,
  top: `150px`,
}));

const Frame12 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `720px`,
  left: `360px`,
  top: `200px`,
});

const Intake = styled(IntakeSteps3)(({ theme }) => ({
  margin: `0px`,
}));

const Rectangle5621 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(240, 241, 242, 1)`,
  borderRadius: `24px 24px 0px 0px`,
  width: `340px`,
  height: `70px`,
  position: `absolute`,
  left: `439px`,
  top: `550px`,
}));

const Group26785 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `340px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Chip1 = styled(SelectYesChip)(({ theme }) => ({
  width: `98px`,
  height: `32px`,
  position: `absolute`,
  left: `20%`,
  top: `30%`,
}));

const Chip2 = styled(UselectedNoChip)(({ theme }) => ({
  width: `98px`,
  height: `32px`,
  position: `absolute`,
  left: `58%`,
  top: `30%`,
}));

const Group26788 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1260px`,
  //height: `819px`,
  height: `500px`,
  left: `90px`,
  top: `170px`,
});

const Rectangle15 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `970px`,
  height: `489px`,
  position: `absolute`,
  left: `145px`,
  top: `130px`,
}));

const PleaseReviewSignYourSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(20, 20, 20, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const PleaseReviewSignYourSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(255, 120, 122, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
});

const PleaseReviewSignYour = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  width: `607px`,
  position: `absolute`,
  left: `31px`,
  top: `22px`,
});

const Screenshot20230421At = styled('img')({
  height: `489px`,
  width: `678px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `270px`,
  top: `144px`,
});
