/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import { useEffect } from 'react';
import axios from "axios";
import VectorImage from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector.png';
import Vector1Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_1.png';
import Vector2Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_2.png';
import Vector3Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_3.png';
import Vector4Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_4.png';
import Vector5Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_5.png';
import Vector6Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_6.png';
import Vector7Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_7.png';
import Vector8Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_8.png';
import Vector9Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_9.png';
import Vector10Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_10.png';
import Vector11Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_11.png';
import Vector12Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_12.png';
import Vector13Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_13.png';
import Vector14Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_14.png';
import Vector15Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_15.png';
import Vector16Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_16.png';
import Vector17Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_17.png';
import Vector18Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_18.png';
import Vector19Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_19.png';
import Ellipse1Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Ellipse_1.png';
import VectorImage1 from 'src/assets/images/PatientPortaleCheckInStep1_Vector.svg';
import Vector20Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_20.png';
import Vector21Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_21.png';
import Vector22Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_22.png';
import Vector23Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_23.png';
import Vector24Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_24.png';
import Vector25Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_25.png';
import Screenshot20230419AtImage from 'src/assets/images/ProviderEMRDocumentingTakeNote_Screenshot_2023_04_19_at_5_04_1.png';
import Vector26Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_26.png';
import Vector27Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_27.png';
import Vector28Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_28.png';
import Vector29Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_29.png';
import Vector30Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_30.png';
import Vector31Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_31.png';
import Vector32Image from 'src/assets/images/ProviderEMRDocumentingTakeNote_Vector_32.png';
import { styled } from '@mui/material/styles';
import useProviderEmrDocumentingTakeNote from './useProviderEmrDocumentingTakeNote';
import { setGlobal, getGlobal } from '../../globals';
import { _URLS, _CONFIGS } from '../../Config/index';

const isDevMode = _CONFIGS.isDevMode;

function ProviderEmrDocumentingTakeNote(props) {
  const { fns } = useProviderEmrDocumentingTakeNote();

  useEffect(() => {
    const arrPatientList = getGlobal("gPatDetails");
    
    if(!arrPatientList) {
      getPatientList();
      return;
    }
    
  }, [])

  async function getPatientList() {
    const strEmrId = getGlobal("gEhrId");
    const patientDetails = await axios.get(_URLS.patientSearchUrl + strEmrId)
      .catch((e) => {
        console.log("ProviderEmrDocumentingTakeNote.getPatientList: Unable to get patient list from " + _URLS.patientSearchUrl + strEmrId);
        console.log(e);
        return;
      });

      if(patientDetails) {
        setGlobal({gActiveUser: patientDetails});
      }
  }

  function PatientDetail() {
    const arrPatientList = getGlobal("gPatDetails");

    if(!arrPatientList) {
      if(isDevMode)
        console.log("ProviderEmrDocumentingTakeNote.PatientDetail: No Patient List Available");

      getPatientList();
      return (
        <Group46>
          <TianaMaya>{`No Patient Detail`}</TianaMaya>
        </Group46> 
      );
    }

    const imgPatient = "data:image/png;base64," + arrPatientList.photo;

    var today = new Date();
    var DOB = new Date(arrPatientList.birth_date);
    var age_now = today.getFullYear() - DOB.getFullYear();

    return (
      <Group46>
        <Ellipse2 src={imgPatient} loading="lazy" alt={'Ellipse 2'} />
        <TianaMaya>{arrPatientList.given_name + " " + arrPatientList.family_name}</TianaMaya>
        <Q32YearSFemaleDob020>
            <br/>
            <br/>
            { 
              age_now + ` Year(s), ` + 
              arrPatientList.gender + 
              `, \nDOB: ` + arrPatientList.birth_date + 
              `\nLanguage: ` + arrPatientList.language_name + 
              `\nMRN: ` + arrPatientList.mrn + 
              `\nPhone: ` + arrPatientList.phone + 
              `\nEmail: \n` + arrPatientList.email
            }
        </Q32YearSFemaleDob020>
      </Group46>
    )
  }

  function VisitTitle() {
    var today = new Date();
    var strDate = (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getFullYear();

    return (
      <Group54>
        <SkinDisorders>{`Routine Care`}</SkinDisorders>
        <WithJamesSloanMdPhys>
          {`with Carol Weiman MD, Physician on ` + strDate}
        </WithJamesSloanMdPhys>
      </Group54>
    )
  }

  return (
    <ProviderEmrDocumenti className={props.className}>
      <Navigation>
        <Rectangle1></Rectangle1>
        <Menu>
          <Vector src={VectorImage} loading="lazy" alt={'Vector'} />
        </Menu>
        <GenericEmr>
          <GenericEmrSpan1>{`Generic`}</GenericEmrSpan1>
          <GenericEmrSpan2>{`EMR`}</GenericEmrSpan2>
        </GenericEmr>
        <LogosAmwellLogoCompa>
          <Frame>
            <Group>
              <Vector1 src={Vector1Image} loading="lazy" alt={'Vector'} />
              <Vector2 src={Vector2Image} loading="lazy" alt={'Vector'} />
              <Vector3 src={Vector3Image} loading="lazy" alt={'Vector'} />
              <Vector4 src={Vector4Image} loading="lazy" alt={'Vector'} />
              <Vector5 src={Vector5Image} loading="lazy" alt={'Vector'} />
              <Vector6 src={Vector6Image} loading="lazy" alt={'Vector'} />
              <Vector7 src={Vector7Image} loading="lazy" alt={'Vector'} />
              <Vector8 src={Vector8Image} loading="lazy" alt={'Vector'} />
              <Vector9 src={Vector9Image} loading="lazy" alt={'Vector'} />
              <Vector10 src={Vector10Image} loading="lazy" alt={'Vector'} />
            </Group>
            <Vector11 src={Vector11Image} loading="lazy" alt={'Vector'} />
            <Vector12 src={Vector12Image} loading="lazy" alt={'Vector'} />
            <Vector13 src={Vector13Image} loading="lazy" alt={'Vector'} />
            <Vector14 src={Vector14Image} loading="lazy" alt={'Vector'} />
            <Vector15 src={Vector15Image} loading="lazy" alt={'Vector'} />
            <Vector16 src={Vector16Image} loading="lazy" alt={'Vector'} />
            <Vector17 src={Vector17Image} loading="lazy" alt={'Vector'} />
            <Vector18 src={Vector18Image} loading="lazy" alt={'Vector'} />
            <Vector19 src={Vector19Image} loading="lazy" alt={'Vector'} />
          </Frame>
        </LogosAmwellLogoCompa>
        <Ellipse1 src={Ellipse1Image} loading="lazy" alt={'Ellipse 1'} />
      </Navigation>
      <Group4 onClick={fns.onLogOut}>
        <Logout>{`Logout`}</Logout>
        <Vector src={VectorImage1} loading="lazy" alt={'Vector'} />
      </Group4>
      <Rectangle7></Rectangle7>
      <Rectangle8></Rectangle8>
      <Rectangle9></Rectangle9>
      <SkinDisordersSignsIn>
        {`Feeling unwell, signs includ `}
      </SkinDisordersSignsIn>
      <MoreVert>
        <Vector20 src={Vector20Image} loading="lazy" alt={'Vector'} />
      </MoreVert>
      <MyNotes>{`My Notes`}</MyNotes>
      <Line4></Line4>
      <Line35></Line35>
      <VisitTitle />
      <Group48>
        <Group47>
          <Rectangle16></Rectangle16>
          <Rectangle17></Rectangle17>
          <VirtualVisit>{`Virtual Visit`}</VirtualVisit>
        </Group47>
        <ChartReview>{`Chart Review`}</ChartReview>
        <Snapshot>{`Snapshot`}</Snapshot>
        <Synopsis>{`Synopsis`}</Synopsis>
        <Screenings>{`Screenings`}</Screenings>
        <Hwbp>{`HWBP`}</Hwbp>
        <PatientMessage>{`Patient Message`}</PatientMessage>
      </Group48>
      <PatientDetail />
      <Group49>
        <Rectangle81></Rectangle81>
        <NoContacts>{`No Contacts`}</NoContacts>
        <Contacts>{`Contacts`}</Contacts>
        <Line41></Line41>
        <MoreVert1>
          <Vector21 src={Vector21Image} loading="lazy" alt={'Vector'} />
        </MoreVert1>
      </Group49>
      <Group50>
        <Rectangle82></Rectangle82>
        <ReasonForVisit>{`Reason for Visit: `}</ReasonForVisit>
        <SkinDisorders1>{`Routine Care`}</SkinDisorders1>
        <ChiefComplaint>{`Chief Complaint`}</ChiefComplaint>
        <Line42></Line42>
        <MoreVert2>
          <Vector22 src={Vector22Image} loading="lazy" alt={'Vector'} />
        </MoreVert2>
      </Group50>
      <Group51>
        <Rectangle83></Rectangle83>
        <NoDataFound>{`No data found`}</NoDataFound>
        <Flowsheets>{`Flowsheets`}</Flowsheets>
        <VisitDetails>{`Visit Details`}</VisitDetails>
        <Line43></Line43>
        <MoreVert3>
          <Vector23 src={Vector23Image} loading="lazy" alt={'Vector'} />
        </MoreVert3>
      </Group51>
      <Group52>
        <Rectangle84></Rectangle84>
        <NoMedicationsOnFile>{`No Medications on file`}</NoMedicationsOnFile>
        <Medications>{`Medications`}</Medications>
        <Line44></Line44>
        <MoreVert4>
          <Vector24 src={Vector24Image} loading="lazy" alt={'Vector'} />
        </MoreVert4>
      </Group52>
      <Group53>
        <Rectangle85></Rectangle85>
        <SelectAPharmacy>{`Select a pharmacy`}</SelectAPharmacy>
        <NoActiveOrders>{`No active orders`}</NoActiveOrders>
        <RefillManagement>{`Refill Management`}</RefillManagement>
        <Line45></Line45>
        <MoreVert5>
          <Vector25 src={Vector25Image} loading="lazy" alt={'Vector'} />
        </MoreVert5>
      </Group53>
      <Group55>
        <TextChip>
          <Text>{`Medicare Phys NW`}</Text>
        </TextChip>
        <TextChip1>
          <Text1>{`PREOP Adult`}</Text1>
        </TextChip1>
        <TextChip2>
          <Text2>{`APSO`}</Text2>
        </TextChip2>
        <TextChip3>
          <Text3>{`Female Phys NW`}</Text3>
        </TextChip3>
        <TextChip4>
          <Text4>{`Male Phys NW`}</Text4>
        </TextChip4>
        <TextChip5>
          <Text5>{`WCC`}</Text5>
        </TextChip5>
      </Group55>
      <Screenshot20230419At
        src={Screenshot20230419AtImage}
        loading="lazy"
        alt={'Screenshot 2023-04-19 at 5.04 1'}
      />
      <Line34></Line34>
      <PrimaryButton onClick={fns.handleOnSave}>
        {false && <DangerBg></DangerBg>}
        {false && (
          <ChevronLeft>
            <Vector26 src={Vector26Image} loading="lazy" alt={'Vector'} />
          </ChevronLeft>
        )}
        <ActionM>{`Save`}</ActionM>
        {false && (
          <ChevronRight>
            <Vector27 src={Vector27Image} loading="lazy" alt={'Vector'} />
          </ChevronRight>
        )}
      </PrimaryButton>
      <PrimaryButton1 onClick={fns.handleStartVideoVisit}>
        {false && <DangerBg1></DangerBg1>}
        <VideocamOutline>
          <Vector28 src={Vector28Image} loading="lazy" alt={'Vector'} />
        </VideocamOutline>
        <ActionM1>{`Connect to Video`}</ActionM1>
        {false && (
          <ChevronRight1>
            <Vector29 src={Vector29Image} loading="lazy" alt={'Vector'} />
          </ChevronRight1>
        )}
      </PrimaryButton1>
      <MoreVert6>
        <Vector30 src={Vector30Image} loading="lazy" alt={'Vector'} />
      </MoreVert6>
      <SecondaryButton onClick={fns.handleOnCancel}>
        {false && (
          <ChevronLeft1>
            <Vector31 src={Vector31Image} loading="lazy" alt={'Vector'} />
          </ChevronLeft1>
        )}
        <ActionM2>{`Cancel`}</ActionM2>
        {false && (
          <ChevronRight2>
            <Vector32 src={Vector32Image} loading="lazy" alt={'Vector'} />
          </ChevronRight2>
        )}
      </SecondaryButton>
    </ProviderEmrDocumenti>
  );
}

export default ProviderEmrDocumentingTakeNote;

const ProviderEmrDocumenti = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `1200px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  overflow: `hidden`,
});

const Navigation = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  left: `0px`,
  top: `0px`,
});

const Rectangle1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(190, 195, 204, 1)`,
  boxSizing: `border-box`,
  width: `1440px`,
  height: `70px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Menu = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `28px`,
  top: `23px`,
  overflow: `hidden`,
});

const Vector = styled('img')({
  height: `12px`,
  width: `18px`,
  position: `absolute`,
  left: `3px`,
  top: `6px`,
});

const GenericEmrSpan1 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `300`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
});

const GenericEmrSpan2 = styled('span')({
  whiteSpace: `pre-wrap`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `800`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
});

const GenericEmr = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 0, 0, 1)`,
  fontStyle: `normal`,
  fontFamily: 'unset',
  fontWeight: `400`,
  fontSize: `28px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `36px`,
  textTransform: `none`,
  position: `absolute`,
  left: `258px`,
  top: `17px`,
});

const LogosAmwellLogoCompa = styled('div')({
  backgroundColor: `rgba(255, 255, 255, 0)`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `160px`,
  height: `32px`,
  left: `76px`,
  top: `19px`,
  overflow: `hidden`,
});

const Frame = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `159.32px`,
  height: `26.5px`,
  left: `0px`,
  top: `3px`,
  overflow: `hidden`,
});

const Group = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `126.96px`,
  height: `16.39px`,
  left: `32px`,
  top: `5px`,
});

const Vector1 = styled('img')({
  height: `15.38px`,
  width: `13.33px`,
  position: `absolute`,
  left: `0px`,
  top: `1px`,
});

const Vector2 = styled('img')({
  height: `12.58px`,
  width: `12.27px`,
  position: `absolute`,
  left: `15px`,
  top: `4px`,
});

const Vector3 = styled('img')({
  height: `12.58px`,
  width: `12.91px`,
  position: `absolute`,
  left: `29px`,
  top: `4px`,
});

const Vector4 = styled('img')({
  height: `16.21px`,
  width: `3.75px`,
  position: `absolute`,
  left: `44px`,
  top: `0px`,
});

const Vector5 = styled('img')({
  height: `15.2px`,
  width: `7.72px`,
  position: `absolute`,
  left: `50px`,
  top: `1px`,
});

const Vector6 = styled('img')({
  height: `16.21px`,
  width: `12.17px`,
  position: `absolute`,
  left: `60px`,
  top: `0px`,
});

const Vector7 = styled('img')({
  height: `15.65px`,
  width: `15.04px`,
  position: `absolute`,
  left: `74px`,
  top: `1px`,
});

const Vector8 = styled('img')({
  height: `12.58px`,
  width: `12.91px`,
  position: `absolute`,
  left: `90px`,
  top: `4px`,
});

const Vector9 = styled('img')({
  height: `12.34px`,
  width: `7.58px`,
  position: `absolute`,
  left: `106px`,
  top: `4px`,
});

const Vector10 = styled('img')({
  height: `12.58px`,
  width: `12.27px`,
  position: `absolute`,
  left: `115px`,
  top: `4px`,
});

const Vector11 = styled('img')({
  height: `11.24px`,
  width: `15.57px`,
  position: `absolute`,
  left: `11px`,
  top: `8px`,
});

const Vector12 = styled('img')({
  height: `11.24px`,
  width: `15.56px`,
  position: `absolute`,
  left: `0px`,
  top: `8px`,
});

const Vector13 = styled('img')({
  height: `15.11px`,
  width: `11.24px`,
  position: `absolute`,
  left: `8px`,
  top: `11px`,
});

const Vector14 = styled('img')({
  height: `1.72px`,
  width: `2.85px`,
  position: `absolute`,
  left: `16px`,
  top: `11px`,
});

const Vector15 = styled('img')({
  height: `16.01px`,
  width: `11.24px`,
  position: `absolute`,
  left: `8px`,
  top: `0px`,
});

const Vector16 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `16px`,
  top: `6px`,
});

const Vector17 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `22px`,
  top: `19px`,
});

const Vector18 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `8px`,
  top: `14px`,
});

const Vector19 = styled('img')({
  height: `1.71px`,
  width: `2.84px`,
  position: `absolute`,
  left: `8px`,
  top: `20px`,
});

const Logout = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `25px`,
  top: `0px`,
  cursor: `pointer`,
}));

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `1369px`,
  top: `12px`,
});

const Group4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `94px`,
  height: `24px`,
  left: `1275px`,
  top: `23px`,
  cursor: `pointer`,
});

const Rectangle7 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  boxShadow: `0px 4px 14px rgba(0, 0, 0, 0.16)`,
  borderRadius: `8px`,
  width: `1140px`,
  height: `894px`,
  position: `absolute`,
  left: `265px`,
  top: `161px`,
}));

const Rectangle8 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `591px`,
  height: `652px`,
  position: `absolute`,
  left: `790px`,
  top: `284px`,
}));

const Rectangle9 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  borderRadius: `8px`,
  width: `543px`,
  height: `307px`,
  position: `absolute`,
  left: `806px`,
  top: `457px`,
}));

const SkinDisordersSignsIn = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(26, 31, 36, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `828px`,
  top: `535px`,
}));

const MoreVert = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `1333px`,
  top: `300px`,
  overflow: `hidden`,
});

const Vector20 = styled('img')({
  height: `16px`,
  width: `4px`,
  position: `absolute`,
  left: `10px`,
  top: `4px`,
});

const MyNotes = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `835px`,
  top: `300px`,
}));

const Line4 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `586px`,
  height: `0px`,
  position: `absolute`,
  left: `790px`,
  top: `340px`,
});

const Line35 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `543px`,
  height: `0px`,
  position: `absolute`,
  left: `806px`,
  top: `509px`,
});

const Group54 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `402px`,
  height: `56px`,
  left: `300px`,
  top: `196px`,
});

const SkinDisorders = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `32px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const WithJamesSloanMdPhys = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `32px`,
  width: '500px',
}));

const Group48 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `1050px`,
  height: `59px`,
  left: `300px`,
  top: `102px`,
});

const Group47 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `164px`,
  height: `59px`,
  left: `433px`,
  top: `0px`,
});

const Rectangle16 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(255, 255, 255, 1)`,
  border: `1px solid rgba(232, 234, 237, 1)`,
  boxSizing: `border-box`,
  borderRadius: `8px 8px 0px 0px`,
  width: `164px`,
  height: `59px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const Rectangle17 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  borderRadius: `8px 8px 0px 0px`,
  width: `164px`,
  height: `8px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const VirtualVisit = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `32px`,
  top: `21px`,
}));

const ChartReview = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `0px`,
  top: `20px`,
}));

const Snapshot = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `179px`,
  top: `20px`,
}));

const Synopsis = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `325px`,
  top: `20px`,
}));

const Screenings = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `629px`,
  top: `20px`,
}));

const Hwbp = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `787px`,
  top: `20px`,
}));

const PatientMessage = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `18px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `885px`,
  top: `20px`,
}));

const Group46 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `145px`,
  height: `264px`,
  left: `42px`,
  top: `102px`,
});

const Ellipse2 = styled('img')({
  height: `120px`,
  width: `120px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `12px`,
  top: `0px`,
});

const TianaMaya = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `24px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `-30px`,
  top: `136px`,
  width: '300px',
}));

const Q32YearSFemaleDob020 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `-40px`,
  top: `168px`,
  width: '250px',
}));

const Group49 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `108px`,
  left: `300px`,
  top: `284px`,
});

const Rectangle81 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `108px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const NoContacts = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `65px`,
}));

const Contacts = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `14px`,
}));

const Line41 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const MoreVert1 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `441px`,
  top: `14px`,
  overflow: `hidden`,
});

const Vector21 = styled('img')({
  height: `16px`,
  width: `4px`,
  position: `absolute`,
  left: `10px`,
  top: `4px`,
});

const Group50 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `108px`,
  left: `300px`,
  top: `408px`,
});

const Rectangle82 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `108px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const ReasonForVisit = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `65px`,
}));

const SkinDisorders1 = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `145px`,
  top: `65px`,
}));

const ChiefComplaint = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `14px`,
}));

const Line42 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const MoreVert2 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `441px`,
  top: `14px`,
  overflow: `hidden`,
});

const Vector22 = styled('img')({
  height: `16px`,
  width: `4px`,
  position: `absolute`,
  left: `10px`,
  top: `4px`,
});

const Group51 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `108px`,
  left: `300px`,
  top: `532px`,
});

const Rectangle83 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `108px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const NoDataFound = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `65px`,
}));

const Flowsheets = styled('div')({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `380px`,
  top: `65px`,
});

const VisitDetails = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `14px`,
}));

const Line43 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const MoreVert3 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `441px`,
  top: `14px`,
  overflow: `hidden`,
});

const Vector23 = styled('img')({
  height: `16px`,
  width: `4px`,
  position: `absolute`,
  left: `10px`,
  top: `4px`,
});

const Group52 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `108px`,
  left: `300px`,
  top: `656px`,
});

const Rectangle84 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `108px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const NoMedicationsOnFile = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `65px`,
}));

const Medications = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `14px`,
}));

const Line44 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const MoreVert4 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `441px`,
  top: `14px`,
  overflow: `hidden`,
});

const Vector24 = styled('img')({
  height: `16px`,
  width: `4px`,
  position: `absolute`,
  left: `10px`,
  top: `4px`,
});

const Group53 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `480px`,
  height: `108px`,
  left: `300px`,
  top: `780px`,
});

const Rectangle85 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(247, 247, 247, 1)`,
  borderRadius: `8px`,
  width: `480px`,
  height: `108px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const SelectAPharmacy = styled('div')({
  textAlign: `right`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(39, 95, 212, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `334px`,
  top: `60px`,
});

const NoActiveOrders = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `60px`,
}));

const RefillManagement = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(35, 42, 48, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `600`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `23px`,
  top: `14px`,
}));

const Line45 = styled('div')({
  border: `1px solid rgba(232, 234, 237, 1)`,
  width: `480px`,
  height: `0px`,
  position: `absolute`,
  left: `0px`,
  top: `49px`,
});

const MoreVert5 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `441px`,
  top: `14px`,
  overflow: `hidden`,
});

const Vector25 = styled('img')({
  height: `16px`,
  width: `4px`,
  position: `absolute`,
  left: `10px`,
  top: `4px`,
});

const Group55 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `477px`,
  height: `74px`,
  left: `806px`,
  top: `356px`,
});

const TextChip = styled('div')({
  border: `1px solid rgba(91, 107, 123, 1)`,
  boxSizing: `border-box`,
  borderRadius: `18px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 12px`,
  left: `0px`,
  top: `0px`,
});

const Text = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  margin: `0px`,
}));

const TextChip1 = styled('div')({
  border: `1px solid rgba(91, 107, 123, 1)`,
  boxSizing: `border-box`,
  borderRadius: `18px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 12px`,
  left: `0px`,
  top: `42px`,
});

const Text1 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  margin: `0px`,
}));

const TextChip2 = styled('div')({
  border: `1px solid rgba(91, 107, 123, 1)`,
  boxSizing: `border-box`,
  borderRadius: `18px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 12px`,
  left: `115px`,
  top: `42px`,
});

const Text2 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  margin: `0px`,
}));

const TextChip3 = styled('div')({
  border: `1px solid rgba(91, 107, 123, 1)`,
  boxSizing: `border-box`,
  borderRadius: `18px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 12px`,
  left: `155px`,
  top: `0px`,
});

const Text3 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  margin: `0px`,
}));

const TextChip4 = styled('div')({
  border: `1px solid rgba(91, 107, 123, 1)`,
  boxSizing: `border-box`,
  borderRadius: `18px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 12px`,
  left: `296px`,
  top: `0px`,
});

const Text4 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  margin: `0px`,
}));

const TextChip5 = styled('div')({
  border: `1px solid rgba(91, 107, 123, 1)`,
  boxSizing: `border-box`,
  borderRadius: `18px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 12px`,
  left: `422px`,
  top: `0px`,
});

const Text5 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `400`,
  fontSize: `14px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `20px`,
  textTransform: `none`,
  margin: `0px`,
}));

const Screenshot20230419At = styled('img')({
  height: `35px`,
  width: `525px`,
  position: `absolute`,
  left: `818px`,
  top: `462px`,
});

const Line34 = styled('div')({
  border: `1px solid rgba(0, 0, 0, 1)`,
  width: `18px`,
  height: `0px`,
  position: `absolute`,
  left: `1025px`,
  top: `543px`,
});

const PrimaryButton = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  borderRadius: `25px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 16px`,
  boxSizing: `border-box`,
  left: `1275px`,
  top: `783px`,
  overflow: `hidden`,
  cursor: `pointer`,
}));

const DangerBg = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(217, 59, 61, 1)`,
  width: `70px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const ChevronLeft = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px 0px 0px 8px`,
  overflow: `hidden`,
});

const Vector26 = styled('img')({
  height: `12px`,
  width: `7.41px`,
  position: `absolute`,
  left: `8px`,
  top: `6px`,
});

const ActionM = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px`,
}));

const ChevronRight = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px 0px 0px 8px`,
  overflow: `hidden`,
});

const Vector27 = styled('img')({
  height: `12px`,
  width: `7.41px`,
  position: `absolute`,
  left: `9px`,
  top: `6px`,
});

const PrimaryButton1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(0, 137, 6, 1)`,
  borderRadius: `25px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `6px 16px`,
  boxSizing: `border-box`,
  left: `1114px`,
  top: `203px`,
  overflow: `hidden`,
  cursor: `pointer`,
}));

const DangerBg1 = styled('div')(({ theme }) => ({
  backgroundColor: `rgba(217, 59, 61, 1)`,
  width: `202px`,
  height: `36px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
}));

const VideocamOutline = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px`,
  overflow: `hidden`,
});

const Vector28 = styled('img')({
  height: `12px`,
  width: `18px`,
  position: `absolute`,
  left: `3px`,
  top: `6px`,
});

const ActionM1 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(255, 255, 255, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px 0px 0px 8px`,
}));

const ChevronRight1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px 0px 0px 8px`,
  overflow: `hidden`,
});

const Vector29 = styled('img')({
  height: `12px`,
  width: `7.41px`,
  position: `absolute`,
  left: `9px`,
  top: `6px`,
});

const MoreVert6 = styled('div')({
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  left: `1332px`,
  top: `209px`,
  overflow: `hidden`,
});

const Vector30 = styled('img')({
  height: `16px`,
  width: `4px`,
  position: `absolute`,
  left: `10px`,
  top: `4px`,
});

const SecondaryButton = styled('div')({
  border: `1px solid rgba(0, 137, 6, 1)`,
  boxSizing: `border-box`,
  borderRadius: `25px`,
  display: `flex`,
  position: `absolute`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `center`,
  alignItems: `center`,
  padding: `7px 16px`,
  left: `1174px`,
  top: `782px`,
  cursor: `pointer`,
});

const ChevronLeft1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px 0px 0px 8px`,
  overflow: `hidden`,
});

const Vector31 = styled('img')({
  height: `12px`,
  width: `7.41px`,
  position: `absolute`,
  left: `8px`,
  top: `6px`,
});

const ActionM2 = styled('div')(({ theme }) => ({
  textAlign: `center`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(0, 137, 6, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `700`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  margin: `0px`,
}));

const ChevronRight2 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `24px`,
  height: `24px`,
  margin: `0px 0px 0px 8px`,
  overflow: `hidden`,
});

const Vector32 = styled('img')({
  height: `12px`,
  width: `7.41px`,
  position: `absolute`,
  left: `9px`,
  top: `6px`,
});
