import "./PortalExp.css";
import { useAuth0 } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { _VISUALS } from '../Config/index.js';

const ORG_TITLE_ICON = _VISUALS.ORG_TITLE_ICON;
const CHAT_ICON = _VISUALS.CHAT_ICON;
const TH_ICON = _VISUALS.TH_ICON;
const FIND_PROVE_ICON = _VISUALS.FIND_PROV_ICON;

const PortalHome=() => {
    const { isAuthenticated } = useAuth0();

    if (isAuthenticated){ 
        return (
            <section>

                <section>
                    <img className="PortalExp-Org_Title_Icon" src= {ORG_TITLE_ICON} alt="Title"></img>
                </section>

                <section className="PortalExp-Tiles">
                    <section>
                        <section>
                            <section className="PortalExp-Title_Section">
                                <h1 className="PortalExp-Title_Text" >VirtualCare</h1>
                            </section>
                        </section>
                        
                        <hr className="PortalExp-Seperator"/>
                        
                        <section className="PortalExp-TH_Section">
                            <section className="PortalExp-Section_Left">
                                <section >
                                    <img className="PortalExp-Icon" src= {CHAT_ICON} alt="Chat"></img>    
                                </section>
                            </section>

                            <section className="PortalExp-Section_Right">
                                <section className="PortalExp-Main_Text">
                                    <h3>SYMPTOM SEARCHES & DOCTOR TEXTS
                                        <h5 className="PortalExp-Sub_Text">Free to check symptoms</h5>    
                                    </h3>
                                </section>
                            </section>
                        </section>
                        
                        <hr className="PortalExp-Seperator"/>

                        <section className="PortalExp-TH_Section">
                            <section className="PortalExp-Section_Left">
                                <section>
                                    <img className="PortalExp-Icon" src= {TH_ICON}  alt="Telehealth"></img>    
                                </section>
                            </section>

                            <section className="PortalExp-Section_Right">
                                <section className="PortalExp-Main_Text">
                                    <h3>VIRTUAL VISIT WITH A DOCTOR
                                        <br/>
                                        <Link className='PortalExp-Sub_Text' to='/PortalTHMenu'>Talk with a doctor from wherever you are</Link>
                                    </h3>
                                </section>
                            </section>
                        </section>
                    </section>
                </section>
                <br/>
                <section className="PortalExp-Tiles">
                    <section>
                        <section>
                            <section className="PortalExp-Title_Section">
                                <h1 className="PortalExp-Title_Text" >Find Local Providers</h1>
                            </section>
                        </section>
                            
                        <hr className="PortalExp-Seperator"/>
                            
                        <section className="PortalExp-TH_Section">
                            <section className="PortalExp-Section_Left">
                                <section>
                                    <img className="PortalExp-Icon" src= {FIND_PROVE_ICON} alt="Find Alternate Provider"></img>    
                                </section>
                            </section>
                        <section className="PortalExp-Section_Right">
                            <section className="PortalExp-Main_Text">
                                <h3>FIND A PROVIDER NEAR YOU
                                    <h5 className="PortalExp-Sub_Text">Search for providers in your local area</h5>    
                                </h3>
                            </section>
                        </section>
                        </section>
                    </section>
                </section>
            </section>
        );
    }
    else {
        return (
            <section className='AmEx__Portal-Section'>
                <h3 className='AmEx__Portal-h3'>Health Portal. Not Logged In</h3>
            </section>
        );
    }

  }

  export default PortalHome