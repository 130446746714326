/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { createRoot } from 'react-dom/client';
import { _CONFIGS } from '../../Config/index';
import { getGlobal } from '../../globals';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { styled } from '@mui/material/styles';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const usePatientPortalECheckIn = () => {
  const data = {};
  const navigate = useNavigate();

  const handleECheckIn = () => { 
    if(isDevMode){
      console.log("usePatientPortalECheckIn: handleECheckIn Called"); 
    }
    navigate('/PatientPortalECheckInStep1');
   
  };
  
  const handleODVisit = () => { 
    if(isDevMode){
      console.log("usePatientPortalECheckIn: handleODVisit Called");
    }

    navigate('/PatientEmbeddedConverge');
  };
  
  const handleViewVisitsOption = () => { 
    if(isDevMode){
      console.log("usePatientPortalECheckIn: handleViewVisitsOption Called");
    }
  };

  const onLogOut = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep1: onLogOut Called"); 
    }

    navigate('/LogIn/PatientExperience');

  };

  async function getPatientDetails() {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep1.getPatientList Called");
    }

    try {
      const strmrId = getGlobal("gEhrId");
      const patientDetails = await axios.get(_CONFIGS.patientListUrl + strmrId);
      
      if(isDevMode){
        console.log("usePatientPortalECheckInStep1.getPatientDetails: Patient list returned");
        console.log(patientDetails.data);
      }
      
      return patientDetails;

    } catch (error) {
      console.error(error);
    }

    return null;
  }

  async function getPatientList() {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep1.getPatientList Called");
    }

    try {
      const strEmrId = getGlobal("gEhrId");
      const result = await axios.get(_CONFIGS.patientListUrl + strEmrId);
      
      if(isDevMode){
        console.log("usePatientPortalECheckInStep1 - Patient list returned:");
        console.log(result.data);
      }

      if(document.getElementById("WelcomeLabel")){
        const welcomeImgRoot = createRoot(document.getElementById("WelcomeLabel"));

        const newImgElement = (
          <Group5 id='WelcomeLabel'>
            <WelcomeTiana >{"Welcome, " + result.data[0].given_name + "!"}</WelcomeTiana>
            <Ellipse1 src={"data:image/png;base64," + result.data[0].photo} loading="lazy" alt={'Ellipse 1'} />
          </Group5>
        )

        welcomeImgRoot.render(newImgElement);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const fns = { getPatientDetails, handleECheckIn, handleODVisit, handleViewVisitsOption, onLogOut, getPatientList };
  return { data, fns };
};

export default usePatientPortalECheckIn;

const Ellipse1 = styled('img')({
  height: `46px`,
  width: `46px`,
  objectFit: `cover`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

const Group5 = styled('div')({
  position: `fixed`,
  flexDirection: `row`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
  width: `250px`,
  height: `46px`,
  left: `1056px`,
  top: `12px`,
});

const WelcomeTiana = styled('div')(({ theme }) => ({
  textAlign: `left`,
  whiteSpace: `pre-wrap`,
  fontSynthesis: `none`,
  color: `rgba(91, 107, 123, 1)`,
  fontStyle: `normal`,
  fontFamily: `Poppins`,
  fontWeight: `500`,
  fontSize: `16px`,
  letterSpacing: `0px`,
  textDecoration: `none`,
  lineHeight: `24px`,
  textTransform: `none`,
  position: `absolute`,
  left: `55px`,
  top: `11px`,
}));
