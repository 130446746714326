import { NavLink } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import classes from './MainHeader.module.css';


const MainHeader = () => {
  const { error, loginWithRedirect, logout, isAuthenticated } = useAuth0();
  
  return (
    <header className={classes.header}>
      <nav>
        <img className='amwellBug' activeclassname={classes.active} src='/images/AmWell_logo_RGB_Color.png' alt=''></img>
        <ul>
          {!error && !isAuthenticated && (    
            <li>
              <NavLink activeclassname={classes.active} to='/' onClick={() => {
                  loginWithRedirect();
                }
              }>
                Log In
              </NavLink>
            </li>
          )}
          {!error && isAuthenticated && (
            <li>
              <ul>
                <NavLink activeclassname={classes.active} to='/' onClick={() => logout({ returnTo: window.location.origin })}>
                  Log Out
                </NavLink>
              </ul>
            </li>
          )}
          
        </ul>
      </nav>
    </header>
  );
};

export default MainHeader;