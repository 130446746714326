/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import React from 'react';
import XCircle2Image from 'src/assets/images/XCircle_XCircle.png';
import { styled } from '@mui/material/styles';

const XCircle1 = styled('div')({
  display: `flex`,
  position: `relative`,
  isolation: `isolate`,
  flexDirection: `row`,
  width: '100%',
  height: `15px`,
  justifyContent: `flex-start`,
  alignItems: `flex-start`,
  padding: `0px`,
  boxSizing: `border-box`,
});

const XCircle2 = styled('img')({
  height: `15px`,
  width: `15px`,
  position: `absolute`,
  left: `0px`,
  top: `0px`,
});

function XCircle(props) {
  return (
    <XCircle1 className={props.className}>
      <XCircle2 src={XCircle2Image} loading="lazy" alt={'XCircle'} />
    </XCircle1>
  );
}

export default XCircle;
