/**********************************************************************
 *
 *   Component hook generated by Quest
 *
 *   Code Logic for the component goes in this hook
 *   To setup bindings that use the data here or call the functions here, use the Quest editor
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { _CONFIGS } from '../../Config/index';
import { useNavigate } from 'react-router-dom';

//Load up the environment variables that we'll be using
const isDevMode = _CONFIGS.isDevMode;

const usePatientPortalECheckInStep3 = () => {
  const data = {};
  const navigate = useNavigate();

  const handleOnNext = () => {   
    if(isDevMode){
      console.log("usePatientPortalECheckInStep3: handleOnAccept Called"); 
    }
    navigate('/PatientPortalECheckInStep4');

  };
  const handleOnFinishLater = () => { 
    if(isDevMode){
      console.log("usePatientPortalECheckInStep3: handleOnFinishLater Called"); 
    }
    navigate('/PatientPortalECheckIn');

  };

  const onLogOut = () => {
    if(isDevMode){
      console.log("usePatientPortalECheckInStep3: onLogOut Called"); 
    }

    navigate('/LogIn/PatientExperience');

  };

  const fns = { handleOnNext, handleOnFinishLater, onLogOut };
  return { data, fns };
};

export default usePatientPortalECheckInStep3;
